import * as ts from "typescript";
import {
  FlagKind,
  ListTemplate,
  OptionalStructListChild,
  OptionalStructSingleChild,
  RequiredStructListChild,
  RequiredStructSingleChild,
  StringTemplate,
  StructTemplate,
} from "./interfaces";

// https://github.com/Microsoft/Typescript/issues/20875
function isTypeOfWorkaround(node: ts.Node): node is ts.TypeOfExpression {
  return node.kind === ts.SyntaxKind.TypeOfExpression;
}

function withUndefinedToFalse<A, B extends A>(
  f: (a: A) => a is B,
): (a: A | undefined) => a is B {
  return (a: A | undefined): a is B => a !== undefined && f(a);
}

export const plainTypes = {
  NumericLiteral: {
    match: withUndefinedToFalse(ts.isNumericLiteral),
    default: ts.createLiteral(0),
  },
  StringLiteral: {
    match: withUndefinedToFalse(ts.isStringLiteral),
    default: ts.createLiteral(""),
  },
  ArrayLiteralExpression: {
    match: withUndefinedToFalse(ts.isArrayLiteralExpression),
    default: ts.createArrayLiteral([]),
  },
  VariableDeclaration: {
    match: withUndefinedToFalse(ts.isVariableDeclaration),
    default: ts.createVariableDeclaration(""),
  },
  VariableDeclarationList: {
    match: withUndefinedToFalse(ts.isVariableDeclarationList),
    default: ts.createVariableDeclarationList([
      ts.createVariableDeclaration(""),
    ]),
  },
  EmptyStatement: {
    match: withUndefinedToFalse(ts.isEmptyStatement),
    default: ts.createEmptyStatement(),
  },
  ThrowStatement: {
    match: withUndefinedToFalse(ts.isThrowStatement),
    default: ts.createThrow(ts.createLiteral("")),
  },
  VariableStatement: {
    match: withUndefinedToFalse(ts.isVariableStatement),
    default: ts.createVariableStatement(
      undefined,
      ts.createVariableDeclarationList([ts.createVariableDeclaration("")]),
    ),
  },
  Identifier: {
    match: withUndefinedToFalse(ts.isIdentifier),
    default: ts.createIdentifier(""),
  },
  PrivateIdentifier: {
    match: withUndefinedToFalse(ts.isPrivateIdentifier),
    default: ts.createPrivateIdentifier("#"),
  },
  FunctionDeclaration: {
    match: withUndefinedToFalse(ts.isFunctionDeclaration),
    default: ts.createFunctionDeclaration(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      [],
      undefined,
      undefined,
    ),
  },
  Block: {
    match: withUndefinedToFalse(ts.isBlock),
    default: ts.createBlock([]),
  },
  ExpressionStatement: {
    match: withUndefinedToFalse(ts.isExpressionStatement),
    default: ts.createStatement(ts.createLiteral("")),
  },
  ParameterDeclaration: {
    match: withUndefinedToFalse(ts.isParameter),
    default: ts.createParameter(
      undefined,
      undefined,
      undefined,
      "",
      undefined,
      undefined,
      undefined,
    ),
  },
  ArrayBindingPattern: {
    match: withUndefinedToFalse(ts.isArrayBindingPattern),
    default: ts.createArrayBindingPattern([]),
  },
  ObjectBindingPattern: {
    match: withUndefinedToFalse(ts.isObjectBindingPattern),
    default: ts.createObjectBindingPattern([]),
  },
  OmittedExpression: {
    match: withUndefinedToFalse(ts.isOmittedExpression),
    default: ts.createOmittedExpression(),
  },
  BindingElement: {
    match: withUndefinedToFalse(ts.isBindingElement),
    default: ts.createBindingElement(undefined, undefined, "", undefined),
  },
  ComputedPropertyName: {
    match: withUndefinedToFalse(ts.isComputedPropertyName),
    default: ts.createComputedPropertyName(ts.createLiteral("")),
  },
  RegularExpressionLiteral: {
    match: withUndefinedToFalse(ts.isRegularExpressionLiteral),
    default: {
      ...(ts.createTemplateHead("") as any),
      kind: ts.SyntaxKind.RegularExpressionLiteral,
    },
  },
  NoSubstitutionTemplateLiteral: {
    match: withUndefinedToFalse(ts.isNoSubstitutionTemplateLiteral),
    default: ts.createNoSubstitutionTemplateLiteral(""),
  },
  FunctionExpression: {
    match: withUndefinedToFalse(ts.isFunctionExpression),
    default: ts.createFunctionExpression(
      undefined,
      undefined,
      undefined,
      undefined,
      [],
      undefined,
      ts.createBlock([]),
    ),
  },
  TemplateExpression: {
    match: withUndefinedToFalse(ts.isTemplateExpression),
    default: ts.createTemplateExpression(ts.createTemplateHead(""), []),
  },
  ObjectLiteralExpression: {
    match: withUndefinedToFalse(ts.isObjectLiteralExpression),
    default: ts.createObjectLiteral(),
  },
  ParenthesizedExpression: {
    match: withUndefinedToFalse(ts.isParenthesizedExpression),
    default: ts.createParen(ts.createLiteral("")),
  },
  NewExpression: {
    match: withUndefinedToFalse(ts.isNewExpression),
    default: ts.createNew(ts.createIdentifier("Object"), undefined, undefined),
  },
  MetaProperty: {
    match: withUndefinedToFalse(ts.isMetaProperty),
    default: ts.createMetaProperty(
      ts.SyntaxKind.NewKeyword,
      ts.createIdentifier(""),
    ),
  },
  JsxElement: {
    match: withUndefinedToFalse(ts.isJsxElement),
    default: ts.createJsxElement(
      ts.createJsxOpeningElement(
        ts.createIdentifier(""),
        undefined,
        ts.createJsxAttributes([]),
      ),
      [],
      ts.createJsxClosingElement(ts.createIdentifier("")),
    ),
  },
  JsxSelfClosingElement: {
    match: withUndefinedToFalse(ts.isJsxSelfClosingElement),
    default: ts.createJsxSelfClosingElement(
      ts.createIdentifier(""),
      undefined,
      ts.createJsxAttributes([]),
    ),
  },
  JsxOpeningElement: {
    match: withUndefinedToFalse(ts.isJsxOpeningElement),
    default: ts.createJsxOpeningElement(
      ts.createIdentifier(""),
      undefined,
      ts.createJsxAttributes([]),
    ),
  },
  JsxClosingElement: {
    match: withUndefinedToFalse(ts.isJsxClosingElement),
    default: ts.createJsxClosingElement(ts.createIdentifier("")),
  },
  JsxFragment: {
    match: withUndefinedToFalse(ts.isJsxFragment),
    default: ts.createJsxFragment(
      ts.createJsxOpeningFragment(),
      [],
      ts.createJsxJsxClosingFragment(),
    ),
  },
  JsxAttribute: {
    match: withUndefinedToFalse(ts.isJsxAttribute),
    default: ts.createJsxAttribute(
      ts.createIdentifier(""),
      ts.createJsxExpression(undefined, undefined),
    ),
  },
  JsxAttributes: {
    match: withUndefinedToFalse(ts.isJsxAttributes),
    default: ts.createJsxAttributes([]),
  },
  JsxSpreadAttribute: {
    match: withUndefinedToFalse(ts.isJsxSpreadAttribute),
    default: ts.createJsxSpreadAttribute(ts.createIdentifier("")),
  },
  JsxExpression: {
    match: withUndefinedToFalse(ts.isJsxExpression),
    default: ts.createJsxExpression(undefined, undefined),
  },
  JsxText: {
    match: withUndefinedToFalse(ts.isJsxText),
    default: ts.createJsxText(""),
  },
  JsxTagNamePropertyAccess: {
    match: withUndefinedToFalse(
      ts.isPropertyAccessExpression as any as (
        node: ts.Node,
      ) => node is ts.JsxTagNamePropertyAccess,
    ),
    default: ts.createPropertyAccess(
      ts.createObjectLiteral(),
      "",
    ) as ts.JsxTagNamePropertyAccess,
  },
  ClassExpression: {
    match: withUndefinedToFalse(ts.isClassExpression),
    default: ts.createClassExpression(undefined, undefined, undefined, [], []),
  },
  PropertyAccessExpression: {
    match: withUndefinedToFalse(ts.isPropertyAccessExpression),
    default: ts.createPropertyAccess(ts.createObjectLiteral(), ""),
  },
  ElementAccessExpression: {
    match: withUndefinedToFalse(ts.isElementAccessExpression),
    default: ts.createElementAccess(ts.createArrayLiteral(), 0),
  },
  TaggedTemplateExpression: {
    match: withUndefinedToFalse(ts.isTaggedTemplateExpression),
    default: ts.createTaggedTemplate(
      ts.createIdentifier(""),
      ts.createTemplateExpression(ts.createTemplateHead(""), []),
    ),
  },
  CallExpression: {
    match: withUndefinedToFalse(ts.isCallExpression),
    default: ts.createCall(ts.createIdentifier(""), undefined, []),
  },
  ImportDeclaration: {
    match: withUndefinedToFalse(ts.isImportDeclaration),
    default: ts.createImportDeclaration(
      undefined,
      undefined,
      undefined,
      ts.createLiteral(""),
    ),
  },
  ImportClause: {
    match: withUndefinedToFalse(ts.isImportClause),
    default: ts.createImportClause(undefined, undefined),
  },
  NamespaceImport: {
    match: withUndefinedToFalse(ts.isNamespaceImport),
    default: ts.createNamespaceImport(ts.createIdentifier("")),
  },
  NamespaceExport: {
    match: withUndefinedToFalse(ts.isNamespaceExport),
    default: ts.createNamespaceExport(ts.createIdentifier("")),
  },
  NamedImports: {
    match: withUndefinedToFalse(ts.isNamedImports),
    default: ts.createNamedImports([]),
  },
  NamedExports: {
    match: withUndefinedToFalse(ts.isNamedExports),
    default: ts.createNamedExports([]),
  },
  ImportSpecifier: {
    match: withUndefinedToFalse(ts.isImportSpecifier),
    default: ts.createImportSpecifier(undefined, ts.createIdentifier("")),
  },
  ExportSpecifier: {
    match: withUndefinedToFalse(ts.isExportSpecifier),
    default: ts.createExportSpecifier(undefined, ts.createIdentifier("")),
  },
  ClassDeclaration: {
    match: withUndefinedToFalse(ts.isClassDeclaration),
    default: ts.createClassDeclaration(
      undefined,
      undefined,
      undefined,
      undefined,
      [],
      [],
    ),
  },
  HeritageClause: {
    match: withUndefinedToFalse(ts.isHeritageClause),
    default: ts.createHeritageClause(ts.SyntaxKind.ExtendsKeyword, []),
  },
  ExpressionWithTypeArguments: {
    match: withUndefinedToFalse(ts.isExpressionWithTypeArguments),
    default: ts.createExpressionWithTypeArguments([], ts.createIdentifier("")),
  },
  PropertyDeclaration: {
    match: withUndefinedToFalse(ts.isPropertyDeclaration),
    default: ts.createProperty(
      undefined,
      undefined,
      ts.createIdentifier(""),
      undefined,
      undefined,
      undefined,
    ),
  },
  MethodDeclaration: {
    match: withUndefinedToFalse(ts.isMethodDeclaration),
    default: ts.createMethod(
      undefined,
      undefined,
      undefined,
      ts.createIdentifier(""),
      undefined,
      undefined,
      [],
      undefined,
      undefined,
    ),
  },
  ConstructorDeclaration: {
    match: withUndefinedToFalse(ts.isConstructorDeclaration),
    default: ts.createConstructor(undefined, undefined, [], undefined),
  },
  GetAccessorDeclaration: {
    match: withUndefinedToFalse(ts.isGetAccessorDeclaration),
    default: ts.createGetAccessor(
      undefined,
      undefined,
      ts.createIdentifier(""),
      [],
      undefined,
      undefined,
    ),
  },
  SetAccessorDeclaration: {
    match: withUndefinedToFalse(ts.isSetAccessorDeclaration),
    default: ts.createSetAccessor(
      undefined,
      undefined,
      ts.createIdentifier(""),
      [],
      undefined,
    ),
  },
  IndexSignatureDeclaration: {
    match: withUndefinedToFalse(ts.isIndexSignatureDeclaration),
    default: ts.createIndexSignature(
      undefined,
      undefined,
      [],
      ts.createThisTypeNode(),
    ),
  },
  SemicolonClassElement: {
    match: withUndefinedToFalse(ts.isSemicolonClassElement),
    default: ts.createSemicolonClassElement(),
  },
  PropertyAssignment: {
    match: withUndefinedToFalse(ts.isPropertyAssignment),
    default: ts.createPropertyAssignment("", ts.createLiteral("")),
  },
  ShorthandPropertyAssignment: {
    match: withUndefinedToFalse(ts.isShorthandPropertyAssignment),
    default: ts.createShorthandPropertyAssignment("", undefined),
  },
  SpreadAssignment: {
    match: withUndefinedToFalse(ts.isSpreadAssignment),
    default: ts.createSpreadAssignment(ts.createIdentifier("")),
  },
  ArrowFunction: {
    match: withUndefinedToFalse(ts.isArrowFunction),
    default: ts.createArrowFunction(
      undefined,
      undefined,
      [],
      undefined,
      ts.createToken(ts.SyntaxKind.EqualsGreaterThanToken),
      ts.createLiteral(""),
    ),
  },
  ReturnStatement: {
    match: withUndefinedToFalse(ts.isReturnStatement),
    default: ts.createReturn(undefined),
  },
  IfStatement: {
    match: withUndefinedToFalse(ts.isIfStatement),
    default: ts.createIf(
      ts.createLiteral(""),
      ts.createEmptyStatement(),
      undefined,
    ),
  },
  ConditionalExpression: {
    match: withUndefinedToFalse(ts.isConditionalExpression),
    default: ts.createConditional(
      ts.createLiteral(""),
      ts.createLiteral(""),
      ts.createLiteral(""),
    ),
  },
  BinaryExpression: {
    match: withUndefinedToFalse(ts.isBinaryExpression),
    default: ts.createBinary(
      ts.createLiteral(""),
      ts.SyntaxKind.PlusToken,
      ts.createLiteral(""),
    ),
  },
  PrefixUnaryExpression: {
    match: withUndefinedToFalse(ts.isPrefixUnaryExpression),
    default: ts.createPrefix(
      ts.SyntaxKind.ExclamationToken,
      ts.createLiteral(""),
    ),
  },
  PostfixUnaryExpression: {
    match: withUndefinedToFalse(ts.isPostfixUnaryExpression),
    default: ts.createPostfix(
      ts.createLiteral(""),
      ts.SyntaxKind.PlusPlusToken,
    ),
  },
  TemplateHead: {
    match: withUndefinedToFalse(ts.isTemplateHead),
    default: ts.createTemplateHead(""),
  },
  TemplateMiddle: {
    match: withUndefinedToFalse(ts.isTemplateMiddle),
    default: ts.createTemplateMiddle(""),
  },
  TemplateTail: {
    match: withUndefinedToFalse(ts.isTemplateTail),
    default: ts.createTemplateTail(""),
  },
  TemplateSpan: {
    match: withUndefinedToFalse(ts.isTemplateSpan),
    default: ts.createTemplateSpan(
      ts.createLiteral(""),
      ts.createTemplateMiddle(""),
    ),
  },
  AsExpression: {
    match: withUndefinedToFalse(ts.isAsExpression),
    default: ts.createAsExpression(
      ts.createLiteral(""),
      ts.createThisTypeNode(),
    ),
  },
  TypeParameterDeclaration: {
    match: withUndefinedToFalse(ts.isTypeParameterDeclaration),
    default: ts.createTypeParameterDeclaration("", undefined, undefined),
  },
  FunctionTypeNode: {
    match: withUndefinedToFalse(ts.isFunctionTypeNode),
    default: ts.createFunctionTypeNode(
      undefined,
      [],
      ts.createTypeLiteralNode(undefined),
    ),
  },
  ConstructorTypeNode: {
    match: withUndefinedToFalse(ts.isConstructorTypeNode),
    default: ts.createConstructorTypeNode(
      undefined,
      [],
      ts.createTypeLiteralNode(undefined),
    ),
  },
  QualifiedName: {
    match: withUndefinedToFalse(ts.isQualifiedName),
    default: ts.createQualifiedName(ts.createIdentifier(""), ""),
  },
  TypeReferenceNode: {
    match: withUndefinedToFalse(ts.isTypeReferenceNode),
    default: ts.createTypeReferenceNode("", undefined),
  },
  TypePredicateNode: {
    match: withUndefinedToFalse(ts.isTypePredicateNode),
    default: ts.createTypePredicateNode("", ts.createThisTypeNode()),
  },
  TypeQueryNode: {
    match: withUndefinedToFalse(ts.isTypeQueryNode),
    default: ts.createTypeQueryNode(ts.createIdentifier("")),
  },
  CallSignatureDeclaration: {
    match: withUndefinedToFalse(ts.isCallSignatureDeclaration),
    default: ts.createCallSignature(undefined, [], undefined),
  },
  ConstructSignatureDeclaration: {
    match: withUndefinedToFalse(ts.isConstructSignatureDeclaration),
    default: ts.createConstructSignature(undefined, [], undefined),
  },
  PropertySignature: {
    match: withUndefinedToFalse(ts.isPropertySignature),
    default: ts.createPropertySignature(
      undefined,
      "",
      undefined,
      undefined,
      undefined,
    ),
  },
  MethodSignature: {
    match: withUndefinedToFalse(ts.isMethodSignature),
    default: ts.createMethodSignature(undefined, [], undefined, "", undefined),
  },
  TypeLiteralNode: {
    match: withUndefinedToFalse(ts.isTypeLiteralNode),
    default: ts.createTypeLiteralNode([]),
  },
  ArrayTypeNode: {
    match: withUndefinedToFalse(ts.isArrayTypeNode),
    default: ts.createArrayTypeNode(ts.createThisTypeNode()),
  },
  TupleTypeNode: {
    match: withUndefinedToFalse(ts.isTupleTypeNode),
    default: ts.createTupleTypeNode([]),
  },
  UnionTypeNode: {
    match: withUndefinedToFalse(ts.isUnionTypeNode),
    default: ts.createUnionTypeNode([]),
  },
  IntersectionTypeNode: {
    match: withUndefinedToFalse(ts.isIntersectionTypeNode),
    default: ts.createIntersectionTypeNode([]),
  },
  ParenthesizedTypeNode: {
    match: withUndefinedToFalse(ts.isParenthesizedTypeNode),
    default: ts.createParenthesizedType(ts.createThisTypeNode()),
  },
  TypeOperatorNode: {
    match: withUndefinedToFalse(ts.isTypeOperatorNode),
    default: ts.createTypeOperatorNode(ts.createThisTypeNode()),
  },
  IndexedAccessTypeNode: {
    match: withUndefinedToFalse(ts.isIndexedAccessTypeNode),
    default: ts.createIndexedAccessTypeNode(
      ts.createThisTypeNode(),
      ts.createThisTypeNode(),
    ),
  },
  MappedTypeNode: {
    match: withUndefinedToFalse(ts.isMappedTypeNode),
    default: ts.createMappedTypeNode(
      undefined,
      ts.createTypeParameterDeclaration(""),
      undefined,
      undefined,
      undefined,
    ),
  },
  LiteralTypeNode: {
    match: withUndefinedToFalse(ts.isLiteralTypeNode),
    default: ts.createLiteralTypeNode(ts.createLiteral("")),
  },
  InterfaceDeclaration: {
    match: withUndefinedToFalse(ts.isInterfaceDeclaration),
    default: ts.createInterfaceDeclaration(
      undefined,
      undefined,
      "",
      undefined,
      undefined,
      [],
    ),
  },
  TypeAliasDeclaration: {
    match: withUndefinedToFalse(ts.isTypeAliasDeclaration),
    default: ts.createTypeAliasDeclaration(
      undefined,
      undefined,
      "",
      undefined,
      ts.createThisTypeNode(),
    ),
  },
  EnumDeclaration: {
    match: withUndefinedToFalse(ts.isEnumDeclaration),
    default: ts.createEnumDeclaration(undefined, undefined, "", []),
  },
  ModuleDeclaration: {
    match: withUndefinedToFalse(ts.isModuleDeclaration),
    default: ts.createModuleDeclaration(
      undefined,
      undefined,
      ts.createIdentifier(""),
      undefined,
      undefined,
    ),
  },
  ImportEqualsDeclaration: {
    match: withUndefinedToFalse(ts.isImportEqualsDeclaration),
    default: ts.createImportEqualsDeclaration(
      undefined,
      undefined,
      false,
      "",
      ts.createIdentifier(""),
    ),
  },
  NamespaceExportDeclaration: {
    match: withUndefinedToFalse(ts.isNamespaceExportDeclaration),
    default: ts.createNamespaceExportDeclaration(""),
  },
  ExportDeclaration: {
    match: withUndefinedToFalse(ts.isExportDeclaration),
    default: ts.createExportDeclaration(
      undefined,
      undefined,
      undefined,
      undefined,
    ),
  },
  ExportAssignment: {
    match: withUndefinedToFalse(ts.isExportAssignment),
    default: ts.createExportAssignment(
      undefined,
      undefined,
      false,
      ts.createIdentifier(""),
    ),
  },
  EnumMember: {
    match: withUndefinedToFalse(ts.isEnumMember),
    default: ts.createEnumMember("", undefined),
  },
  ModuleBlock: {
    match: withUndefinedToFalse(ts.isModuleBlock),
    default: ts.createModuleBlock([]),
  },
  NamespaceDeclaration: {
    match: withUndefinedToFalse(
      ts.isModuleDeclaration as any as (
        node: ts.Node,
      ) => node is ts.NamespaceDeclaration,
    ),
    default: ts.createModuleDeclaration(
      undefined,
      undefined,
      ts.createIdentifier(""),
      ts.createModuleBlock([]),
      undefined,
    ) as ts.NamespaceDeclaration,
  },
  ExternalModuleReference: {
    match: withUndefinedToFalse(ts.isExternalModuleReference),
    default: ts.createExternalModuleReference(ts.createLiteral("")),
  },
  TypeOfExpression: {
    match: withUndefinedToFalse(isTypeOfWorkaround),
    default: ts.createTypeOf(ts.createLiteral("")),
  },
  AwaitExpression: {
    match: withUndefinedToFalse(ts.isAwaitExpression),
    default: ts.createAwait(ts.createLiteral("")),
  },
  NonNullExpression: {
    match: withUndefinedToFalse(ts.isNonNullExpression),
    default: ts.createNonNullExpression(ts.createLiteral("")),
  },
  DoStatement: {
    match: withUndefinedToFalse(ts.isDoStatement),
    default: ts.createDo(ts.createEmptyStatement(), ts.createLiteral("")),
  },
  WhileStatement: {
    match: withUndefinedToFalse(ts.isWhileStatement),
    default: ts.createWhile(ts.createLiteral(""), ts.createEmptyStatement()),
  },
  ForStatement: {
    match: withUndefinedToFalse(ts.isForStatement),
    default: ts.createFor(
      undefined,
      undefined,
      undefined,
      ts.createEmptyStatement(),
    ),
  },
  ForInStatement: {
    match: withUndefinedToFalse(ts.isForInStatement),
    default: ts.createForIn(
      ts.createVariableDeclarationList([]),
      ts.createLiteral(""),
      ts.createEmptyStatement(),
    ),
  },
  ForOfStatement: {
    match: withUndefinedToFalse(ts.isForOfStatement),
    default: ts.createForOf(
      undefined,
      ts.createVariableDeclarationList([]),
      ts.createLiteral(""),
      ts.createEmptyStatement(),
    ),
  },
  SpreadElement: {
    match: withUndefinedToFalse(ts.isSpreadElement),
    default: ts.createSpread(ts.createLiteral("")),
  },
  TryStatement: {
    match: withUndefinedToFalse(ts.isTryStatement),
    default: ts.createTry(ts.createBlock([]), undefined, undefined),
  },
  CatchClause: {
    match: withUndefinedToFalse(ts.isCatchClause),
    default: ts.createCatchClause(undefined, ts.createBlock([])),
  },
  SwitchStatement: {
    match: withUndefinedToFalse(ts.isSwitchStatement),
    default: ts.createSwitch(ts.createLiteral(""), ts.createCaseBlock([])),
  },
  CaseBlock: {
    match: withUndefinedToFalse(ts.isCaseBlock),
    default: ts.createCaseBlock([]),
  },
  CaseClause: {
    match: withUndefinedToFalse(ts.isCaseClause),
    default: ts.createCaseClause(ts.createLiteral(""), []),
  },
  DefaultClause: {
    match: withUndefinedToFalse(ts.isDefaultClause),
    default: ts.createDefaultClause([]),
  },
  BreakStatement: {
    match: withUndefinedToFalse(ts.isBreakStatement),
    default: ts.createBreak(),
  },
  ContinueStatement: {
    match: withUndefinedToFalse(ts.isContinueStatement),
    default: ts.createContinue(),
  },
  NamedTupleMember: {
    match: withUndefinedToFalse(ts.isNamedTupleMember),
    default: ts.factory.createNamedTupleMember(
      undefined,
      ts.createIdentifier(""),
      undefined,
      ts.createTypeLiteralNode(undefined),
    ),
  },
  NullLiteral: {
    match: (e: ts.Node | undefined): e is ts.NullLiteral =>
      e !== undefined && [ts.SyntaxKind.NullKeyword].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.NullKeyword } as ts.NullLiteral,
  },
  TrueLiteral: {
    match: (e: ts.Node | undefined): e is ts.TrueLiteral =>
      e !== undefined && [ts.SyntaxKind.TrueKeyword].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.TrueKeyword } as ts.TrueLiteral,
  },
  FalseLiteral: {
    match: (e: ts.Node | undefined): e is ts.FalseLiteral =>
      e !== undefined && [ts.SyntaxKind.FalseKeyword].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.FalseKeyword } as ts.FalseLiteral,
  },
  ThisExpression: {
    match: (e: ts.Node | undefined): e is ts.ThisExpression =>
      e !== undefined && [ts.SyntaxKind.ThisKeyword].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.ThisKeyword } as ts.ThisExpression,
  },
  SuperExpression: {
    match: (e: ts.Node | undefined): e is ts.SuperExpression =>
      e !== undefined && [ts.SyntaxKind.SuperKeyword].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.SuperKeyword } as ts.SuperExpression,
  },
  ImportExpression: {
    match: (e: ts.Node | undefined): e is ts.ImportExpression =>
      e !== undefined &&
      [ts.SyntaxKind.ImportKeyword].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.ImportKeyword } as ts.ImportExpression,
  },
  DotDotDotToken: {
    match: (e: ts.Node | undefined): e is ts.DotDotDotToken =>
      e !== undefined &&
      [ts.SyntaxKind.DotDotDotToken].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.DotDotDotToken } as ts.DotDotDotToken,
  },
  QuestionToken: {
    match: (e: ts.Node | undefined): e is ts.QuestionToken =>
      e !== undefined &&
      [ts.SyntaxKind.QuestionToken].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.QuestionToken } as ts.QuestionToken,
  },
  QuestionDotToken: {
    match: (e: ts.Node | undefined): e is ts.QuestionDotToken =>
      e !== undefined &&
      [ts.SyntaxKind.QuestionDotToken].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.QuestionDotToken } as ts.QuestionDotToken,
  },
  AsteriskToken: {
    match: (e: ts.Node | undefined): e is ts.AsteriskToken =>
      e !== undefined &&
      [ts.SyntaxKind.AsteriskToken].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.AsteriskToken } as ts.AsteriskToken,
  },
  ThisTypeNode: {
    match: (e: ts.Node | undefined): e is ts.ThisTypeNode =>
      e !== undefined && [ts.SyntaxKind.ThisType].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.ThisType } as ts.ThisTypeNode,
  },
  ReadonlyToken: {
    match: (e: ts.Node | undefined): e is ts.ReadonlyToken =>
      e !== undefined &&
      [ts.SyntaxKind.ReadonlyKeyword].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.ReadonlyKeyword } as ts.ReadonlyToken,
  },
  EqualsToken: {
    match: (e: ts.Node | undefined): e is ts.EqualsToken =>
      e !== undefined && [ts.SyntaxKind.EqualsToken].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.EqualsToken } as ts.EqualsToken,
  },
  AwaitKeywordToken: {
    match: (e: ts.Node | undefined): e is ts.AwaitKeywordToken =>
      e !== undefined && [ts.SyntaxKind.AwaitKeyword].some((k) => e.kind === k),
    default: { kind: ts.SyntaxKind.AwaitKeyword } as ts.AwaitKeywordToken,
  },
  OpenBraceToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.OpenBraceToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.OpenBraceToken,
    default: {
      kind: ts.SyntaxKind.OpenBraceToken,
    } as ts.Token<ts.SyntaxKind.OpenBraceToken>,
  },
  CloseBraceToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.CloseBraceToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.CloseBraceToken,
    default: {
      kind: ts.SyntaxKind.CloseBraceToken,
    } as ts.Token<ts.SyntaxKind.CloseBraceToken>,
  },
  OpenParenToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.OpenParenToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.OpenParenToken,
    default: {
      kind: ts.SyntaxKind.OpenParenToken,
    } as ts.Token<ts.SyntaxKind.OpenParenToken>,
  },
  CloseParenToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.CloseParenToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.CloseParenToken,
    default: {
      kind: ts.SyntaxKind.CloseParenToken,
    } as ts.Token<ts.SyntaxKind.CloseParenToken>,
  },
  OpenBracketToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.OpenBracketToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.OpenBracketToken,
    default: {
      kind: ts.SyntaxKind.OpenBracketToken,
    } as ts.Token<ts.SyntaxKind.OpenBracketToken>,
  },
  CloseBracketToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.CloseBracketToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.CloseBracketToken,
    default: {
      kind: ts.SyntaxKind.CloseBracketToken,
    } as ts.Token<ts.SyntaxKind.CloseBracketToken>,
  },
  DotToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.DotToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.DotToken,
    default: {
      kind: ts.SyntaxKind.DotToken,
    } as ts.Token<ts.SyntaxKind.DotToken>,
  },
  SemicolonToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.SemicolonToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.SemicolonToken,
    default: {
      kind: ts.SyntaxKind.SemicolonToken,
    } as ts.Token<ts.SyntaxKind.SemicolonToken>,
  },
  CommaToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.CommaToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.CommaToken,
    default: {
      kind: ts.SyntaxKind.CommaToken,
    } as ts.Token<ts.SyntaxKind.CommaToken>,
  },
  LessThanToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.LessThanToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.LessThanToken,
    default: {
      kind: ts.SyntaxKind.LessThanToken,
    } as ts.Token<ts.SyntaxKind.LessThanToken>,
  },
  LessThanSlashToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.LessThanSlashToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.LessThanSlashToken,
    default: {
      kind: ts.SyntaxKind.LessThanSlashToken,
    } as ts.Token<ts.SyntaxKind.LessThanSlashToken>,
  },
  GreaterThanToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.GreaterThanToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.GreaterThanToken,
    default: {
      kind: ts.SyntaxKind.GreaterThanToken,
    } as ts.Token<ts.SyntaxKind.GreaterThanToken>,
  },
  LessThanEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.LessThanEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.LessThanEqualsToken,
    default: {
      kind: ts.SyntaxKind.LessThanEqualsToken,
    } as ts.Token<ts.SyntaxKind.LessThanEqualsToken>,
  },
  GreaterThanEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.GreaterThanEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.GreaterThanEqualsToken,
    default: {
      kind: ts.SyntaxKind.GreaterThanEqualsToken,
    } as ts.Token<ts.SyntaxKind.GreaterThanEqualsToken>,
  },
  EqualsEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.EqualsEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.EqualsEqualsToken,
    default: {
      kind: ts.SyntaxKind.EqualsEqualsToken,
    } as ts.Token<ts.SyntaxKind.EqualsEqualsToken>,
  },
  ExclamationEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.ExclamationEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.ExclamationEqualsToken,
    default: {
      kind: ts.SyntaxKind.ExclamationEqualsToken,
    } as ts.Token<ts.SyntaxKind.ExclamationEqualsToken>,
  },
  EqualsEqualsEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.EqualsEqualsEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.EqualsEqualsEqualsToken,
    default: {
      kind: ts.SyntaxKind.EqualsEqualsEqualsToken,
    } as ts.Token<ts.SyntaxKind.EqualsEqualsEqualsToken>,
  },
  ExclamationEqualsEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.ExclamationEqualsEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.ExclamationEqualsEqualsToken,
    default: {
      kind: ts.SyntaxKind.ExclamationEqualsEqualsToken,
    } as ts.Token<ts.SyntaxKind.ExclamationEqualsEqualsToken>,
  },
  EqualsGreaterThanToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.EqualsGreaterThanToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.EqualsGreaterThanToken,
    default: {
      kind: ts.SyntaxKind.EqualsGreaterThanToken,
    } as ts.Token<ts.SyntaxKind.EqualsGreaterThanToken>,
  },
  PlusToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.PlusToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.PlusToken,
    default: {
      kind: ts.SyntaxKind.PlusToken,
    } as ts.Token<ts.SyntaxKind.PlusToken>,
  },
  MinusToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.MinusToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.MinusToken,
    default: {
      kind: ts.SyntaxKind.MinusToken,
    } as ts.Token<ts.SyntaxKind.MinusToken>,
  },
  AsteriskAsteriskToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.AsteriskAsteriskToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.AsteriskAsteriskToken,
    default: {
      kind: ts.SyntaxKind.AsteriskAsteriskToken,
    } as ts.Token<ts.SyntaxKind.AsteriskAsteriskToken>,
  },
  SlashToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.SlashToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.SlashToken,
    default: {
      kind: ts.SyntaxKind.SlashToken,
    } as ts.Token<ts.SyntaxKind.SlashToken>,
  },
  PercentToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.PercentToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.PercentToken,
    default: {
      kind: ts.SyntaxKind.PercentToken,
    } as ts.Token<ts.SyntaxKind.PercentToken>,
  },
  PlusPlusToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.PlusPlusToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.PlusPlusToken,
    default: {
      kind: ts.SyntaxKind.PlusPlusToken,
    } as ts.Token<ts.SyntaxKind.PlusPlusToken>,
  },
  MinusMinusToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.MinusMinusToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.MinusMinusToken,
    default: {
      kind: ts.SyntaxKind.MinusMinusToken,
    } as ts.Token<ts.SyntaxKind.MinusMinusToken>,
  },
  LessThanLessThanToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.LessThanLessThanToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.LessThanLessThanToken,
    default: {
      kind: ts.SyntaxKind.LessThanLessThanToken,
    } as ts.Token<ts.SyntaxKind.LessThanLessThanToken>,
  },
  GreaterThanGreaterThanToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.GreaterThanGreaterThanToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.GreaterThanGreaterThanToken,
    default: {
      kind: ts.SyntaxKind.GreaterThanGreaterThanToken,
    } as ts.Token<ts.SyntaxKind.GreaterThanGreaterThanToken>,
  },
  GreaterThanGreaterThanGreaterThanToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.GreaterThanGreaterThanGreaterThanToken> =>
      e !== undefined &&
      e.kind === ts.SyntaxKind.GreaterThanGreaterThanGreaterThanToken,
    default: {
      kind: ts.SyntaxKind.GreaterThanGreaterThanGreaterThanToken,
    } as ts.Token<ts.SyntaxKind.GreaterThanGreaterThanGreaterThanToken>,
  },
  AmpersandToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.AmpersandToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.AmpersandToken,
    default: {
      kind: ts.SyntaxKind.AmpersandToken,
    } as ts.Token<ts.SyntaxKind.AmpersandToken>,
  },
  BarToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.BarToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.BarToken,
    default: {
      kind: ts.SyntaxKind.BarToken,
    } as ts.Token<ts.SyntaxKind.BarToken>,
  },
  CaretToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.CaretToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.CaretToken,
    default: {
      kind: ts.SyntaxKind.CaretToken,
    } as ts.Token<ts.SyntaxKind.CaretToken>,
  },
  ExclamationToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.ExclamationToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.ExclamationToken,
    default: {
      kind: ts.SyntaxKind.ExclamationToken,
    } as ts.Token<ts.SyntaxKind.ExclamationToken>,
  },
  TildeToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.TildeToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.TildeToken,
    default: {
      kind: ts.SyntaxKind.TildeToken,
    } as ts.Token<ts.SyntaxKind.TildeToken>,
  },
  AmpersandAmpersandToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.AmpersandAmpersandToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.AmpersandAmpersandToken,
    default: {
      kind: ts.SyntaxKind.AmpersandAmpersandToken,
    } as ts.Token<ts.SyntaxKind.AmpersandAmpersandToken>,
  },
  QuestionQuestionToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.QuestionQuestionToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.QuestionQuestionToken,
    default: {
      kind: ts.SyntaxKind.QuestionQuestionToken,
    } as ts.Token<ts.SyntaxKind.QuestionQuestionToken>,
  },
  BarBarToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.BarBarToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.BarBarToken,
    default: {
      kind: ts.SyntaxKind.BarBarToken,
    } as ts.Token<ts.SyntaxKind.BarBarToken>,
  },
  ColonToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.ColonToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.ColonToken,
    default: {
      kind: ts.SyntaxKind.ColonToken,
    } as ts.Token<ts.SyntaxKind.ColonToken>,
  },
  AtToken: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.AtToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.AtToken,
    default: { kind: ts.SyntaxKind.AtToken } as ts.Token<ts.SyntaxKind.AtToken>,
  },
  PlusEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.PlusEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.PlusEqualsToken,
    default: {
      kind: ts.SyntaxKind.PlusEqualsToken,
    } as ts.Token<ts.SyntaxKind.PlusEqualsToken>,
  },
  MinusEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.MinusEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.MinusEqualsToken,
    default: {
      kind: ts.SyntaxKind.MinusEqualsToken,
    } as ts.Token<ts.SyntaxKind.MinusEqualsToken>,
  },
  AsteriskEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.AsteriskEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.AsteriskEqualsToken,
    default: {
      kind: ts.SyntaxKind.AsteriskEqualsToken,
    } as ts.Token<ts.SyntaxKind.AsteriskEqualsToken>,
  },
  AsteriskAsteriskEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.AsteriskAsteriskEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.AsteriskAsteriskEqualsToken,
    default: {
      kind: ts.SyntaxKind.AsteriskAsteriskEqualsToken,
    } as ts.Token<ts.SyntaxKind.AsteriskAsteriskEqualsToken>,
  },
  SlashEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.SlashEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.SlashEqualsToken,
    default: {
      kind: ts.SyntaxKind.SlashEqualsToken,
    } as ts.Token<ts.SyntaxKind.SlashEqualsToken>,
  },
  PercentEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.PercentEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.PercentEqualsToken,
    default: {
      kind: ts.SyntaxKind.PercentEqualsToken,
    } as ts.Token<ts.SyntaxKind.PercentEqualsToken>,
  },
  LessThanLessThanEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.LessThanLessThanEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.LessThanLessThanEqualsToken,
    default: {
      kind: ts.SyntaxKind.LessThanLessThanEqualsToken,
    } as ts.Token<ts.SyntaxKind.LessThanLessThanEqualsToken>,
  },
  GreaterThanGreaterThanEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.GreaterThanGreaterThanEqualsToken> =>
      e !== undefined &&
      e.kind === ts.SyntaxKind.GreaterThanGreaterThanEqualsToken,
    default: {
      kind: ts.SyntaxKind.GreaterThanGreaterThanEqualsToken,
    } as ts.Token<ts.SyntaxKind.GreaterThanGreaterThanEqualsToken>,
  },
  GreaterThanGreaterThanGreaterThanEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.GreaterThanGreaterThanGreaterThanEqualsToken> =>
      e !== undefined &&
      e.kind === ts.SyntaxKind.GreaterThanGreaterThanGreaterThanEqualsToken,
    default: {
      kind: ts.SyntaxKind.GreaterThanGreaterThanGreaterThanEqualsToken,
    } as ts.Token<ts.SyntaxKind.GreaterThanGreaterThanGreaterThanEqualsToken>,
  },
  AmpersandEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.AmpersandEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.AmpersandEqualsToken,
    default: {
      kind: ts.SyntaxKind.AmpersandEqualsToken,
    } as ts.Token<ts.SyntaxKind.AmpersandEqualsToken>,
  },
  BarEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.BarEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.BarEqualsToken,
    default: {
      kind: ts.SyntaxKind.BarEqualsToken,
    } as ts.Token<ts.SyntaxKind.BarEqualsToken>,
  },
  CaretEqualsToken: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.CaretEqualsToken> =>
      e !== undefined && e.kind === ts.SyntaxKind.CaretEqualsToken,
    default: {
      kind: ts.SyntaxKind.CaretEqualsToken,
    } as ts.Token<ts.SyntaxKind.CaretEqualsToken>,
  },
  InKeyword: {
    match: (e: ts.Node | undefined): e is ts.Token<ts.SyntaxKind.InKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.InKeyword,
    default: {
      kind: ts.SyntaxKind.InKeyword,
    } as ts.Token<ts.SyntaxKind.InKeyword>,
  },
  InstanceOfKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.Token<ts.SyntaxKind.InstanceOfKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.InstanceOfKeyword,
    default: {
      kind: ts.SyntaxKind.InstanceOfKeyword,
    } as ts.Token<ts.SyntaxKind.InstanceOfKeyword>,
  },
  AnyKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.KeywordTypeNode<ts.SyntaxKind.AnyKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.AnyKeyword,
    default: {
      kind: ts.SyntaxKind.AnyKeyword,
    } as ts.KeywordTypeNode<ts.SyntaxKind.AnyKeyword>,
  },
  UnknownKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.KeywordTypeNode<ts.SyntaxKind.UnknownKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.UnknownKeyword,
    default: {
      kind: ts.SyntaxKind.UnknownKeyword,
    } as ts.KeywordTypeNode<ts.SyntaxKind.UnknownKeyword>,
  },
  NumberKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.KeywordTypeNode<ts.SyntaxKind.NumberKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.NumberKeyword,
    default: {
      kind: ts.SyntaxKind.NumberKeyword,
    } as ts.KeywordTypeNode<ts.SyntaxKind.NumberKeyword>,
  },
  ObjectKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.KeywordTypeNode<ts.SyntaxKind.ObjectKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.ObjectKeyword,
    default: {
      kind: ts.SyntaxKind.ObjectKeyword,
    } as ts.KeywordTypeNode<ts.SyntaxKind.ObjectKeyword>,
  },
  BooleanKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.KeywordTypeNode<ts.SyntaxKind.BooleanKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.BooleanKeyword,
    default: {
      kind: ts.SyntaxKind.BooleanKeyword,
    } as ts.KeywordTypeNode<ts.SyntaxKind.BooleanKeyword>,
  },
  StringKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.KeywordTypeNode<ts.SyntaxKind.StringKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.StringKeyword,
    default: {
      kind: ts.SyntaxKind.StringKeyword,
    } as ts.KeywordTypeNode<ts.SyntaxKind.StringKeyword>,
  },
  SymbolKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.KeywordTypeNode<ts.SyntaxKind.SymbolKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.SymbolKeyword,
    default: {
      kind: ts.SyntaxKind.SymbolKeyword,
    } as ts.KeywordTypeNode<ts.SyntaxKind.SymbolKeyword>,
  },
  VoidKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.KeywordTypeNode<ts.SyntaxKind.VoidKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.VoidKeyword,
    default: {
      kind: ts.SyntaxKind.VoidKeyword,
    } as ts.KeywordTypeNode<ts.SyntaxKind.VoidKeyword>,
  },
  UndefinedKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.KeywordTypeNode<ts.SyntaxKind.UndefinedKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.UndefinedKeyword,
    default: {
      kind: ts.SyntaxKind.UndefinedKeyword,
    } as ts.KeywordTypeNode<ts.SyntaxKind.UndefinedKeyword>,
  },
  NeverKeyword: {
    match: (
      e: ts.Node | undefined,
    ): e is ts.KeywordTypeNode<ts.SyntaxKind.NeverKeyword> =>
      e !== undefined && e.kind === ts.SyntaxKind.NeverKeyword,
    default: {
      kind: ts.SyntaxKind.NeverKeyword,
    } as ts.KeywordTypeNode<ts.SyntaxKind.NeverKeyword>,
  },
};

export const unions = {
  Expression: {
    name: "Expression",
    getMembers: () => ({
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
      Identifier: plainTypes.Identifier,
      ThisExpression: plainTypes.ThisExpression,
      SuperExpression: plainTypes.SuperExpression,
      ImportExpression: plainTypes.ImportExpression,
      FunctionExpression: plainTypes.FunctionExpression,
      TemplateExpression: plainTypes.TemplateExpression,
      NullLiteral: plainTypes.NullLiteral,
      TrueLiteral: plainTypes.TrueLiteral,
      FalseLiteral: plainTypes.FalseLiteral,
      ArrayLiteralExpression: plainTypes.ArrayLiteralExpression,
      ObjectLiteralExpression: plainTypes.ObjectLiteralExpression,
      JsxAttributes: plainTypes.JsxAttributes,
      ParenthesizedExpression: plainTypes.ParenthesizedExpression,
      NewExpression: plainTypes.NewExpression,
      MetaProperty: plainTypes.MetaProperty,
      JsxElement: plainTypes.JsxElement,
      JsxSelfClosingElement: plainTypes.JsxSelfClosingElement,
      JsxFragment: plainTypes.JsxFragment,
      ClassExpression: plainTypes.ClassExpression,
      PropertyAccessExpression: plainTypes.PropertyAccessExpression,
      ElementAccessExpression: plainTypes.ElementAccessExpression,
      TaggedTemplateExpression: plainTypes.TaggedTemplateExpression,
      CallExpression: plainTypes.CallExpression,
      NonNullExpression: plainTypes.NonNullExpression,
      PrefixUnaryExpression: plainTypes.PrefixUnaryExpression,
      PostfixUnaryExpression: plainTypes.PostfixUnaryExpression,
      TypeOfExpression: plainTypes.TypeOfExpression,
      AwaitExpression: plainTypes.AwaitExpression,
      BinaryExpression: plainTypes.BinaryExpression,
      ArrowFunction: plainTypes.ArrowFunction,
      ConditionalExpression: plainTypes.ConditionalExpression,
      AsExpression: plainTypes.AsExpression,
      SpreadElement: plainTypes.SpreadElement,
    }),
  },
  UnaryExpression: {
    name: "UnaryExpression",
    getMembers: () => ({
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
      Identifier: plainTypes.Identifier,
      ThisExpression: plainTypes.ThisExpression,
      SuperExpression: plainTypes.SuperExpression,
      ImportExpression: plainTypes.ImportExpression,
      FunctionExpression: plainTypes.FunctionExpression,
      TemplateExpression: plainTypes.TemplateExpression,
      NullLiteral: plainTypes.NullLiteral,
      TrueLiteral: plainTypes.TrueLiteral,
      FalseLiteral: plainTypes.FalseLiteral,
      ArrayLiteralExpression: plainTypes.ArrayLiteralExpression,
      ObjectLiteralExpression: plainTypes.ObjectLiteralExpression,
      JsxAttributes: plainTypes.JsxAttributes,
      ParenthesizedExpression: plainTypes.ParenthesizedExpression,
      NewExpression: plainTypes.NewExpression,
      MetaProperty: plainTypes.MetaProperty,
      JsxElement: plainTypes.JsxElement,
      JsxSelfClosingElement: plainTypes.JsxSelfClosingElement,
      JsxFragment: plainTypes.JsxFragment,
      ClassExpression: plainTypes.ClassExpression,
      PropertyAccessExpression: plainTypes.PropertyAccessExpression,
      ElementAccessExpression: plainTypes.ElementAccessExpression,
      TaggedTemplateExpression: plainTypes.TaggedTemplateExpression,
      CallExpression: plainTypes.CallExpression,
      NonNullExpression: plainTypes.NonNullExpression,
      PrefixUnaryExpression: plainTypes.PrefixUnaryExpression,
      PostfixUnaryExpression: plainTypes.PostfixUnaryExpression,
      TypeOfExpression: plainTypes.TypeOfExpression,
      AwaitExpression: plainTypes.AwaitExpression,
    }),
  },
  UpdateExpression: {
    name: "UpdateExpression",
    getMembers: () => ({
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
      Identifier: plainTypes.Identifier,
      ThisExpression: plainTypes.ThisExpression,
      SuperExpression: plainTypes.SuperExpression,
      ImportExpression: plainTypes.ImportExpression,
      FunctionExpression: plainTypes.FunctionExpression,
      TemplateExpression: plainTypes.TemplateExpression,
      NullLiteral: plainTypes.NullLiteral,
      TrueLiteral: plainTypes.TrueLiteral,
      FalseLiteral: plainTypes.FalseLiteral,
      ArrayLiteralExpression: plainTypes.ArrayLiteralExpression,
      ObjectLiteralExpression: plainTypes.ObjectLiteralExpression,
      JsxAttributes: plainTypes.JsxAttributes,
      ParenthesizedExpression: plainTypes.ParenthesizedExpression,
      NewExpression: plainTypes.NewExpression,
      MetaProperty: plainTypes.MetaProperty,
      JsxElement: plainTypes.JsxElement,
      JsxSelfClosingElement: plainTypes.JsxSelfClosingElement,
      JsxFragment: plainTypes.JsxFragment,
      ClassExpression: plainTypes.ClassExpression,
      PropertyAccessExpression: plainTypes.PropertyAccessExpression,
      ElementAccessExpression: plainTypes.ElementAccessExpression,
      TaggedTemplateExpression: plainTypes.TaggedTemplateExpression,
      CallExpression: plainTypes.CallExpression,
      NonNullExpression: plainTypes.NonNullExpression,
      PrefixUnaryExpression: plainTypes.PrefixUnaryExpression,
      PostfixUnaryExpression: plainTypes.PostfixUnaryExpression,
    }),
  },
  LeftHandSideExpression: {
    name: "LeftHandSideExpression",
    getMembers: () => ({
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
      Identifier: plainTypes.Identifier,
      ThisExpression: plainTypes.ThisExpression,
      SuperExpression: plainTypes.SuperExpression,
      ImportExpression: plainTypes.ImportExpression,
      FunctionExpression: plainTypes.FunctionExpression,
      TemplateExpression: plainTypes.TemplateExpression,
      NullLiteral: plainTypes.NullLiteral,
      TrueLiteral: plainTypes.TrueLiteral,
      FalseLiteral: plainTypes.FalseLiteral,
      ArrayLiteralExpression: plainTypes.ArrayLiteralExpression,
      ObjectLiteralExpression: plainTypes.ObjectLiteralExpression,
      JsxAttributes: plainTypes.JsxAttributes,
      ParenthesizedExpression: plainTypes.ParenthesizedExpression,
      NewExpression: plainTypes.NewExpression,
      MetaProperty: plainTypes.MetaProperty,
      JsxElement: plainTypes.JsxElement,
      JsxSelfClosingElement: plainTypes.JsxSelfClosingElement,
      JsxFragment: plainTypes.JsxFragment,
      ClassExpression: plainTypes.ClassExpression,
      PropertyAccessExpression: plainTypes.PropertyAccessExpression,
      ElementAccessExpression: plainTypes.ElementAccessExpression,
      TaggedTemplateExpression: plainTypes.TaggedTemplateExpression,
      CallExpression: plainTypes.CallExpression,
      NonNullExpression: plainTypes.NonNullExpression,
    }),
  },
  MemberExpression: {
    name: "MemberExpression",
    getMembers: () => ({
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
      Identifier: plainTypes.Identifier,
      ThisExpression: plainTypes.ThisExpression,
      SuperExpression: plainTypes.SuperExpression,
      ImportExpression: plainTypes.ImportExpression,
      FunctionExpression: plainTypes.FunctionExpression,
      TemplateExpression: plainTypes.TemplateExpression,
      NullLiteral: plainTypes.NullLiteral,
      TrueLiteral: plainTypes.TrueLiteral,
      FalseLiteral: plainTypes.FalseLiteral,
      ArrayLiteralExpression: plainTypes.ArrayLiteralExpression,
      ObjectLiteralExpression: plainTypes.ObjectLiteralExpression,
      JsxAttributes: plainTypes.JsxAttributes,
      ParenthesizedExpression: plainTypes.ParenthesizedExpression,
      NewExpression: plainTypes.NewExpression,
      MetaProperty: plainTypes.MetaProperty,
      JsxElement: plainTypes.JsxElement,
      JsxSelfClosingElement: plainTypes.JsxSelfClosingElement,
      JsxFragment: plainTypes.JsxFragment,
      ClassExpression: plainTypes.ClassExpression,
      PropertyAccessExpression: plainTypes.PropertyAccessExpression,
      ElementAccessExpression: plainTypes.ElementAccessExpression,
      TaggedTemplateExpression: plainTypes.TaggedTemplateExpression,
    }),
  },
  PrimaryExpression: {
    name: "PrimaryExpression",
    getMembers: () => ({
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
      Identifier: plainTypes.Identifier,
      ThisExpression: plainTypes.ThisExpression,
      SuperExpression: plainTypes.SuperExpression,
      ImportExpression: plainTypes.ImportExpression,
      FunctionExpression: plainTypes.FunctionExpression,
      TemplateExpression: plainTypes.TemplateExpression,
      NullLiteral: plainTypes.NullLiteral,
      TrueLiteral: plainTypes.TrueLiteral,
      FalseLiteral: plainTypes.FalseLiteral,
      ArrayLiteralExpression: plainTypes.ArrayLiteralExpression,
      ObjectLiteralExpression: plainTypes.ObjectLiteralExpression,
      JsxAttributes: plainTypes.JsxAttributes,
      ParenthesizedExpression: plainTypes.ParenthesizedExpression,
      NewExpression: plainTypes.NewExpression,
      MetaProperty: plainTypes.MetaProperty,
      JsxElement: plainTypes.JsxElement,
      JsxSelfClosingElement: plainTypes.JsxSelfClosingElement,
      JsxFragment: plainTypes.JsxFragment,
      ClassExpression: plainTypes.ClassExpression,
    }),
  },
  LiteralExpression: {
    name: "LiteralExpression",
    getMembers: () => ({
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
    }),
  },
  Statement: {
    name: "Statement",
    getMembers: () => ({
      FunctionDeclaration: plainTypes.FunctionDeclaration,
      ClassDeclaration: plainTypes.ClassDeclaration,
      ModuleDeclaration: plainTypes.ModuleDeclaration,
      InterfaceDeclaration: plainTypes.InterfaceDeclaration,
      TypeAliasDeclaration: plainTypes.TypeAliasDeclaration,
      EnumDeclaration: plainTypes.EnumDeclaration,
      ImportEqualsDeclaration: plainTypes.ImportEqualsDeclaration,
      NamespaceExportDeclaration: plainTypes.NamespaceExportDeclaration,
      ExportDeclaration: plainTypes.ExportDeclaration,
      ExportAssignment: plainTypes.ExportAssignment,
      DoStatement: plainTypes.DoStatement,
      WhileStatement: plainTypes.WhileStatement,
      ForStatement: plainTypes.ForStatement,
      ForInStatement: plainTypes.ForInStatement,
      ForOfStatement: plainTypes.ForOfStatement,
      EmptyStatement: plainTypes.EmptyStatement,
      VariableStatement: plainTypes.VariableStatement,
      ExpressionStatement: plainTypes.ExpressionStatement,
      ReturnStatement: plainTypes.ReturnStatement,
      ThrowStatement: plainTypes.ThrowStatement,
      TryStatement: plainTypes.TryStatement,
      ImportDeclaration: plainTypes.ImportDeclaration,
      IfStatement: plainTypes.IfStatement,
      Block: plainTypes.Block,
      SwitchStatement: plainTypes.SwitchStatement,
      BreakStatement: plainTypes.BreakStatement,
      ContinueStatement: plainTypes.ContinueStatement,
    }),
  },
  DeclarationStatement: {
    name: "DeclarationStatement",
    getMembers: () => ({
      FunctionDeclaration: plainTypes.FunctionDeclaration,
      ClassDeclaration: plainTypes.ClassDeclaration,
      ModuleDeclaration: plainTypes.ModuleDeclaration,
      InterfaceDeclaration: plainTypes.InterfaceDeclaration,
      TypeAliasDeclaration: plainTypes.TypeAliasDeclaration,
      EnumDeclaration: plainTypes.EnumDeclaration,
      ImportEqualsDeclaration: plainTypes.ImportEqualsDeclaration,
      NamespaceExportDeclaration: plainTypes.NamespaceExportDeclaration,
      ExportDeclaration: plainTypes.ExportDeclaration,
      ExportAssignment: plainTypes.ExportAssignment,
    }),
  },
  IterationStatement: {
    name: "IterationStatement",
    getMembers: () => ({
      DoStatement: plainTypes.DoStatement,
      WhileStatement: plainTypes.WhileStatement,
      ForStatement: plainTypes.ForStatement,
      ForInStatement: plainTypes.ForInStatement,
      ForOfStatement: plainTypes.ForOfStatement,
    }),
  },
  ForInitializer: {
    name: "ForInitializer",
    getMembers: () => ({
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
      Identifier: plainTypes.Identifier,
      ThisExpression: plainTypes.ThisExpression,
      SuperExpression: plainTypes.SuperExpression,
      ImportExpression: plainTypes.ImportExpression,
      FunctionExpression: plainTypes.FunctionExpression,
      TemplateExpression: plainTypes.TemplateExpression,
      NullLiteral: plainTypes.NullLiteral,
      TrueLiteral: plainTypes.TrueLiteral,
      FalseLiteral: plainTypes.FalseLiteral,
      ArrayLiteralExpression: plainTypes.ArrayLiteralExpression,
      ObjectLiteralExpression: plainTypes.ObjectLiteralExpression,
      JsxAttributes: plainTypes.JsxAttributes,
      ParenthesizedExpression: plainTypes.ParenthesizedExpression,
      NewExpression: plainTypes.NewExpression,
      MetaProperty: plainTypes.MetaProperty,
      JsxElement: plainTypes.JsxElement,
      JsxSelfClosingElement: plainTypes.JsxSelfClosingElement,
      JsxFragment: plainTypes.JsxFragment,
      ClassExpression: plainTypes.ClassExpression,
      PropertyAccessExpression: plainTypes.PropertyAccessExpression,
      ElementAccessExpression: plainTypes.ElementAccessExpression,
      TaggedTemplateExpression: plainTypes.TaggedTemplateExpression,
      CallExpression: plainTypes.CallExpression,
      NonNullExpression: plainTypes.NonNullExpression,
      PrefixUnaryExpression: plainTypes.PrefixUnaryExpression,
      PostfixUnaryExpression: plainTypes.PostfixUnaryExpression,
      TypeOfExpression: plainTypes.TypeOfExpression,
      AwaitExpression: plainTypes.AwaitExpression,
      BinaryExpression: plainTypes.BinaryExpression,
      ArrowFunction: plainTypes.ArrowFunction,
      ConditionalExpression: plainTypes.ConditionalExpression,
      AsExpression: plainTypes.AsExpression,
      SpreadElement: plainTypes.SpreadElement,
      VariableDeclarationList: plainTypes.VariableDeclarationList,
    }),
  },
  ModuleName: {
    name: "ModuleName",
    getMembers: () => ({
      Identifier: plainTypes.Identifier,
      StringLiteral: plainTypes.StringLiteral,
    }),
  },
  ModuleBody: {
    name: "ModuleBody",
    getMembers: () => ({
      ModuleBlock: plainTypes.ModuleBlock,
      NamespaceDeclaration: plainTypes.NamespaceDeclaration,
    }),
  },
  NamespaceBody: {
    name: "NamespaceBody",
    getMembers: () => ({
      ModuleBlock: plainTypes.ModuleBlock,
      NamespaceDeclaration: plainTypes.NamespaceDeclaration,
    }),
  },
  ModuleReference: {
    name: "ModuleReference",
    getMembers: () => ({
      Identifier: plainTypes.Identifier,
      QualifiedName: plainTypes.QualifiedName,
      ExternalModuleReference: plainTypes.ExternalModuleReference,
    }),
  },
  ClassElement: {
    name: "ClassElement",
    getMembers: () => ({
      PropertyDeclaration: plainTypes.PropertyDeclaration,
      MethodDeclaration: plainTypes.MethodDeclaration,
      ConstructorDeclaration: plainTypes.ConstructorDeclaration,
      GetAccessorDeclaration: plainTypes.GetAccessorDeclaration,
      SetAccessorDeclaration: plainTypes.SetAccessorDeclaration,
      IndexSignatureDeclaration: plainTypes.IndexSignatureDeclaration,
      SemicolonClassElement: plainTypes.SemicolonClassElement,
    }),
  },
  ObjectLiteralElementLike: {
    name: "ObjectLiteralElementLike",
    getMembers: () => ({
      PropertyAssignment: plainTypes.PropertyAssignment,
      ShorthandPropertyAssignment: plainTypes.ShorthandPropertyAssignment,
      SpreadAssignment: plainTypes.SpreadAssignment,
      MethodDeclaration: plainTypes.MethodDeclaration,
      GetAccessorDeclaration: plainTypes.GetAccessorDeclaration,
      SetAccessorDeclaration: plainTypes.SetAccessorDeclaration,
    }),
  },
  BindingName: {
    name: "BindingName",
    getMembers: () => ({
      Identifier: plainTypes.Identifier,
      ArrayBindingPattern: plainTypes.ArrayBindingPattern,
      ObjectBindingPattern: plainTypes.ObjectBindingPattern,
    }),
  },
  FunctionBody: {
    name: "FunctionBody",
    getMembers: () => ({
      Block: plainTypes.Block,
    }),
  },
  ConciseBody: {
    name: "ConciseBody",
    getMembers: () => ({
      Block: plainTypes.Block,
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
      Identifier: plainTypes.Identifier,
      ThisExpression: plainTypes.ThisExpression,
      SuperExpression: plainTypes.SuperExpression,
      ImportExpression: plainTypes.ImportExpression,
      FunctionExpression: plainTypes.FunctionExpression,
      TemplateExpression: plainTypes.TemplateExpression,
      NullLiteral: plainTypes.NullLiteral,
      TrueLiteral: plainTypes.TrueLiteral,
      FalseLiteral: plainTypes.FalseLiteral,
      ArrayLiteralExpression: plainTypes.ArrayLiteralExpression,
      ObjectLiteralExpression: plainTypes.ObjectLiteralExpression,
      JsxAttributes: plainTypes.JsxAttributes,
      ParenthesizedExpression: plainTypes.ParenthesizedExpression,
      NewExpression: plainTypes.NewExpression,
      MetaProperty: plainTypes.MetaProperty,
      JsxElement: plainTypes.JsxElement,
      JsxSelfClosingElement: plainTypes.JsxSelfClosingElement,
      JsxFragment: plainTypes.JsxFragment,
      ClassExpression: plainTypes.ClassExpression,
      PropertyAccessExpression: plainTypes.PropertyAccessExpression,
      ElementAccessExpression: plainTypes.ElementAccessExpression,
      TaggedTemplateExpression: plainTypes.TaggedTemplateExpression,
      CallExpression: plainTypes.CallExpression,
      NonNullExpression: plainTypes.NonNullExpression,
      PrefixUnaryExpression: plainTypes.PrefixUnaryExpression,
      PostfixUnaryExpression: plainTypes.PostfixUnaryExpression,
      TypeOfExpression: plainTypes.TypeOfExpression,
      AwaitExpression: plainTypes.AwaitExpression,
      BinaryExpression: plainTypes.BinaryExpression,
      ArrowFunction: plainTypes.ArrowFunction,
      ConditionalExpression: plainTypes.ConditionalExpression,
      AsExpression: plainTypes.AsExpression,
      SpreadElement: plainTypes.SpreadElement,
    }),
  },
  BindingPattern: {
    name: "BindingPattern",
    getMembers: () => ({
      ArrayBindingPattern: plainTypes.ArrayBindingPattern,
      ObjectBindingPattern: plainTypes.ObjectBindingPattern,
    }),
  },
  ArrayBindingElement: {
    name: "ArrayBindingElement",
    getMembers: () => ({
      BindingElement: plainTypes.BindingElement,
      OmittedExpression: plainTypes.OmittedExpression,
    }),
  },
  PropertyName: {
    name: "PropertyName",
    getMembers: () => ({
      Identifier: plainTypes.Identifier,
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      ComputedPropertyName: plainTypes.ComputedPropertyName,
    }),
  },
  EntityName: {
    name: "EntityName",
    getMembers: () => ({
      Identifier: plainTypes.Identifier,
      QualifiedName: plainTypes.QualifiedName,
    }),
  },
  MemberName: {
    name: "MemberName",
    getMembers: () => ({
      Identifier: plainTypes.Identifier,
      PrivateIdentifier: plainTypes.PrivateIdentifier,
    }),
  },
  JsxChild: {
    name: "JsxChild",
    getMembers: () => ({
      JsxText: plainTypes.JsxText,
      JsxExpression: plainTypes.JsxExpression,
      JsxElement: plainTypes.JsxElement,
      JsxSelfClosingElement: plainTypes.JsxSelfClosingElement,
      JsxFragment: plainTypes.JsxFragment,
    }),
  },
  JsxAttributeLike: {
    name: "JsxAttributeLike",
    getMembers: () => ({
      JsxAttribute: plainTypes.JsxAttribute,
      JsxSpreadAttribute: plainTypes.JsxSpreadAttribute,
    }),
  },
  JsxTagNameExpression: {
    name: "JsxTagNameExpression",
    getMembers: () => ({
      Identifier: plainTypes.Identifier,
      ThisExpression: plainTypes.ThisExpression,
      JsxTagNamePropertyAccess: plainTypes.JsxTagNamePropertyAccess,
    }),
  },
  JsxAttributeInitializer: {
    name: "JsxAttributeInitializer",
    getMembers: () => ({
      StringLiteral: plainTypes.StringLiteral,
      JsxExpression: plainTypes.JsxExpression,
    }),
  },
  TypeNode: {
    name: "TypeNode",
    getMembers: () => ({
      AnyKeyword: plainTypes.AnyKeyword,
      UnknownKeyword: plainTypes.UnknownKeyword,
      NumberKeyword: plainTypes.NumberKeyword,
      ObjectKeyword: plainTypes.ObjectKeyword,
      BooleanKeyword: plainTypes.BooleanKeyword,
      StringKeyword: plainTypes.StringKeyword,
      SymbolKeyword: plainTypes.SymbolKeyword,
      VoidKeyword: plainTypes.VoidKeyword,
      UndefinedKeyword: plainTypes.UndefinedKeyword,
      NeverKeyword: plainTypes.NeverKeyword,
      ThisTypeNode: plainTypes.ThisTypeNode,
      TypeLiteralNode: plainTypes.TypeLiteralNode,
      FunctionTypeNode: plainTypes.FunctionTypeNode,
      ConstructorTypeNode: plainTypes.ConstructorTypeNode,
      TypeReferenceNode: plainTypes.TypeReferenceNode,
      TypeQueryNode: plainTypes.TypeQueryNode,
      ArrayTypeNode: plainTypes.ArrayTypeNode,
      TupleTypeNode: plainTypes.TupleTypeNode,
      ParenthesizedTypeNode: plainTypes.ParenthesizedTypeNode,
      UnionTypeNode: plainTypes.UnionTypeNode,
      IntersectionTypeNode: plainTypes.IntersectionTypeNode,
      TypeOperatorNode: plainTypes.TypeOperatorNode,
      IndexedAccessTypeNode: plainTypes.IndexedAccessTypeNode,
      MappedTypeNode: plainTypes.MappedTypeNode,
      LiteralTypeNode: plainTypes.LiteralTypeNode,
      ExpressionWithTypeArguments: plainTypes.ExpressionWithTypeArguments,
      TypePredicateNode: plainTypes.TypePredicateNode,
      NamedTupleMember: plainTypes.NamedTupleMember,
    }),
  },
  KeywordTypeNode: {
    name: "KeywordTypeNode",
    getMembers: () => ({
      AnyKeyword: plainTypes.AnyKeyword,
      UnknownKeyword: plainTypes.UnknownKeyword,
      NumberKeyword: plainTypes.NumberKeyword,
      ObjectKeyword: plainTypes.ObjectKeyword,
      BooleanKeyword: plainTypes.BooleanKeyword,
      StringKeyword: plainTypes.StringKeyword,
      SymbolKeyword: plainTypes.SymbolKeyword,
      VoidKeyword: plainTypes.VoidKeyword,
      UndefinedKeyword: plainTypes.UndefinedKeyword,
      NeverKeyword: plainTypes.NeverKeyword,
    }),
  },
  TypeElement: {
    name: "TypeElement",
    getMembers: () => ({
      CallSignatureDeclaration: plainTypes.CallSignatureDeclaration,
      ConstructSignatureDeclaration: plainTypes.ConstructSignatureDeclaration,
      PropertySignature: plainTypes.PropertySignature,
      MethodSignature: plainTypes.MethodSignature,
      IndexSignatureDeclaration: plainTypes.IndexSignatureDeclaration,
    }),
  },
  TypePredicateNodeParameterName: {
    name: "TypePredicateNodeParameterName",
    getMembers: () => ({
      Identifier: plainTypes.Identifier,
      ThisTypeNode: plainTypes.ThisTypeNode,
    }),
  },
  LiteralTypeNodeLiteral: {
    name: "LiteralTypeNodeLiteral",
    getMembers: () => ({
      NullLiteral: plainTypes.NullLiteral,
      TrueLiteral: plainTypes.TrueLiteral,
      FalseLiteral: plainTypes.FalseLiteral,
      StringLiteral: plainTypes.StringLiteral,
      NumericLiteral: plainTypes.NumericLiteral,
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
      PrefixUnaryExpression: plainTypes.PrefixUnaryExpression,
    }),
  },
  TemplateSpanLiteral: {
    name: "TemplateSpanLiteral",
    getMembers: () => ({
      TemplateMiddle: plainTypes.TemplateMiddle,
      TemplateTail: plainTypes.TemplateTail,
    }),
  },
  ExponentiationOperatorToken: {
    name: "ExponentiationOperatorToken",
    getMembers: () => ({
      AsteriskAsteriskToken: plainTypes.AsteriskAsteriskToken,
    }),
  },
  MultiplicativeOperatorToken: {
    name: "MultiplicativeOperatorToken",
    getMembers: () => ({
      AsteriskToken: plainTypes.AsteriskToken,
      SlashToken: plainTypes.SlashToken,
      PercentToken: plainTypes.PercentToken,
    }),
  },
  MultiplicativeOperatorOrHigher: {
    name: "MultiplicativeOperatorOrHigher",
    getMembers: () => ({
      AsteriskToken: plainTypes.AsteriskToken,
      SlashToken: plainTypes.SlashToken,
      PercentToken: plainTypes.PercentToken,
      AsteriskAsteriskToken: plainTypes.AsteriskAsteriskToken,
    }),
  },
  AdditiveOperatorToken: {
    name: "AdditiveOperatorToken",
    getMembers: () => ({
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
    }),
  },
  AdditiveOperatorOrHigher: {
    name: "AdditiveOperatorOrHigher",
    getMembers: () => ({
      AsteriskToken: plainTypes.AsteriskToken,
      SlashToken: plainTypes.SlashToken,
      PercentToken: plainTypes.PercentToken,
      AsteriskAsteriskToken: plainTypes.AsteriskAsteriskToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
    }),
  },
  ShiftOperatorToken: {
    name: "ShiftOperatorToken",
    getMembers: () => ({
      LessThanLessThanToken: plainTypes.LessThanLessThanToken,
      GreaterThanGreaterThanToken: plainTypes.GreaterThanGreaterThanToken,
      GreaterThanGreaterThanGreaterThanToken:
        plainTypes.GreaterThanGreaterThanGreaterThanToken,
    }),
  },
  ShiftOperatorOrHigher: {
    name: "ShiftOperatorOrHigher",
    getMembers: () => ({
      AsteriskToken: plainTypes.AsteriskToken,
      SlashToken: plainTypes.SlashToken,
      PercentToken: plainTypes.PercentToken,
      AsteriskAsteriskToken: plainTypes.AsteriskAsteriskToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
      LessThanLessThanToken: plainTypes.LessThanLessThanToken,
      GreaterThanGreaterThanToken: plainTypes.GreaterThanGreaterThanToken,
      GreaterThanGreaterThanGreaterThanToken:
        plainTypes.GreaterThanGreaterThanGreaterThanToken,
    }),
  },
  RelationalOperatorToken: {
    name: "RelationalOperatorToken",
    getMembers: () => ({
      LessThanToken: plainTypes.LessThanToken,
      LessThanEqualsToken: plainTypes.LessThanEqualsToken,
      GreaterThanToken: plainTypes.GreaterThanToken,
      GreaterThanEqualsToken: plainTypes.GreaterThanEqualsToken,
      InstanceOfKeyword: plainTypes.InstanceOfKeyword,
      InKeyword: plainTypes.InKeyword,
    }),
  },
  RelationalOperatorOrHigher: {
    name: "RelationalOperatorOrHigher",
    getMembers: () => ({
      AsteriskToken: plainTypes.AsteriskToken,
      SlashToken: plainTypes.SlashToken,
      PercentToken: plainTypes.PercentToken,
      AsteriskAsteriskToken: plainTypes.AsteriskAsteriskToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
      LessThanLessThanToken: plainTypes.LessThanLessThanToken,
      GreaterThanGreaterThanToken: plainTypes.GreaterThanGreaterThanToken,
      GreaterThanGreaterThanGreaterThanToken:
        plainTypes.GreaterThanGreaterThanGreaterThanToken,
      LessThanToken: plainTypes.LessThanToken,
      LessThanEqualsToken: plainTypes.LessThanEqualsToken,
      GreaterThanToken: plainTypes.GreaterThanToken,
      GreaterThanEqualsToken: plainTypes.GreaterThanEqualsToken,
      InstanceOfKeyword: plainTypes.InstanceOfKeyword,
      InKeyword: plainTypes.InKeyword,
    }),
  },
  EqualityOperatorToken: {
    name: "EqualityOperatorToken",
    getMembers: () => ({
      EqualsEqualsToken: plainTypes.EqualsEqualsToken,
      EqualsEqualsEqualsToken: plainTypes.EqualsEqualsEqualsToken,
      ExclamationEqualsEqualsToken: plainTypes.ExclamationEqualsEqualsToken,
      ExclamationEqualsToken: plainTypes.ExclamationEqualsToken,
    }),
  },
  EqualityOperatorOrHigher: {
    name: "EqualityOperatorOrHigher",
    getMembers: () => ({
      AsteriskToken: plainTypes.AsteriskToken,
      SlashToken: plainTypes.SlashToken,
      PercentToken: plainTypes.PercentToken,
      AsteriskAsteriskToken: plainTypes.AsteriskAsteriskToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
      LessThanLessThanToken: plainTypes.LessThanLessThanToken,
      GreaterThanGreaterThanToken: plainTypes.GreaterThanGreaterThanToken,
      GreaterThanGreaterThanGreaterThanToken:
        plainTypes.GreaterThanGreaterThanGreaterThanToken,
      LessThanToken: plainTypes.LessThanToken,
      LessThanEqualsToken: plainTypes.LessThanEqualsToken,
      GreaterThanToken: plainTypes.GreaterThanToken,
      GreaterThanEqualsToken: plainTypes.GreaterThanEqualsToken,
      InstanceOfKeyword: plainTypes.InstanceOfKeyword,
      InKeyword: plainTypes.InKeyword,
      EqualsEqualsToken: plainTypes.EqualsEqualsToken,
      EqualsEqualsEqualsToken: plainTypes.EqualsEqualsEqualsToken,
      ExclamationEqualsEqualsToken: plainTypes.ExclamationEqualsEqualsToken,
      ExclamationEqualsToken: plainTypes.ExclamationEqualsToken,
    }),
  },
  BitwiseOperatorToken: {
    name: "BitwiseOperatorToken",
    getMembers: () => ({
      AmpersandToken: plainTypes.AmpersandToken,
      BarToken: plainTypes.BarToken,
      CaretToken: plainTypes.CaretToken,
    }),
  },
  BitwiseOperatorOrHigher: {
    name: "BitwiseOperatorOrHigher",
    getMembers: () => ({
      AsteriskToken: plainTypes.AsteriskToken,
      SlashToken: plainTypes.SlashToken,
      PercentToken: plainTypes.PercentToken,
      AsteriskAsteriskToken: plainTypes.AsteriskAsteriskToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
      LessThanLessThanToken: plainTypes.LessThanLessThanToken,
      GreaterThanGreaterThanToken: plainTypes.GreaterThanGreaterThanToken,
      GreaterThanGreaterThanGreaterThanToken:
        plainTypes.GreaterThanGreaterThanGreaterThanToken,
      LessThanToken: plainTypes.LessThanToken,
      LessThanEqualsToken: plainTypes.LessThanEqualsToken,
      GreaterThanToken: plainTypes.GreaterThanToken,
      GreaterThanEqualsToken: plainTypes.GreaterThanEqualsToken,
      InstanceOfKeyword: plainTypes.InstanceOfKeyword,
      InKeyword: plainTypes.InKeyword,
      EqualsEqualsToken: plainTypes.EqualsEqualsToken,
      EqualsEqualsEqualsToken: plainTypes.EqualsEqualsEqualsToken,
      ExclamationEqualsEqualsToken: plainTypes.ExclamationEqualsEqualsToken,
      ExclamationEqualsToken: plainTypes.ExclamationEqualsToken,
      AmpersandToken: plainTypes.AmpersandToken,
      BarToken: plainTypes.BarToken,
      CaretToken: plainTypes.CaretToken,
    }),
  },
  LogicalOperatorToken: {
    name: "LogicalOperatorToken",
    getMembers: () => ({
      AmpersandAmpersandToken: plainTypes.AmpersandAmpersandToken,
      BarBarToken: plainTypes.BarBarToken,
    }),
  },
  LogicalOperatorOrHigher: {
    name: "LogicalOperatorOrHigher",
    getMembers: () => ({
      AsteriskToken: plainTypes.AsteriskToken,
      SlashToken: plainTypes.SlashToken,
      PercentToken: plainTypes.PercentToken,
      AsteriskAsteriskToken: plainTypes.AsteriskAsteriskToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
      LessThanLessThanToken: plainTypes.LessThanLessThanToken,
      GreaterThanGreaterThanToken: plainTypes.GreaterThanGreaterThanToken,
      GreaterThanGreaterThanGreaterThanToken:
        plainTypes.GreaterThanGreaterThanGreaterThanToken,
      LessThanToken: plainTypes.LessThanToken,
      LessThanEqualsToken: plainTypes.LessThanEqualsToken,
      GreaterThanToken: plainTypes.GreaterThanToken,
      GreaterThanEqualsToken: plainTypes.GreaterThanEqualsToken,
      InstanceOfKeyword: plainTypes.InstanceOfKeyword,
      InKeyword: plainTypes.InKeyword,
      EqualsEqualsToken: plainTypes.EqualsEqualsToken,
      EqualsEqualsEqualsToken: plainTypes.EqualsEqualsEqualsToken,
      ExclamationEqualsEqualsToken: plainTypes.ExclamationEqualsEqualsToken,
      ExclamationEqualsToken: plainTypes.ExclamationEqualsToken,
      AmpersandToken: plainTypes.AmpersandToken,
      BarToken: plainTypes.BarToken,
      CaretToken: plainTypes.CaretToken,
      AmpersandAmpersandToken: plainTypes.AmpersandAmpersandToken,
      BarBarToken: plainTypes.BarBarToken,
    }),
  },
  CompoundAssignmentOperatorToken: {
    name: "CompoundAssignmentOperatorToken",
    getMembers: () => ({
      PlusEqualsToken: plainTypes.PlusEqualsToken,
      MinusEqualsToken: plainTypes.MinusEqualsToken,
      AsteriskAsteriskEqualsToken: plainTypes.AsteriskAsteriskEqualsToken,
      AsteriskEqualsToken: plainTypes.AsteriskEqualsToken,
      SlashEqualsToken: plainTypes.SlashEqualsToken,
      PercentEqualsToken: plainTypes.PercentEqualsToken,
      AmpersandEqualsToken: plainTypes.AmpersandEqualsToken,
      BarEqualsToken: plainTypes.BarEqualsToken,
      CaretEqualsToken: plainTypes.CaretEqualsToken,
      LessThanLessThanEqualsToken: plainTypes.LessThanLessThanEqualsToken,
      GreaterThanGreaterThanGreaterThanEqualsToken:
        plainTypes.GreaterThanGreaterThanGreaterThanEqualsToken,
      GreaterThanGreaterThanEqualsToken:
        plainTypes.GreaterThanGreaterThanEqualsToken,
    }),
  },
  AssignmentOperatorToken: {
    name: "AssignmentOperatorToken",
    getMembers: () => ({
      PlusEqualsToken: plainTypes.PlusEqualsToken,
      MinusEqualsToken: plainTypes.MinusEqualsToken,
      AsteriskAsteriskEqualsToken: plainTypes.AsteriskAsteriskEqualsToken,
      AsteriskEqualsToken: plainTypes.AsteriskEqualsToken,
      SlashEqualsToken: plainTypes.SlashEqualsToken,
      PercentEqualsToken: plainTypes.PercentEqualsToken,
      AmpersandEqualsToken: plainTypes.AmpersandEqualsToken,
      BarEqualsToken: plainTypes.BarEqualsToken,
      CaretEqualsToken: plainTypes.CaretEqualsToken,
      LessThanLessThanEqualsToken: plainTypes.LessThanLessThanEqualsToken,
      GreaterThanGreaterThanGreaterThanEqualsToken:
        plainTypes.GreaterThanGreaterThanGreaterThanEqualsToken,
      GreaterThanGreaterThanEqualsToken:
        plainTypes.GreaterThanGreaterThanEqualsToken,
      EqualsToken: plainTypes.EqualsToken,
    }),
  },
  AssignmentOperatorOrHigher: {
    name: "AssignmentOperatorOrHigher",
    getMembers: () => ({
      QuestionQuestionToken: plainTypes.QuestionQuestionToken,
      AsteriskToken: plainTypes.AsteriskToken,
      SlashToken: plainTypes.SlashToken,
      PercentToken: plainTypes.PercentToken,
      AsteriskAsteriskToken: plainTypes.AsteriskAsteriskToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
      LessThanLessThanToken: plainTypes.LessThanLessThanToken,
      GreaterThanGreaterThanToken: plainTypes.GreaterThanGreaterThanToken,
      GreaterThanGreaterThanGreaterThanToken:
        plainTypes.GreaterThanGreaterThanGreaterThanToken,
      LessThanToken: plainTypes.LessThanToken,
      LessThanEqualsToken: plainTypes.LessThanEqualsToken,
      GreaterThanToken: plainTypes.GreaterThanToken,
      GreaterThanEqualsToken: plainTypes.GreaterThanEqualsToken,
      InstanceOfKeyword: plainTypes.InstanceOfKeyword,
      InKeyword: plainTypes.InKeyword,
      EqualsEqualsToken: plainTypes.EqualsEqualsToken,
      EqualsEqualsEqualsToken: plainTypes.EqualsEqualsEqualsToken,
      ExclamationEqualsEqualsToken: plainTypes.ExclamationEqualsEqualsToken,
      ExclamationEqualsToken: plainTypes.ExclamationEqualsToken,
      AmpersandToken: plainTypes.AmpersandToken,
      BarToken: plainTypes.BarToken,
      CaretToken: plainTypes.CaretToken,
      AmpersandAmpersandToken: plainTypes.AmpersandAmpersandToken,
      BarBarToken: plainTypes.BarBarToken,
      PlusEqualsToken: plainTypes.PlusEqualsToken,
      MinusEqualsToken: plainTypes.MinusEqualsToken,
      AsteriskAsteriskEqualsToken: plainTypes.AsteriskAsteriskEqualsToken,
      AsteriskEqualsToken: plainTypes.AsteriskEqualsToken,
      SlashEqualsToken: plainTypes.SlashEqualsToken,
      PercentEqualsToken: plainTypes.PercentEqualsToken,
      AmpersandEqualsToken: plainTypes.AmpersandEqualsToken,
      BarEqualsToken: plainTypes.BarEqualsToken,
      CaretEqualsToken: plainTypes.CaretEqualsToken,
      LessThanLessThanEqualsToken: plainTypes.LessThanLessThanEqualsToken,
      GreaterThanGreaterThanGreaterThanEqualsToken:
        plainTypes.GreaterThanGreaterThanGreaterThanEqualsToken,
      GreaterThanGreaterThanEqualsToken:
        plainTypes.GreaterThanGreaterThanEqualsToken,
      EqualsToken: plainTypes.EqualsToken,
    }),
  },
  BinaryOperatorToken: {
    name: "BinaryOperatorToken",
    getMembers: () => ({
      QuestionQuestionToken: plainTypes.QuestionQuestionToken,
      AsteriskToken: plainTypes.AsteriskToken,
      SlashToken: plainTypes.SlashToken,
      PercentToken: plainTypes.PercentToken,
      AsteriskAsteriskToken: plainTypes.AsteriskAsteriskToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
      LessThanLessThanToken: plainTypes.LessThanLessThanToken,
      GreaterThanGreaterThanToken: plainTypes.GreaterThanGreaterThanToken,
      GreaterThanGreaterThanGreaterThanToken:
        plainTypes.GreaterThanGreaterThanGreaterThanToken,
      LessThanToken: plainTypes.LessThanToken,
      LessThanEqualsToken: plainTypes.LessThanEqualsToken,
      GreaterThanToken: plainTypes.GreaterThanToken,
      GreaterThanEqualsToken: plainTypes.GreaterThanEqualsToken,
      InstanceOfKeyword: plainTypes.InstanceOfKeyword,
      InKeyword: plainTypes.InKeyword,
      EqualsEqualsToken: plainTypes.EqualsEqualsToken,
      EqualsEqualsEqualsToken: plainTypes.EqualsEqualsEqualsToken,
      ExclamationEqualsEqualsToken: plainTypes.ExclamationEqualsEqualsToken,
      ExclamationEqualsToken: plainTypes.ExclamationEqualsToken,
      AmpersandToken: plainTypes.AmpersandToken,
      BarToken: plainTypes.BarToken,
      CaretToken: plainTypes.CaretToken,
      AmpersandAmpersandToken: plainTypes.AmpersandAmpersandToken,
      BarBarToken: plainTypes.BarBarToken,
      PlusEqualsToken: plainTypes.PlusEqualsToken,
      MinusEqualsToken: plainTypes.MinusEqualsToken,
      AsteriskAsteriskEqualsToken: plainTypes.AsteriskAsteriskEqualsToken,
      AsteriskEqualsToken: plainTypes.AsteriskEqualsToken,
      SlashEqualsToken: plainTypes.SlashEqualsToken,
      PercentEqualsToken: plainTypes.PercentEqualsToken,
      AmpersandEqualsToken: plainTypes.AmpersandEqualsToken,
      BarEqualsToken: plainTypes.BarEqualsToken,
      CaretEqualsToken: plainTypes.CaretEqualsToken,
      LessThanLessThanEqualsToken: plainTypes.LessThanLessThanEqualsToken,
      GreaterThanGreaterThanGreaterThanEqualsToken:
        plainTypes.GreaterThanGreaterThanGreaterThanEqualsToken,
      GreaterThanGreaterThanEqualsToken:
        plainTypes.GreaterThanGreaterThanEqualsToken,
      EqualsToken: plainTypes.EqualsToken,
      CommaToken: plainTypes.CommaToken,
    }),
  },
  PrefixUnaryOperator: {
    name: "PrefixUnaryOperator",
    getMembers: () => ({
      PlusPlusToken: plainTypes.PlusPlusToken,
      MinusMinusToken: plainTypes.MinusMinusToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
      TildeToken: plainTypes.TildeToken,
      ExclamationToken: plainTypes.ExclamationToken,
    }),
  },
  PostfixUnaryOperator: {
    name: "PostfixUnaryOperator",
    getMembers: () => ({
      PlusPlusToken: plainTypes.PlusPlusToken,
      MinusMinusToken: plainTypes.MinusMinusToken,
    }),
  },
  ReadonlyMappedToken: {
    name: "ReadonlyMappedToken",
    getMembers: () => ({
      ReadonlyToken: plainTypes.ReadonlyToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
    }),
  },
  QuestionMappedToken: {
    name: "QuestionMappedToken",
    getMembers: () => ({
      QuestionToken: plainTypes.QuestionToken,
      PlusToken: plainTypes.PlusToken,
      MinusToken: plainTypes.MinusToken,
    }),
  },
  CaseOrDefaultClause: {
    name: "CaseOrDefaultClause",
    getMembers: () => ({
      CaseClause: plainTypes.CaseClause,
      DefaultClause: plainTypes.DefaultClause,
    }),
  },
  NamedImportBindings: {
    name: "NamedImportBindings",
    getMembers: () => ({
      NamespaceImport: plainTypes.NamespaceImport,
      NamedImports: plainTypes.NamedImports,
    }),
  },
  NamedExportBindings: {
    name: "NamedExportBindings",
    getMembers: () => ({
      NamespaceExport: plainTypes.NamespaceExport,
      NamedExports: plainTypes.NamedExports,
    }),
  },
  NamedDeclaration: {
    name: "NamedDeclaration",
    getMembers: () => ({
      NamespaceImport: plainTypes.NamespaceImport,
      NamespaceExport: plainTypes.NamespaceExport,
    }),
  },
  BooleanLiteral: {
    name: "BooleanLiteral",
    getMembers: () => ({
      TrueLiteral: plainTypes.TrueLiteral,
      FalseLiteral: plainTypes.FalseLiteral,
    }),
  },
  // Unit unions from plain types and token types
  NumericLiteral: {
    name: "NumericLiteral",
    getMembers: () => ({
      NumericLiteral: plainTypes.NumericLiteral,
    }),
  },
  StringLiteral: {
    name: "StringLiteral",
    getMembers: () => ({
      StringLiteral: plainTypes.StringLiteral,
    }),
  },
  ArrayLiteralExpression: {
    name: "ArrayLiteralExpression",
    getMembers: () => ({
      ArrayLiteralExpression: plainTypes.ArrayLiteralExpression,
    }),
  },
  VariableDeclaration: {
    name: "VariableDeclaration",
    getMembers: () => ({
      VariableDeclaration: plainTypes.VariableDeclaration,
    }),
  },
  VariableDeclarationList: {
    name: "VariableDeclarationList",
    getMembers: () => ({
      VariableDeclarationList: plainTypes.VariableDeclarationList,
    }),
  },
  EmptyStatement: {
    name: "EmptyStatement",
    getMembers: () => ({
      EmptyStatement: plainTypes.EmptyStatement,
    }),
  },
  ThrowStatement: {
    name: "ThrowStatement",
    getMembers: () => ({
      ThrowStatement: plainTypes.ThrowStatement,
    }),
  },
  VariableStatement: {
    name: "VariableStatement",
    getMembers: () => ({
      VariableStatement: plainTypes.VariableStatement,
    }),
  },
  Identifier: {
    name: "Identifier",
    getMembers: () => ({
      Identifier: plainTypes.Identifier,
    }),
  },
  PrivateIdentifier: {
    name: "PrivateIdentifier",
    getMembers: () => ({
      PrivateIdentifier: plainTypes.PrivateIdentifier,
    }),
  },
  FunctionDeclaration: {
    name: "FunctionDeclaration",
    getMembers: () => ({
      FunctionDeclaration: plainTypes.FunctionDeclaration,
    }),
  },
  Block: {
    name: "Block",
    getMembers: () => ({
      Block: plainTypes.Block,
    }),
  },
  ExpressionStatement: {
    name: "ExpressionStatement",
    getMembers: () => ({
      ExpressionStatement: plainTypes.ExpressionStatement,
    }),
  },
  ParameterDeclaration: {
    name: "ParameterDeclaration",
    getMembers: () => ({
      ParameterDeclaration: plainTypes.ParameterDeclaration,
    }),
  },
  ArrayBindingPattern: {
    name: "ArrayBindingPattern",
    getMembers: () => ({
      ArrayBindingPattern: plainTypes.ArrayBindingPattern,
    }),
  },
  ObjectBindingPattern: {
    name: "ObjectBindingPattern",
    getMembers: () => ({
      ObjectBindingPattern: plainTypes.ObjectBindingPattern,
    }),
  },
  OmittedExpression: {
    name: "OmittedExpression",
    getMembers: () => ({
      OmittedExpression: plainTypes.OmittedExpression,
    }),
  },
  BindingElement: {
    name: "BindingElement",
    getMembers: () => ({
      BindingElement: plainTypes.BindingElement,
    }),
  },
  ComputedPropertyName: {
    name: "ComputedPropertyName",
    getMembers: () => ({
      ComputedPropertyName: plainTypes.ComputedPropertyName,
    }),
  },
  RegularExpressionLiteral: {
    name: "RegularExpressionLiteral",
    getMembers: () => ({
      RegularExpressionLiteral: plainTypes.RegularExpressionLiteral,
    }),
  },
  NoSubstitutionTemplateLiteral: {
    name: "NoSubstitutionTemplateLiteral",
    getMembers: () => ({
      NoSubstitutionTemplateLiteral: plainTypes.NoSubstitutionTemplateLiteral,
    }),
  },
  FunctionExpression: {
    name: "FunctionExpression",
    getMembers: () => ({
      FunctionExpression: plainTypes.FunctionExpression,
    }),
  },
  TemplateExpression: {
    name: "TemplateExpression",
    getMembers: () => ({
      TemplateExpression: plainTypes.TemplateExpression,
    }),
  },
  ObjectLiteralExpression: {
    name: "ObjectLiteralExpression",
    getMembers: () => ({
      ObjectLiteralExpression: plainTypes.ObjectLiteralExpression,
    }),
  },
  ParenthesizedExpression: {
    name: "ParenthesizedExpression",
    getMembers: () => ({
      ParenthesizedExpression: plainTypes.ParenthesizedExpression,
    }),
  },
  NewExpression: {
    name: "NewExpression",
    getMembers: () => ({
      NewExpression: plainTypes.NewExpression,
    }),
  },
  MetaProperty: {
    name: "MetaProperty",
    getMembers: () => ({
      MetaProperty: plainTypes.MetaProperty,
    }),
  },
  JsxElement: {
    name: "JsxElement",
    getMembers: () => ({
      JsxElement: plainTypes.JsxElement,
    }),
  },
  JsxSelfClosingElement: {
    name: "JsxSelfClosingElement",
    getMembers: () => ({
      JsxSelfClosingElement: plainTypes.JsxSelfClosingElement,
    }),
  },
  JsxOpeningElement: {
    name: "JsxOpeningElement",
    getMembers: () => ({
      JsxOpeningElement: plainTypes.JsxOpeningElement,
    }),
  },
  JsxClosingElement: {
    name: "JsxClosingElement",
    getMembers: () => ({
      JsxClosingElement: plainTypes.JsxClosingElement,
    }),
  },
  JsxFragment: {
    name: "JsxFragment",
    getMembers: () => ({
      JsxFragment: plainTypes.JsxFragment,
    }),
  },
  JsxAttribute: {
    name: "JsxAttribute",
    getMembers: () => ({
      JsxAttribute: plainTypes.JsxAttribute,
    }),
  },
  JsxAttributes: {
    name: "JsxAttributes",
    getMembers: () => ({
      JsxAttributes: plainTypes.JsxAttributes,
    }),
  },
  JsxSpreadAttribute: {
    name: "JsxSpreadAttribute",
    getMembers: () => ({
      JsxSpreadAttribute: plainTypes.JsxSpreadAttribute,
    }),
  },
  JsxExpression: {
    name: "JsxExpression",
    getMembers: () => ({
      JsxExpression: plainTypes.JsxExpression,
    }),
  },
  JsxText: {
    name: "JsxText",
    getMembers: () => ({
      JsxText: plainTypes.JsxText,
    }),
  },
  JsxTagNamePropertyAccess: {
    name: "JsxTagNamePropertyAccess",
    getMembers: () => ({
      JsxTagNamePropertyAccess: plainTypes.JsxTagNamePropertyAccess,
    }),
  },
  ClassExpression: {
    name: "ClassExpression",
    getMembers: () => ({
      ClassExpression: plainTypes.ClassExpression,
    }),
  },
  PropertyAccessExpression: {
    name: "PropertyAccessExpression",
    getMembers: () => ({
      PropertyAccessExpression: plainTypes.PropertyAccessExpression,
    }),
  },
  ElementAccessExpression: {
    name: "ElementAccessExpression",
    getMembers: () => ({
      ElementAccessExpression: plainTypes.ElementAccessExpression,
    }),
  },
  TaggedTemplateExpression: {
    name: "TaggedTemplateExpression",
    getMembers: () => ({
      TaggedTemplateExpression: plainTypes.TaggedTemplateExpression,
    }),
  },
  CallExpression: {
    name: "CallExpression",
    getMembers: () => ({
      CallExpression: plainTypes.CallExpression,
    }),
  },
  ImportDeclaration: {
    name: "ImportDeclaration",
    getMembers: () => ({
      ImportDeclaration: plainTypes.ImportDeclaration,
    }),
  },
  ImportClause: {
    name: "ImportClause",
    getMembers: () => ({
      ImportClause: plainTypes.ImportClause,
    }),
  },
  NamespaceImport: {
    name: "NamespaceImport",
    getMembers: () => ({
      NamespaceImport: plainTypes.NamespaceImport,
    }),
  },
  NamespaceExport: {
    name: "NamespaceExport",
    getMembers: () => ({
      NamespaceExport: plainTypes.NamespaceExport,
    }),
  },
  NamedImports: {
    name: "NamedImports",
    getMembers: () => ({
      NamedImports: plainTypes.NamedImports,
    }),
  },
  NamedExports: {
    name: "NamedExports",
    getMembers: () => ({
      NamedExports: plainTypes.NamedExports,
    }),
  },
  ImportSpecifier: {
    name: "ImportSpecifier",
    getMembers: () => ({
      ImportSpecifier: plainTypes.ImportSpecifier,
    }),
  },
  ExportSpecifier: {
    name: "ExportSpecifier",
    getMembers: () => ({
      ExportSpecifier: plainTypes.ExportSpecifier,
    }),
  },
  ClassDeclaration: {
    name: "ClassDeclaration",
    getMembers: () => ({
      ClassDeclaration: plainTypes.ClassDeclaration,
    }),
  },
  HeritageClause: {
    name: "HeritageClause",
    getMembers: () => ({
      HeritageClause: plainTypes.HeritageClause,
    }),
  },
  ExpressionWithTypeArguments: {
    name: "ExpressionWithTypeArguments",
    getMembers: () => ({
      ExpressionWithTypeArguments: plainTypes.ExpressionWithTypeArguments,
    }),
  },
  PropertyDeclaration: {
    name: "PropertyDeclaration",
    getMembers: () => ({
      PropertyDeclaration: plainTypes.PropertyDeclaration,
    }),
  },
  MethodDeclaration: {
    name: "MethodDeclaration",
    getMembers: () => ({
      MethodDeclaration: plainTypes.MethodDeclaration,
    }),
  },
  ConstructorDeclaration: {
    name: "ConstructorDeclaration",
    getMembers: () => ({
      ConstructorDeclaration: plainTypes.ConstructorDeclaration,
    }),
  },
  GetAccessorDeclaration: {
    name: "GetAccessorDeclaration",
    getMembers: () => ({
      GetAccessorDeclaration: plainTypes.GetAccessorDeclaration,
    }),
  },
  SetAccessorDeclaration: {
    name: "SetAccessorDeclaration",
    getMembers: () => ({
      SetAccessorDeclaration: plainTypes.SetAccessorDeclaration,
    }),
  },
  IndexSignatureDeclaration: {
    name: "IndexSignatureDeclaration",
    getMembers: () => ({
      IndexSignatureDeclaration: plainTypes.IndexSignatureDeclaration,
    }),
  },
  SemicolonClassElement: {
    name: "SemicolonClassElement",
    getMembers: () => ({
      SemicolonClassElement: plainTypes.SemicolonClassElement,
    }),
  },
  PropertyAssignment: {
    name: "PropertyAssignment",
    getMembers: () => ({
      PropertyAssignment: plainTypes.PropertyAssignment,
    }),
  },
  ShorthandPropertyAssignment: {
    name: "ShorthandPropertyAssignment",
    getMembers: () => ({
      ShorthandPropertyAssignment: plainTypes.ShorthandPropertyAssignment,
    }),
  },
  SpreadAssignment: {
    name: "SpreadAssignment",
    getMembers: () => ({
      SpreadAssignment: plainTypes.SpreadAssignment,
    }),
  },
  ArrowFunction: {
    name: "ArrowFunction",
    getMembers: () => ({
      ArrowFunction: plainTypes.ArrowFunction,
    }),
  },
  ReturnStatement: {
    name: "ReturnStatement",
    getMembers: () => ({
      ReturnStatement: plainTypes.ReturnStatement,
    }),
  },
  IfStatement: {
    name: "IfStatement",
    getMembers: () => ({
      IfStatement: plainTypes.IfStatement,
    }),
  },
  ConditionalExpression: {
    name: "ConditionalExpression",
    getMembers: () => ({
      ConditionalExpression: plainTypes.ConditionalExpression,
    }),
  },
  BinaryExpression: {
    name: "BinaryExpression",
    getMembers: () => ({
      BinaryExpression: plainTypes.BinaryExpression,
    }),
  },
  PrefixUnaryExpression: {
    name: "PrefixUnaryExpression",
    getMembers: () => ({
      PrefixUnaryExpression: plainTypes.PrefixUnaryExpression,
    }),
  },
  PostfixUnaryExpression: {
    name: "PostfixUnaryExpression",
    getMembers: () => ({
      PostfixUnaryExpression: plainTypes.PostfixUnaryExpression,
    }),
  },
  TemplateHead: {
    name: "TemplateHead",
    getMembers: () => ({
      TemplateHead: plainTypes.TemplateHead,
    }),
  },
  TemplateMiddle: {
    name: "TemplateMiddle",
    getMembers: () => ({
      TemplateMiddle: plainTypes.TemplateMiddle,
    }),
  },
  TemplateTail: {
    name: "TemplateTail",
    getMembers: () => ({
      TemplateTail: plainTypes.TemplateTail,
    }),
  },
  TemplateSpan: {
    name: "TemplateSpan",
    getMembers: () => ({
      TemplateSpan: plainTypes.TemplateSpan,
    }),
  },
  AsExpression: {
    name: "AsExpression",
    getMembers: () => ({
      AsExpression: plainTypes.AsExpression,
    }),
  },
  TypeParameterDeclaration: {
    name: "TypeParameterDeclaration",
    getMembers: () => ({
      TypeParameterDeclaration: plainTypes.TypeParameterDeclaration,
    }),
  },
  FunctionTypeNode: {
    name: "FunctionTypeNode",
    getMembers: () => ({
      FunctionTypeNode: plainTypes.FunctionTypeNode,
    }),
  },
  ConstructorTypeNode: {
    name: "ConstructorTypeNode",
    getMembers: () => ({
      ConstructorTypeNode: plainTypes.ConstructorTypeNode,
    }),
  },
  QualifiedName: {
    name: "QualifiedName",
    getMembers: () => ({
      QualifiedName: plainTypes.QualifiedName,
    }),
  },
  TypeReferenceNode: {
    name: "TypeReferenceNode",
    getMembers: () => ({
      TypeReferenceNode: plainTypes.TypeReferenceNode,
    }),
  },
  TypePredicateNode: {
    name: "TypePredicateNode",
    getMembers: () => ({
      TypePredicateNode: plainTypes.TypePredicateNode,
    }),
  },
  TypeQueryNode: {
    name: "TypeQueryNode",
    getMembers: () => ({
      TypeQueryNode: plainTypes.TypeQueryNode,
    }),
  },
  CallSignatureDeclaration: {
    name: "CallSignatureDeclaration",
    getMembers: () => ({
      CallSignatureDeclaration: plainTypes.CallSignatureDeclaration,
    }),
  },
  ConstructSignatureDeclaration: {
    name: "ConstructSignatureDeclaration",
    getMembers: () => ({
      ConstructSignatureDeclaration: plainTypes.ConstructSignatureDeclaration,
    }),
  },
  PropertySignature: {
    name: "PropertySignature",
    getMembers: () => ({
      PropertySignature: plainTypes.PropertySignature,
    }),
  },
  MethodSignature: {
    name: "MethodSignature",
    getMembers: () => ({
      MethodSignature: plainTypes.MethodSignature,
    }),
  },
  TypeLiteralNode: {
    name: "TypeLiteralNode",
    getMembers: () => ({
      TypeLiteralNode: plainTypes.TypeLiteralNode,
    }),
  },
  ArrayTypeNode: {
    name: "ArrayTypeNode",
    getMembers: () => ({
      ArrayTypeNode: plainTypes.ArrayTypeNode,
    }),
  },
  TupleTypeNode: {
    name: "TupleTypeNode",
    getMembers: () => ({
      TupleTypeNode: plainTypes.TupleTypeNode,
    }),
  },
  UnionTypeNode: {
    name: "UnionTypeNode",
    getMembers: () => ({
      UnionTypeNode: plainTypes.UnionTypeNode,
    }),
  },
  IntersectionTypeNode: {
    name: "IntersectionTypeNode",
    getMembers: () => ({
      IntersectionTypeNode: plainTypes.IntersectionTypeNode,
    }),
  },
  ParenthesizedTypeNode: {
    name: "ParenthesizedTypeNode",
    getMembers: () => ({
      ParenthesizedTypeNode: plainTypes.ParenthesizedTypeNode,
    }),
  },
  TypeOperatorNode: {
    name: "TypeOperatorNode",
    getMembers: () => ({
      TypeOperatorNode: plainTypes.TypeOperatorNode,
    }),
  },
  IndexedAccessTypeNode: {
    name: "IndexedAccessTypeNode",
    getMembers: () => ({
      IndexedAccessTypeNode: plainTypes.IndexedAccessTypeNode,
    }),
  },
  MappedTypeNode: {
    name: "MappedTypeNode",
    getMembers: () => ({
      MappedTypeNode: plainTypes.MappedTypeNode,
    }),
  },
  LiteralTypeNode: {
    name: "LiteralTypeNode",
    getMembers: () => ({
      LiteralTypeNode: plainTypes.LiteralTypeNode,
    }),
  },
  InterfaceDeclaration: {
    name: "InterfaceDeclaration",
    getMembers: () => ({
      InterfaceDeclaration: plainTypes.InterfaceDeclaration,
    }),
  },
  TypeAliasDeclaration: {
    name: "TypeAliasDeclaration",
    getMembers: () => ({
      TypeAliasDeclaration: plainTypes.TypeAliasDeclaration,
    }),
  },
  EnumDeclaration: {
    name: "EnumDeclaration",
    getMembers: () => ({
      EnumDeclaration: plainTypes.EnumDeclaration,
    }),
  },
  ModuleDeclaration: {
    name: "ModuleDeclaration",
    getMembers: () => ({
      ModuleDeclaration: plainTypes.ModuleDeclaration,
    }),
  },
  ImportEqualsDeclaration: {
    name: "ImportEqualsDeclaration",
    getMembers: () => ({
      ImportEqualsDeclaration: plainTypes.ImportEqualsDeclaration,
    }),
  },
  NamespaceExportDeclaration: {
    name: "NamespaceExportDeclaration",
    getMembers: () => ({
      NamespaceExportDeclaration: plainTypes.NamespaceExportDeclaration,
    }),
  },
  ExportDeclaration: {
    name: "ExportDeclaration",
    getMembers: () => ({
      ExportDeclaration: plainTypes.ExportDeclaration,
    }),
  },
  ExportAssignment: {
    name: "ExportAssignment",
    getMembers: () => ({
      ExportAssignment: plainTypes.ExportAssignment,
    }),
  },
  EnumMember: {
    name: "EnumMember",
    getMembers: () => ({
      EnumMember: plainTypes.EnumMember,
    }),
  },
  ModuleBlock: {
    name: "ModuleBlock",
    getMembers: () => ({
      ModuleBlock: plainTypes.ModuleBlock,
    }),
  },
  NamespaceDeclaration: {
    name: "NamespaceDeclaration",
    getMembers: () => ({
      NamespaceDeclaration: plainTypes.NamespaceDeclaration,
    }),
  },
  ExternalModuleReference: {
    name: "ExternalModuleReference",
    getMembers: () => ({
      ExternalModuleReference: plainTypes.ExternalModuleReference,
    }),
  },
  TypeOfExpression: {
    name: "TypeOfExpression",
    getMembers: () => ({
      TypeOfExpression: plainTypes.TypeOfExpression,
    }),
  },
  AwaitExpression: {
    name: "AwaitExpression",
    getMembers: () => ({
      AwaitExpression: plainTypes.AwaitExpression,
    }),
  },
  NonNullExpression: {
    name: "NonNullExpression",
    getMembers: () => ({
      NonNullExpression: plainTypes.NonNullExpression,
    }),
  },
  DoStatement: {
    name: "DoStatement",
    getMembers: () => ({
      DoStatement: plainTypes.DoStatement,
    }),
  },
  WhileStatement: {
    name: "WhileStatement",
    getMembers: () => ({
      WhileStatement: plainTypes.WhileStatement,
    }),
  },
  ForStatement: {
    name: "ForStatement",
    getMembers: () => ({
      ForStatement: plainTypes.ForStatement,
    }),
  },
  ForInStatement: {
    name: "ForInStatement",
    getMembers: () => ({
      ForInStatement: plainTypes.ForInStatement,
    }),
  },
  ForOfStatement: {
    name: "ForOfStatement",
    getMembers: () => ({
      ForOfStatement: plainTypes.ForOfStatement,
    }),
  },
  SpreadElement: {
    name: "SpreadElement",
    getMembers: () => ({
      SpreadElement: plainTypes.SpreadElement,
    }),
  },
  TryStatement: {
    name: "TryStatement",
    getMembers: () => ({
      TryStatement: plainTypes.TryStatement,
    }),
  },
  CatchClause: {
    name: "CatchClause",
    getMembers: () => ({
      CatchClause: plainTypes.CatchClause,
    }),
  },
  SwitchStatement: {
    name: "SwitchStatement",
    getMembers: () => ({
      SwitchStatement: plainTypes.SwitchStatement,
    }),
  },
  CaseBlock: {
    name: "CaseBlock",
    getMembers: () => ({
      CaseBlock: plainTypes.CaseBlock,
    }),
  },
  CaseClause: {
    name: "CaseClause",
    getMembers: () => ({
      CaseClause: plainTypes.CaseClause,
    }),
  },
  DefaultClause: {
    name: "DefaultClause",
    getMembers: () => ({
      DefaultClause: plainTypes.DefaultClause,
    }),
  },
  BreakStatement: {
    name: "BreakStatement",
    getMembers: () => ({
      BreakStatement: plainTypes.BreakStatement,
    }),
  },
  ContinueStatement: {
    name: "ContinueStatement",
    getMembers: () => ({
      ContinueStatement: plainTypes.ContinueStatement,
    }),
  },
  NamedTupleMember: {
    name: "NamedTupleMember",
    getMembers: () => ({
      NamedTupleMember: plainTypes.NamedTupleMember,
    }),
  },
  NullLiteral: {
    name: "NullLiteral",
    getMembers: () => ({
      NullLiteral: plainTypes.NullLiteral,
    }),
  },
  TrueLiteral: {
    name: "TrueLiteral",
    getMembers: () => ({
      TrueLiteral: plainTypes.TrueLiteral,
    }),
  },
  FalseLiteral: {
    name: "FalseLiteral",
    getMembers: () => ({
      FalseLiteral: plainTypes.FalseLiteral,
    }),
  },
  ThisExpression: {
    name: "ThisExpression",
    getMembers: () => ({
      ThisExpression: plainTypes.ThisExpression,
    }),
  },
  SuperExpression: {
    name: "SuperExpression",
    getMembers: () => ({
      SuperExpression: plainTypes.SuperExpression,
    }),
  },
  ImportExpression: {
    name: "ImportExpression",
    getMembers: () => ({
      ImportExpression: plainTypes.ImportExpression,
    }),
  },
  DotDotDotToken: {
    name: "DotDotDotToken",
    getMembers: () => ({
      DotDotDotToken: plainTypes.DotDotDotToken,
    }),
  },
  QuestionToken: {
    name: "QuestionToken",
    getMembers: () => ({
      QuestionToken: plainTypes.QuestionToken,
    }),
  },
  QuestionDotToken: {
    name: "QuestionDotToken",
    getMembers: () => ({
      QuestionDotToken: plainTypes.QuestionDotToken,
    }),
  },
  AsteriskToken: {
    name: "AsteriskToken",
    getMembers: () => ({
      AsteriskToken: plainTypes.AsteriskToken,
    }),
  },
  ThisTypeNode: {
    name: "ThisTypeNode",
    getMembers: () => ({
      ThisTypeNode: plainTypes.ThisTypeNode,
    }),
  },
  ReadonlyToken: {
    name: "ReadonlyToken",
    getMembers: () => ({
      ReadonlyToken: plainTypes.ReadonlyToken,
    }),
  },
  EqualsToken: {
    name: "EqualsToken",
    getMembers: () => ({
      EqualsToken: plainTypes.EqualsToken,
    }),
  },
  AwaitKeywordToken: {
    name: "AwaitKeywordToken",
    getMembers: () => ({
      AwaitKeywordToken: plainTypes.AwaitKeywordToken,
    }),
  },
};

const StringLiteral: StringTemplate<ts.StringLiteral> = {
  match: plainTypes.StringLiteral.match,
  load: (built) => built.text,
  build: ts.createLiteral,
};

const NumericLiteral: StringTemplate<ts.NumericLiteral> = {
  match: plainTypes.NumericLiteral.match,
  load: (built) => built.text,
  build: (t) => {
    if ((+t).toString() !== t) {
      throw new Error("Invalid number");
    }
    return ts.createLiteral(+t);
  },
};

const Identifier: StringTemplate<ts.Identifier> = {
  match: plainTypes.Identifier.match,
  load: (built) => built.text,
  build: ts.createIdentifier,
};

const PrivateIdentifier: StringTemplate<ts.PrivateIdentifier> = {
  match: plainTypes.PrivateIdentifier.match,
  load: (built) => built.text,
  build: ts.createPrivateIdentifier,
};

const RegularExpressionLiteral: StringTemplate<ts.RegularExpressionLiteral> = {
  match: plainTypes.RegularExpressionLiteral.match,
  load: (built) => built.text,
  build: (t) => ({
    ...(ts.createTemplateHead(t) as any),
    kind: ts.SyntaxKind.RegularExpressionLiteral,
  }),
};

const TemplateHead: StringTemplate<ts.TemplateHead> = {
  match: plainTypes.TemplateHead.match,
  load: (built) => built.text,
  build: ts.createTemplateHead,
};

const TemplateMiddle: StringTemplate<ts.TemplateMiddle> = {
  match: plainTypes.TemplateMiddle.match,
  load: (built) => built.text,
  build: ts.createTemplateMiddle,
};

const TemplateTail: StringTemplate<ts.TemplateTail> = {
  match: plainTypes.TemplateTail.match,
  load: (built) => built.text,
  build: ts.createTemplateTail,
};

const JsxText: StringTemplate<ts.JsxText> = {
  match: plainTypes.JsxText.match,
  load: (built) => built.text,
  build: ts.createJsxText,
};

export const stringTemplates = [
  StringLiteral,
  NumericLiteral,
  Identifier,
  PrivateIdentifier,
  RegularExpressionLiteral,
  TemplateHead,
  TemplateMiddle,
  TemplateTail,
  JsxText,
];

const ArrayLiteralExpression: ListTemplate<
  ts.ArrayLiteralExpression,
  ts.Expression
> = {
  match: plainTypes.ArrayLiteralExpression.match,
  flags: [] as FlagKind[],
  load: (built) => built.elements,
  build: (children) => ts.createArrayLiteral(children),
  childUnion: unions.Expression,
};

const ObjectLiteralExpression: ListTemplate<
  ts.ObjectLiteralExpression,
  ts.ObjectLiteralElementLike
> = {
  match: plainTypes.ObjectLiteralExpression.match,
  flags: [] as FlagKind[],
  load: (built) => built.properties,
  build: (children) => ts.createObjectLiteral(children),
  childUnion: unions.ObjectLiteralElementLike,
};

const VariableDeclarationList: ListTemplate<
  ts.VariableDeclarationList,
  ts.VariableDeclaration
> = {
  match: plainTypes.VariableDeclarationList.match,
  flags: ["variableFlavor"] as FlagKind[],
  load: (built) => built.declarations,
  build: (children) => ts.createVariableDeclarationList(children),
  childUnion: unions.VariableDeclaration,
};

const Block: ListTemplate<ts.Block, ts.Statement> = {
  match: plainTypes.Block.match,
  flags: [] as FlagKind[],
  load: (built) => built.statements,
  build: (children) => ts.createBlock(children),
  childUnion: unions.Statement,
};

const ArrayBindingPattern: ListTemplate<
  ts.ArrayBindingPattern,
  ts.ArrayBindingElement
> = {
  match: plainTypes.ArrayBindingPattern.match,
  flags: [] as FlagKind[],
  load: (built) => built.elements,
  build: (children) => ts.createArrayBindingPattern(children),
  childUnion: unions.ArrayBindingElement,
};

const ObjectBindingPattern: ListTemplate<
  ts.ObjectBindingPattern,
  ts.BindingElement
> = {
  match: plainTypes.ObjectBindingPattern.match,
  flags: [] as FlagKind[],
  load: (built) => built.elements,
  build: (children) => ts.createObjectBindingPattern(children),
  childUnion: unions.BindingElement,
};

const NamedImports: ListTemplate<ts.NamedImports, ts.ImportSpecifier> = {
  match: plainTypes.NamedImports.match,
  flags: [] as FlagKind[],
  load: (built) => built.elements,
  build: (children) => ts.createNamedImports(children),
  childUnion: unions.ImportSpecifier,
};

const NamedExports: ListTemplate<ts.NamedExports, ts.ExportSpecifier> = {
  match: plainTypes.NamedExports.match,
  flags: [] as FlagKind[],
  load: (built) => built.elements,
  build: (children) => ts.createNamedExports(children),
  childUnion: unions.ExportSpecifier,
};

const TypeLiteralNode: ListTemplate<ts.TypeLiteralNode, ts.TypeElement> = {
  match: plainTypes.TypeLiteralNode.match,
  flags: [] as FlagKind[],
  load: (built) => built.members,
  build: (children) => ts.createTypeLiteralNode(children),
  childUnion: unions.TypeElement,
};

const TupleTypeNode: ListTemplate<ts.TupleTypeNode, ts.TypeNode> = {
  match: plainTypes.TupleTypeNode.match,
  flags: [] as FlagKind[],
  load: (built) => built.elements,
  build: (children) => ts.createTupleTypeNode(children),
  childUnion: unions.TypeNode,
};

const UnionTypeNode: ListTemplate<ts.UnionTypeNode, ts.TypeNode> = {
  match: plainTypes.UnionTypeNode.match,
  flags: [] as FlagKind[],
  load: (built) => built.types,
  build: (children) => ts.createUnionTypeNode(children),
  childUnion: unions.TypeNode,
};

const IntersectionTypeNode: ListTemplate<ts.IntersectionTypeNode, ts.TypeNode> =
  {
    match: plainTypes.IntersectionTypeNode.match,
    flags: [] as FlagKind[],
    load: (built) => built.types,
    build: (children) => ts.createIntersectionTypeNode(children),
    childUnion: unions.TypeNode,
  };

const ModuleBlock: ListTemplate<ts.ModuleBlock, ts.Statement> = {
  match: plainTypes.ModuleBlock.match,
  flags: [] as FlagKind[],
  load: (built) => built.statements,
  build: (children) => ts.createModuleBlock(children),
  childUnion: unions.Statement,
};

const JsxAttributes: ListTemplate<ts.JsxAttributes, ts.JsxAttributeLike> = {
  match: plainTypes.JsxAttributes.match,
  flags: [] as FlagKind[],
  load: (built) => built.properties,
  build: (children) => ts.createJsxAttributes(children),
  childUnion: unions.JsxAttributeLike,
};

export const listTemplates = [
  ArrayLiteralExpression,
  ObjectLiteralExpression,
  VariableDeclarationList,
  Block,
  ArrayBindingPattern,
  ObjectBindingPattern,
  NamedImports,
  NamedExports,
  TypeLiteralNode,
  TupleTypeNode,
  UnionTypeNode,
  IntersectionTypeNode,
  ModuleBlock,
  JsxAttributes,
];

const VariableDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.BindingName>;
    type: OptionalStructSingleChild<ts.TypeNode>;
    initializer: OptionalStructSingleChild<ts.Expression>;
  },
  ts.VariableDeclaration
> = {
  match: plainTypes.VariableDeclaration.match,
  children: ["name", "type", "initializer"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.BindingName,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
    initializer: {
      value: e.initializer,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ name, type, initializer }) =>
    ts.createVariableDeclaration(name, type, initializer),
};

const EmptyStatement: StructTemplate<{}, ts.EmptyStatement> = {
  match: plainTypes.EmptyStatement.match,
  children: [],
  flags: [] as FlagKind[],

  load: (e) => ({}),
  build: ts.createEmptyStatement,
};

const ThrowStatement: StructTemplate<
  {
    expression: OptionalStructSingleChild<ts.Expression>;
  },
  ts.ThrowStatement
> = {
  match: plainTypes.ThrowStatement.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ expression }) => {
    if (!expression) {
      throw new Error("expression is required");
    }
    return ts.createThrow(expression);
  },
};

const VariableStatement: StructTemplate<
  {
    declarationList: RequiredStructSingleChild<ts.VariableDeclarationList>;
  },
  ts.VariableStatement
> = {
  match: plainTypes.VariableStatement.match,
  children: ["declarationList"],
  flags: ["export", "ambient"] as FlagKind[],

  load: (e) => ({
    declarationList: {
      value: e.declarationList,
      union: unions.VariableDeclarationList,
    },
  }),
  build: ({ declarationList }, modifiers) =>
    ts.createVariableStatement(modifiers, declarationList),
};

const FunctionDeclaration: StructTemplate<
  {
    asteriskToken: OptionalStructSingleChild<ts.AsteriskToken>;
    name: OptionalStructSingleChild<ts.Identifier>;
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: OptionalStructSingleChild<ts.TypeNode>;
    body: OptionalStructSingleChild<ts.FunctionBody>;
  },
  ts.FunctionDeclaration
> = {
  match: plainTypes.FunctionDeclaration.match,
  children: [
    "asteriskToken",
    "name",
    "typeParameters",
    "parameters",
    "type",
    "body",
  ],
  flags: ["export", "default", "ambient", "async"] as FlagKind[],
  keyword: ts.SyntaxKind.FunctionKeyword,
  load: (e) => ({
    asteriskToken: {
      value: e.asteriskToken,
      union: unions.AsteriskToken,
      optional: true,
    },
    name: {
      value: e.name,
      union: unions.Identifier,
      optional: true,
    },
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
    body: {
      value: e.body,
      union: unions.FunctionBody,
      optional: true,
    },
  }),
  build: (
    { asteriskToken, name, parameters, typeParameters, type, body },
    modifiers,
  ) =>
    ts.createFunctionDeclaration(
      undefined,
      modifiers,
      asteriskToken,
      name,
      typeParameters,
      parameters,
      type,
      body,
    ),
};

const FunctionExpression: StructTemplate<
  {
    asteriskToken: OptionalStructSingleChild<ts.AsteriskToken>;
    name: OptionalStructSingleChild<ts.Identifier>;
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: OptionalStructSingleChild<ts.TypeNode>;
    body: RequiredStructSingleChild<ts.FunctionBody>;
  },
  ts.FunctionExpression
> = {
  match: plainTypes.FunctionExpression.match,
  children: [
    "asteriskToken",
    "name",
    "typeParameters",
    "parameters",
    "type",
    "body",
  ],
  flags: ["async"] as FlagKind[],
  keyword: ts.SyntaxKind.FunctionKeyword,
  load: (e) => ({
    asteriskToken: {
      value: e.asteriskToken,
      union: unions.AsteriskToken,
      optional: true,
    },
    name: {
      value: e.name,
      union: unions.Identifier,
      optional: true,
    },
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
    body: {
      value: e.body,
      union: unions.FunctionBody,
    },
  }),
  build: (
    { asteriskToken, name, parameters, typeParameters, type, body },
    modifiers,
  ) =>
    ts.createFunctionExpression(
      modifiers,
      asteriskToken,
      name,
      typeParameters,
      parameters,
      type,
      body,
    ),
};

const ExpressionStatement: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.ExpressionStatement
> = {
  match: plainTypes.ExpressionStatement.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ expression }) => ts.createStatement(expression),
};

const ParameterDeclaration: StructTemplate<
  {
    dotDotDotToken: OptionalStructSingleChild<ts.DotDotDotToken>;
    name: RequiredStructSingleChild<ts.BindingName>;
    questionToken: OptionalStructSingleChild<ts.QuestionToken>;
    type: OptionalStructSingleChild<ts.TypeNode>;
    initializer: OptionalStructSingleChild<ts.Expression>;
  },
  ts.ParameterDeclaration
> = {
  match: plainTypes.ParameterDeclaration.match,
  children: ["dotDotDotToken", "name", "questionToken", "type", "initializer"],
  flags: ["accesibility", "readonly"] as FlagKind[],

  load: (e) => ({
    dotDotDotToken: {
      value: e.dotDotDotToken,
      union: unions.DotDotDotToken,
      optional: true,
    },
    name: {
      value: e.name,
      union: unions.BindingName,
    },
    questionToken: {
      value: e.questionToken,
      union: unions.QuestionToken,
      optional: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
    initializer: {
      value: e.initializer,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: (
    { dotDotDotToken, name, questionToken, type, initializer },
    modifiers,
  ) =>
    ts.createParameter(
      undefined,
      modifiers,
      dotDotDotToken,
      name,
      questionToken,
      type,
      initializer,
    ),
};

const BindingElement: StructTemplate<
  {
    propertyName: OptionalStructSingleChild<ts.PropertyName>;
    dotDotDotToken: OptionalStructSingleChild<ts.DotDotDotToken>;
    name: RequiredStructSingleChild<ts.BindingName>;
    initializer: OptionalStructSingleChild<ts.Expression>;
  },
  ts.BindingElement
> = {
  match: plainTypes.BindingElement.match,
  children: ["propertyName", "dotDotDotToken", "name", "initializer"],
  flags: [] as FlagKind[],

  load: (e) => ({
    propertyName: {
      value: e.propertyName,
      union: unions.PropertyName,
      optional: true,
    },
    dotDotDotToken: {
      value: e.dotDotDotToken,
      union: unions.DotDotDotToken,
      optional: true,
    },
    name: {
      value: e.name,
      union: unions.BindingName,
    },
    initializer: {
      value: e.initializer,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ propertyName, dotDotDotToken, name, initializer }) =>
    ts.createBindingElement(dotDotDotToken, propertyName, name, initializer),
};

const ComputedPropertyName: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.ComputedPropertyName
> = {
  match: plainTypes.ComputedPropertyName.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ expression }) => ts.createComputedPropertyName(expression),
};

const CallExpression: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.LeftHandSideExpression>;
    questionDotToken: OptionalStructSingleChild<ts.QuestionDotToken>;
    typeArguments: OptionalStructListChild<ts.TypeNode>;
    arguments: RequiredStructListChild<ts.Expression>;
  },
  ts.CallExpression
> = {
  match: plainTypes.CallExpression.match,
  children: ["expression", "questionDotToken", "typeArguments", "arguments"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.LeftHandSideExpression,
    },
    questionDotToken: {
      value: e.questionDotToken,
      union: unions.QuestionDotToken,
      optional: true,
    },
    typeArguments: {
      value: e.typeArguments,
      union: unions.TypeNode,
      optional: true,
      isList: true,
    },
    arguments: {
      value: e.arguments,
      union: unions.Expression,
      isList: true,
    },
  }),
  build: ({ expression, questionDotToken, typeArguments, arguments: args }) =>
    ts.createCallChain(expression, questionDotToken, typeArguments, args),
};

const PropertyAccessExpression: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.LeftHandSideExpression>;
    questionDotToken: OptionalStructSingleChild<ts.QuestionDotToken>;
    name: RequiredStructSingleChild<ts.MemberName>;
  },
  ts.PropertyAccessExpression
> = {
  match: plainTypes.PropertyAccessExpression.match,
  children: ["expression", "questionDotToken", "name"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.LeftHandSideExpression,
    },
    questionDotToken: {
      value: e.questionDotToken,
      union: unions.QuestionDotToken,
      optional: true,
    },
    name: {
      value: e.name,
      union: unions.MemberName,
    },
  }),
  build: ({ expression, questionDotToken, name }) =>
    ts.createPropertyAccessChain(expression, questionDotToken, name),
};

const ElementAccessExpression: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.LeftHandSideExpression>;
    questionDotToken: OptionalStructSingleChild<ts.QuestionDotToken>;
    argumentExpression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.ElementAccessExpression
> = {
  match: plainTypes.ElementAccessExpression.match,
  children: ["expression", "questionDotToken", "argumentExpression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.LeftHandSideExpression,
    },
    questionDotToken: {
      value: e.questionDotToken,
      union: unions.QuestionDotToken,
      optional: true,
    },
    argumentExpression: {
      value: e.argumentExpression,
      union: unions.Expression,
    },
  }),
  build: ({ expression, questionDotToken, argumentExpression }) =>
    ts.createElementAccessChain(
      expression,
      questionDotToken,
      argumentExpression,
    ),
};

const ImportDeclaration: StructTemplate<
  {
    importClause: OptionalStructSingleChild<ts.ImportClause>;
    moduleSpecifier: RequiredStructSingleChild<ts.Expression>;
  },
  ts.ImportDeclaration
> = {
  match: plainTypes.ImportDeclaration.match,
  children: ["importClause", "moduleSpecifier"],
  flags: [] as FlagKind[],

  load: (e) => ({
    importClause: {
      value: e.importClause,
      union: unions.ImportClause,
      optional: true,
    },
    moduleSpecifier: {
      value: e.moduleSpecifier,
      union: unions.Expression,
    },
  }),
  build: ({ importClause, moduleSpecifier }) =>
    ts.createImportDeclaration(
      undefined,
      undefined,
      importClause,
      moduleSpecifier,
    ),
};

const ImportClause: StructTemplate<
  {
    name: OptionalStructSingleChild<ts.Identifier>;
    namedBindings: OptionalStructSingleChild<ts.NamedImportBindings>;
  },
  ts.ImportClause
> = {
  match: plainTypes.ImportClause.match,
  children: ["name", "namedBindings"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
      optional: true,
    },
    namedBindings: {
      value: e.namedBindings,
      union: unions.NamedImportBindings,
      optional: true,
    },
  }),
  build: ({ name, namedBindings }) =>
    ts.createImportClause(name, namedBindings),
};

const NamespaceImport: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
  },
  ts.NamespaceImport
> = {
  match: plainTypes.NamespaceImport.match,
  children: ["name"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
  }),
  build: ({ name }) => ts.createNamespaceImport(name),
};

const NamespaceExport: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
  },
  ts.NamespaceExport
> = {
  match: plainTypes.NamespaceExport.match,
  children: ["name"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
  }),
  build: ({ name }) => ts.createNamespaceExport(name),
};

const ImportSpecifier: StructTemplate<
  {
    propertyName: OptionalStructSingleChild<ts.Identifier>;
    name: RequiredStructSingleChild<ts.Identifier>;
  },
  ts.ImportSpecifier
> = {
  match: plainTypes.ImportSpecifier.match,
  children: ["propertyName", "name"],
  flags: [] as FlagKind[],

  load: (e) => ({
    propertyName: {
      value: e.propertyName,
      union: unions.Identifier,
      optional: true,
    },
    name: {
      value: e.name,
      union: unions.Identifier,
    },
  }),
  build: ({ propertyName, name }) =>
    ts.createImportSpecifier(propertyName, name),
};

const ExportSpecifier: StructTemplate<
  {
    propertyName: OptionalStructSingleChild<ts.Identifier>;
    name: RequiredStructSingleChild<ts.Identifier>;
  },
  ts.ExportSpecifier
> = {
  match: plainTypes.ExportSpecifier.match,
  children: ["propertyName", "name"],
  flags: [] as FlagKind[],

  load: (e) => ({
    propertyName: {
      value: e.propertyName,
      union: unions.Identifier,
      optional: true,
    },
    name: {
      value: e.name,
      union: unions.Identifier,
    },
  }),
  build: ({ propertyName, name }) =>
    ts.createExportSpecifier(propertyName, name),
};

const ClassDeclaration: StructTemplate<
  {
    name: OptionalStructSingleChild<ts.Identifier>;
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    heritageClauses: OptionalStructListChild<ts.HeritageClause>;
    members: RequiredStructListChild<ts.ClassElement>;
  },
  ts.ClassDeclaration
> = {
  match: plainTypes.ClassDeclaration.match,
  children: ["name", "typeParameters", "heritageClauses", "members"],
  flags: ["export", "default", "ambient", "abstract"] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
      optional: true,
    },
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    heritageClauses: {
      value: e.heritageClauses,
      union: unions.HeritageClause,
      optional: true,
      isList: true,
    },
    members: {
      value: e.members,
      union: unions.ClassElement,
      isList: true,
    },
  }),
  build: ({ name, typeParameters, heritageClauses, members }, modifiers) =>
    ts.createClassDeclaration(
      undefined,
      modifiers,
      name,
      typeParameters,
      heritageClauses || [],
      members,
    ),
};

const HeritageClause: StructTemplate<
  {
    types: RequiredStructListChild<ts.ExpressionWithTypeArguments>;
  },
  ts.HeritageClause
> = {
  match: plainTypes.HeritageClause.match,
  children: ["types"],
  flags: [] as FlagKind[],

  load: (e) => ({
    types: {
      value: e.types,
      union: unions.ExpressionWithTypeArguments,
      isList: true,
    },
  }),
  build: ({ types }) =>
    ts.createHeritageClause(ts.SyntaxKind.ExtendsKeyword, types),
};

const ExpressionWithTypeArguments: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
    typeArguments: OptionalStructListChild<ts.TypeNode>;
  },
  ts.ExpressionWithTypeArguments
> = {
  match: plainTypes.ExpressionWithTypeArguments.match,
  children: ["expression", "typeArguments"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
    typeArguments: {
      value: e.typeArguments,
      union: unions.TypeNode,
      optional: true,
      isList: true,
    },
  }),
  build: ({ expression, typeArguments }) =>
    ts.createExpressionWithTypeArguments(typeArguments || [], expression),
};

const PropertyDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.PropertyName>;
    questionToken: OptionalStructSingleChild<ts.QuestionToken>;
    type: OptionalStructSingleChild<ts.TypeNode>;
    initializer: OptionalStructSingleChild<ts.Expression>;
  },
  ts.PropertyDeclaration
> = {
  match: plainTypes.PropertyDeclaration.match,
  children: ["name", "questionToken", "type", "initializer"],
  flags: [
    "accesibility",
    "static",
    "readonly",
    "async",
    "abstract",
  ] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.PropertyName,
    },
    questionToken: {
      value: e.questionToken,
      union: unions.QuestionToken,
      optional: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
    initializer: {
      value: e.initializer,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ name, questionToken, type, initializer }, modifiers) =>
    ts.createProperty(
      undefined,
      modifiers,
      name,
      questionToken,
      type,
      initializer,
    ),
};

const MethodDeclaration: StructTemplate<
  {
    asteriskToken: OptionalStructSingleChild<ts.AsteriskToken>;
    name: RequiredStructSingleChild<ts.PropertyName>;
    questionToken: OptionalStructSingleChild<ts.QuestionToken>;
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: OptionalStructSingleChild<ts.TypeNode>;
    body: OptionalStructSingleChild<ts.FunctionBody>;
  },
  ts.MethodDeclaration
> = {
  match: plainTypes.MethodDeclaration.match,
  children: [
    "asteriskToken",
    "name",
    "questionToken",
    "typeParameters",
    "parameters",
    "type",
    "body",
  ],
  flags: ["accesibility", "static", "async", "abstract"] as FlagKind[],

  load: (e) => ({
    asteriskToken: {
      value: e.asteriskToken,
      union: unions.AsteriskToken,
      optional: true,
    },
    name: {
      value: e.name,
      union: unions.PropertyName,
    },
    questionToken: {
      value: e.questionToken,
      union: unions.QuestionToken,
      optional: true,
    },
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
    body: {
      value: e.body,
      union: unions.FunctionBody,
      optional: true,
    },
  }),
  build: (
    {
      asteriskToken,
      name,
      questionToken,
      typeParameters,
      parameters,
      type,
      body,
    },
    modifiers,
  ) =>
    ts.createMethod(
      undefined,
      modifiers,
      asteriskToken,
      name,
      questionToken,
      typeParameters,
      parameters,
      type,
      body,
    ),
};

const ConstructorDeclaration: StructTemplate<
  {
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    body: OptionalStructSingleChild<ts.FunctionBody>;
  },
  ts.ConstructorDeclaration
> = {
  match: plainTypes.ConstructorDeclaration.match,
  children: ["parameters", "body"],
  flags: ["abstract"] as FlagKind[],

  load: (e) => ({
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    body: {
      value: e.body,
      union: unions.FunctionBody,
      optional: true,
    },
  }),
  build: ({ body, parameters }, modifiers) =>
    ts.createConstructor(undefined, modifiers, parameters, body),
};

const GetAccessorDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.PropertyName>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: OptionalStructSingleChild<ts.TypeNode>;
    body: OptionalStructSingleChild<ts.FunctionBody>;
  },
  ts.GetAccessorDeclaration
> = {
  match: plainTypes.GetAccessorDeclaration.match,
  children: ["name", "parameters", "type", "body"],
  flags: ["accesibility", "static", "abstract"] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.PropertyName,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
    body: {
      value: e.body,
      union: unions.FunctionBody,
      optional: true,
    },
  }),
  build: ({ name, parameters, type, body }, modifiers) =>
    ts.createGetAccessor(undefined, modifiers, name, parameters, type, body),
};

const SetAccessorDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.PropertyName>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    body: OptionalStructSingleChild<ts.FunctionBody>;
  },
  ts.SetAccessorDeclaration
> = {
  match: plainTypes.SetAccessorDeclaration.match,
  children: ["name", "parameters", "body"],
  flags: ["accesibility", "static", "abstract"] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.PropertyName,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    body: {
      value: e.body,
      union: unions.FunctionBody,
      optional: true,
    },
  }),
  build: ({ name, body, parameters }, modifiers) =>
    ts.createSetAccessor(undefined, modifiers, name, parameters, body),
};

const ArrowFunction: StructTemplate<
  {
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: OptionalStructSingleChild<ts.TypeNode>;
    body: RequiredStructSingleChild<ts.ConciseBody>;
  },
  ts.ArrowFunction
> = {
  match: plainTypes.ArrowFunction.match,
  children: ["typeParameters", "parameters", "type", "body"],
  flags: ["async"] as FlagKind[],

  load: (e) => ({
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
    body: {
      value: e.body,
      union: unions.ConciseBody,
    },
  }),
  build: ({ body, typeParameters, parameters, type }, modifiers) =>
    ts.createArrowFunction(
      modifiers,
      typeParameters,
      parameters,
      type,
      ts.createToken(ts.SyntaxKind.EqualsGreaterThanToken),
      body,
    ),
};

const PropertyAssignment: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.PropertyName>;
    initializer: RequiredStructSingleChild<ts.Expression>;
  },
  ts.PropertyAssignment
> = {
  match: plainTypes.PropertyAssignment.match,
  children: ["name", "initializer"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.PropertyName,
    },
    initializer: {
      value: e.initializer,
      union: unions.Expression,
    },
  }),
  build: ({ name, initializer }) =>
    ts.createPropertyAssignment(name, initializer),
};

const ReturnStatement: StructTemplate<
  {
    expression: OptionalStructSingleChild<ts.Expression>;
  },
  ts.ReturnStatement
> = {
  match: plainTypes.ReturnStatement.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ expression }) => ts.createReturn(expression),
};

const NewExpression: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.LeftHandSideExpression>;
    typeArguments: OptionalStructListChild<ts.TypeNode>;
    arguments: OptionalStructListChild<ts.Expression>;
  },
  ts.NewExpression
> = {
  match: plainTypes.NewExpression.match,
  children: ["expression", "typeArguments", "arguments"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.LeftHandSideExpression,
    },
    typeArguments: {
      value: e.typeArguments,
      union: unions.TypeNode,
      optional: true,
      isList: true,
    },
    arguments: {
      value: e.arguments,
      union: unions.Expression,
      optional: true,
      isList: true,
    },
  }),
  build: ({ expression, typeArguments, arguments: args }) =>
    ts.createNew(expression, typeArguments, args),
};

const IfStatement: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
    thenStatement: RequiredStructSingleChild<ts.Statement>;
    elseStatement: OptionalStructSingleChild<ts.Statement>;
  },
  ts.IfStatement
> = {
  match: plainTypes.IfStatement.match,
  children: ["expression", "thenStatement", "elseStatement"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
    thenStatement: {
      value: e.thenStatement,
      union: unions.Statement,
    },
    elseStatement: {
      value: e.elseStatement,
      union: unions.Statement,
      optional: true,
    },
  }),
  build: ({ expression, thenStatement, elseStatement }) =>
    ts.createIf(expression, thenStatement, elseStatement),
};

const ConditionalExpression: StructTemplate<
  {
    condition: RequiredStructSingleChild<ts.Expression>;
    whenTrue: RequiredStructSingleChild<ts.Expression>;
    whenFalse: RequiredStructSingleChild<ts.Expression>;
  },
  ts.ConditionalExpression
> = {
  match: plainTypes.ConditionalExpression.match,
  children: ["condition", "whenTrue", "whenFalse"],
  flags: [] as FlagKind[],

  load: (e) => ({
    condition: {
      value: e.condition,
      union: unions.Expression,
    },
    whenTrue: {
      value: e.whenTrue,
      union: unions.Expression,
    },
    whenFalse: {
      value: e.whenFalse,
      union: unions.Expression,
    },
  }),
  build: ({ condition, whenTrue, whenFalse }) =>
    ts.createConditional(condition, whenTrue, whenFalse),
};

const BinaryExpression: StructTemplate<
  {
    left: RequiredStructSingleChild<ts.Expression>;
    operatorToken: RequiredStructSingleChild<ts.BinaryOperatorToken>;
    right: RequiredStructSingleChild<ts.Expression>;
  },
  ts.BinaryExpression
> = {
  match: plainTypes.BinaryExpression.match,
  children: ["left", "operatorToken", "right"],
  flags: [] as FlagKind[],

  load: (e) => ({
    left: {
      value: e.left,
      union: unions.Expression,
    },
    operatorToken: {
      value: e.operatorToken,
      union: unions.BinaryOperatorToken,
    },
    right: {
      value: e.right,
      union: unions.Expression,
    },
  }),
  build: ({ left, operatorToken, right }) =>
    ts.createBinary(left, operatorToken, right),
};

const PrefixUnaryExpression: StructTemplate<
  {
    operator: RequiredStructSingleChild<ts.Token<ts.PrefixUnaryOperator>>;
    operand: RequiredStructSingleChild<ts.UnaryExpression>;
  },
  ts.PrefixUnaryExpression
> = {
  match: plainTypes.PrefixUnaryExpression.match,
  children: ["operator", "operand"],
  flags: [] as FlagKind[],

  load: (e) => ({
    operator: {
      value: ts.createToken(e.operator),
      union: unions.PrefixUnaryOperator,
    },
    operand: {
      value: e.operand,
      union: unions.UnaryExpression,
    },
  }),
  build: ({ operator, operand }) => ts.createPrefix(operator.kind, operand),
};

const PostfixUnaryExpression: StructTemplate<
  {
    operand: RequiredStructSingleChild<ts.LeftHandSideExpression>;
    operator: RequiredStructSingleChild<ts.Token<ts.PostfixUnaryOperator>>;
  },
  ts.PostfixUnaryExpression
> = {
  match: plainTypes.PostfixUnaryExpression.match,
  children: ["operand", "operator"],
  flags: [] as FlagKind[],

  load: (e) => ({
    operand: {
      value: e.operand,
      union: unions.LeftHandSideExpression,
    },
    operator: {
      value: ts.createToken(e.operator),
      union: unions.PostfixUnaryOperator,
    },
  }),
  build: ({ operand, operator }) => ts.createPostfix(operand, operator.kind),
};

const TemplateExpression: StructTemplate<
  {
    head: RequiredStructSingleChild<ts.TemplateHead>;
    templateSpans: RequiredStructListChild<ts.TemplateSpan>;
  },
  ts.TemplateExpression
> = {
  match: plainTypes.TemplateExpression.match,
  children: ["head", "templateSpans"],
  flags: [] as FlagKind[],

  load: (e) => ({
    head: {
      value: e.head,
      union: unions.TemplateHead,
    },
    templateSpans: {
      value: e.templateSpans,
      union: unions.TemplateSpan,
      isList: true,
    },
  }),
  build: ({ head, templateSpans }) =>
    ts.createTemplateExpression(head, templateSpans),
};

const TemplateSpan: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
    literal: RequiredStructSingleChild<ts.TemplateMiddle | ts.TemplateTail>;
  },
  ts.TemplateSpan
> = {
  match: plainTypes.TemplateSpan.match,
  children: ["expression", "literal"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
    literal: {
      value: e.literal,
      union: unions.TemplateSpanLiteral,
    },
  }),
  build: ({ expression, literal }) =>
    ts.createTemplateSpan(expression, literal),
};

const ParenthesizedExpression: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.ParenthesizedExpression
> = {
  match: plainTypes.ParenthesizedExpression.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ expression }) => ts.createParen(expression),
};

const AsExpression: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
    type: RequiredStructSingleChild<ts.TypeNode>;
  },
  ts.AsExpression
> = {
  match: plainTypes.AsExpression.match,
  children: ["expression", "type"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
    },
  }),
  build: ({ expression, type }) => ts.createAsExpression(expression, type),
};

const TypeParameterDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
    constraint: OptionalStructSingleChild<ts.TypeNode>;
    default: OptionalStructSingleChild<ts.TypeNode>;
  },
  ts.TypeParameterDeclaration
> = {
  match: plainTypes.TypeParameterDeclaration.match,
  children: ["name", "constraint", "default"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
    constraint: {
      value: e.constraint,
      union: unions.TypeNode,
      optional: true,
    },
    default: {
      value: e.default,
      union: unions.TypeNode,
      optional: true,
    },
  }),
  build: ({ name, constraint, default: _default }) =>
    ts.createTypeParameterDeclaration(name, constraint, _default),
};

const FunctionTypeNode: StructTemplate<
  {
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: RequiredStructSingleChild<ts.TypeNode>;
  },
  ts.FunctionTypeNode
> = {
  match: plainTypes.FunctionTypeNode.match,
  children: ["typeParameters", "parameters", "type"],
  flags: [] as FlagKind[],

  load: (e) => ({
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
    },
  }),
  build: ({ typeParameters, parameters, type }) =>
    ts.createFunctionTypeNode(
      typeParameters && [...typeParameters],
      [...parameters],
      type,
    ),
};

const ConstructorTypeNode: StructTemplate<
  {
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: RequiredStructSingleChild<ts.TypeNode>;
  },
  ts.ConstructorTypeNode
> = {
  match: plainTypes.ConstructorTypeNode.match,
  children: ["typeParameters", "parameters", "type"],
  flags: [] as FlagKind[],

  load: (e) => ({
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
    },
  }),
  build: ({ typeParameters, parameters, type }) =>
    ts.createConstructorTypeNode(
      typeParameters && [...typeParameters],
      [...parameters],
      type,
    ),
};

const QualifiedName: StructTemplate<
  {
    left: RequiredStructSingleChild<ts.EntityName>;
    right: RequiredStructSingleChild<ts.Identifier>;
  },
  ts.QualifiedName
> = {
  match: plainTypes.QualifiedName.match,
  children: ["left", "right"],
  flags: [] as FlagKind[],

  load: (e) => ({
    left: {
      value: e.left,
      union: unions.EntityName,
    },
    right: {
      value: e.right,
      union: unions.Identifier,
    },
  }),
  build: ({ left, right }) => ts.createQualifiedName(left, right),
};

const TypeReferenceNode: StructTemplate<
  {
    typeName: RequiredStructSingleChild<ts.EntityName>;
    typeArguments: OptionalStructListChild<ts.TypeNode>;
  },
  ts.TypeReferenceNode
> = {
  match: plainTypes.TypeReferenceNode.match,
  children: ["typeName", "typeArguments"],
  flags: [] as FlagKind[],

  load: (e) => ({
    typeName: {
      value: e.typeName,
      union: unions.EntityName,
    },
    typeArguments: {
      value: e.typeArguments,
      union: unions.TypeNode,
      optional: true,
      isList: true,
    },
  }),
  build: ({ typeName, typeArguments }) =>
    ts.createTypeReferenceNode(typeName, typeArguments),
};

const TypePredicateNode: StructTemplate<
  {
    parameterName: RequiredStructSingleChild<ts.Identifier | ts.ThisTypeNode>;
    type: OptionalStructSingleChild<ts.TypeNode>;
  },
  ts.TypePredicateNode
> = {
  match: plainTypes.TypePredicateNode.match,
  children: ["parameterName", "type"],
  flags: [] as FlagKind[],

  load: (e) => ({
    parameterName: {
      value: e.parameterName,
      union: unions.TypePredicateNodeParameterName,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
  }),
  build: ({ parameterName, type }) => {
    if (!type) {
      throw new Error("type is required");
    }
    return ts.createTypePredicateNode(parameterName, type);
  },
};

const TypeQueryNode: StructTemplate<
  {
    exprName: RequiredStructSingleChild<ts.EntityName>;
  },
  ts.TypeQueryNode
> = {
  match: plainTypes.TypeQueryNode.match,
  children: ["exprName"],
  flags: [] as FlagKind[],

  load: (e) => ({
    exprName: {
      value: e.exprName,
      union: unions.EntityName,
    },
  }),
  build: ({ exprName }) => ts.createTypeQueryNode(exprName),
};

const CallSignatureDeclaration: StructTemplate<
  {
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: OptionalStructSingleChild<ts.TypeNode>;
  },
  ts.CallSignatureDeclaration
> = {
  match: plainTypes.CallSignatureDeclaration.match,
  children: ["typeParameters", "parameters", "type"],
  flags: [] as FlagKind[],

  load: (e) => ({
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
  }),
  build: ({ typeParameters, parameters, type }) =>
    ts.createCallSignature(
      typeParameters && [...typeParameters],
      [...parameters],
      type,
    ),
};

const ConstructSignatureDeclaration: StructTemplate<
  {
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: OptionalStructSingleChild<ts.TypeNode>;
  },
  ts.ConstructSignatureDeclaration
> = {
  match: plainTypes.ConstructSignatureDeclaration.match,
  children: ["typeParameters", "parameters", "type"],
  flags: [] as FlagKind[],

  load: (e) => ({
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
  }),
  build: ({ typeParameters, parameters, type }) =>
    ts.createConstructSignature(
      typeParameters && [...typeParameters],
      [...parameters],
      type,
    ),
};

const PropertySignature: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.PropertyName>;
    questionToken: OptionalStructSingleChild<ts.QuestionToken>;
    type: OptionalStructSingleChild<ts.TypeNode>;
    initializer: OptionalStructSingleChild<ts.Expression>;
  },
  ts.PropertySignature
> = {
  match: plainTypes.PropertySignature.match,
  children: ["name", "questionToken", "type", "initializer"],
  flags: ["readonly"] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.PropertyName,
    },
    questionToken: {
      value: e.questionToken,
      union: unions.QuestionToken,
      optional: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
    initializer: {
      value: e.initializer,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ name, questionToken, type, initializer }, modifiers) =>
    ts.createPropertySignature(
      modifiers,
      name,
      questionToken,
      type,
      initializer,
    ),
};

const MethodSignature: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.PropertyName>;
    questionToken: OptionalStructSingleChild<ts.QuestionToken>;
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: OptionalStructSingleChild<ts.TypeNode>;
  },
  ts.MethodSignature
> = {
  match: plainTypes.MethodSignature.match,
  children: ["name", "questionToken", "typeParameters", "parameters", "type"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.PropertyName,
    },
    questionToken: {
      value: e.questionToken,
      union: unions.QuestionToken,
      optional: true,
    },
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
  }),
  build: ({ name, questionToken, typeParameters, parameters, type }) =>
    ts.createMethodSignature(
      typeParameters,
      parameters,
      type,
      name,
      questionToken,
    ),
};

const IndexSignatureDeclaration: StructTemplate<
  {
    parameters: RequiredStructListChild<ts.ParameterDeclaration>;
    type: OptionalStructSingleChild<ts.TypeNode>;
  },
  ts.IndexSignatureDeclaration
> = {
  match: plainTypes.IndexSignatureDeclaration.match,
  children: ["parameters", "type"],
  flags: ["readonly"] as FlagKind[],

  load: (e) => ({
    parameters: {
      value: e.parameters,
      union: unions.ParameterDeclaration,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
  }),
  build: ({ parameters, type }, modifiers) => {
    if (!type) {
      throw new Error("type is required");
    }
    return ts.createIndexSignature(undefined, modifiers, parameters, type);
  },
};

const ArrayTypeNode: StructTemplate<
  {
    elementType: RequiredStructSingleChild<ts.TypeNode>;
  },
  ts.ArrayTypeNode
> = {
  match: plainTypes.ArrayTypeNode.match,
  children: ["elementType"],
  flags: [] as FlagKind[],

  load: (e) => ({
    elementType: {
      value: e.elementType,
      union: unions.TypeNode,
    },
  }),
  build: ({ elementType }) => ts.createArrayTypeNode(elementType),
};

const ParenthesizedTypeNode: StructTemplate<
  {
    type: RequiredStructSingleChild<ts.TypeNode>;
  },
  ts.ParenthesizedTypeNode
> = {
  match: plainTypes.ParenthesizedTypeNode.match,
  children: ["type"],
  flags: [] as FlagKind[],

  load: (e) => ({
    type: {
      value: e.type,
      union: unions.TypeNode,
    },
  }),
  build: ({ type }) => ts.createParenthesizedType(type),
};

const TypeOperatorNode: StructTemplate<
  {
    type: RequiredStructSingleChild<ts.TypeNode>;
  },
  ts.TypeOperatorNode
> = {
  match: plainTypes.TypeOperatorNode.match,
  children: ["type"],
  flags: [] as FlagKind[],

  load: (e) => ({
    type: {
      value: e.type,
      union: unions.TypeNode,
    },
  }),
  build: ({ type }) => ts.createTypeOperatorNode(type),
};

const IndexedAccessTypeNode: StructTemplate<
  {
    objectType: RequiredStructSingleChild<ts.TypeNode>;
    indexType: RequiredStructSingleChild<ts.TypeNode>;
  },
  ts.IndexedAccessTypeNode
> = {
  match: plainTypes.IndexedAccessTypeNode.match,
  children: ["objectType", "indexType"],
  flags: [] as FlagKind[],

  load: (e) => ({
    objectType: {
      value: e.objectType,
      union: unions.TypeNode,
    },
    indexType: {
      value: e.indexType,
      union: unions.TypeNode,
    },
  }),
  build: ({ objectType, indexType }) =>
    ts.createIndexedAccessTypeNode(objectType, indexType),
};

const MappedTypeNode: StructTemplate<
  {
    readonlyToken: OptionalStructSingleChild<
      ts.ReadonlyToken | ts.PlusToken | ts.MinusToken
    >;
    typeParameter: RequiredStructSingleChild<ts.TypeParameterDeclaration>;
    nameType: OptionalStructSingleChild<ts.TypeNode>;
    questionToken: OptionalStructSingleChild<
      ts.QuestionToken | ts.PlusToken | ts.MinusToken
    >;
    type: OptionalStructSingleChild<ts.TypeNode>;
  },
  ts.MappedTypeNode
> = {
  match: plainTypes.MappedTypeNode.match,
  children: [
    "readonlyToken",
    "typeParameter",
    "nameType",
    "questionToken",
    "type",
  ],
  flags: [] as FlagKind[],

  load: (e) => ({
    readonlyToken: {
      value: e.readonlyToken,
      union: unions.ReadonlyMappedToken,
      optional: true,
    },
    typeParameter: {
      value: e.typeParameter,
      union: unions.TypeParameterDeclaration,
    },
    nameType: {
      value: e.nameType,
      union: unions.TypeNode,
      optional: true,
    },
    questionToken: {
      value: e.questionToken,
      union: unions.QuestionMappedToken,
      optional: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
      optional: true,
    },
  }),
  build: ({ readonlyToken, typeParameter, nameType, questionToken, type }) =>
    ts.createMappedTypeNode(
      readonlyToken,
      typeParameter,
      nameType,
      questionToken,
      type,
    ),
};

const LiteralTypeNode: StructTemplate<
  {
    literal: RequiredStructSingleChild<
      | ts.NullLiteral
      | ts.BooleanLiteral
      | ts.LiteralExpression
      | ts.PrefixUnaryExpression
    >;
  },
  ts.LiteralTypeNode
> = {
  match: plainTypes.LiteralTypeNode.match,
  children: ["literal"],
  flags: [] as FlagKind[],

  load: (e) => ({
    literal: {
      value: e.literal,
      union: unions.LiteralTypeNodeLiteral,
    },
  }),
  build: ({ literal }) => ts.createLiteralTypeNode(literal),
};

const InterfaceDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    heritageClauses: OptionalStructListChild<ts.HeritageClause>;
    members: RequiredStructListChild<ts.TypeElement>;
  },
  ts.InterfaceDeclaration
> = {
  match: plainTypes.InterfaceDeclaration.match,
  children: ["name", "typeParameters", "heritageClauses", "members"],
  flags: ["export", "default", "ambient"] as FlagKind[],
  keyword: ts.SyntaxKind.InterfaceKeyword,
  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    heritageClauses: {
      value: e.heritageClauses,
      union: unions.HeritageClause,
      optional: true,
      isList: true,
    },
    members: {
      value: e.members,
      union: unions.TypeElement,
      isList: true,
    },
  }),
  build: ({ name, typeParameters, heritageClauses, members }, modifiers) =>
    ts.createInterfaceDeclaration(
      undefined,
      modifiers,
      name,
      typeParameters,
      heritageClauses,
      members,
    ),
};

const TypeAliasDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
    typeParameters: OptionalStructListChild<ts.TypeParameterDeclaration>;
    type: RequiredStructSingleChild<ts.TypeNode>;
  },
  ts.TypeAliasDeclaration
> = {
  match: plainTypes.TypeAliasDeclaration.match,
  children: ["name", "typeParameters", "type"],
  flags: ["export", "ambient"] as FlagKind[],
  keyword: ts.SyntaxKind.TypeKeyword,
  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
    typeParameters: {
      value: e.typeParameters,
      union: unions.TypeParameterDeclaration,
      optional: true,
      isList: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
    },
  }),
  build: ({ name, typeParameters, type }, modifiers) =>
    ts.createTypeAliasDeclaration(
      undefined,
      modifiers,
      name,
      typeParameters,
      type,
    ),
};

const EnumDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
    members: RequiredStructListChild<ts.EnumMember>;
  },
  ts.EnumDeclaration
> = {
  match: plainTypes.EnumDeclaration.match,
  children: ["name", "members"],
  flags: ["export", "ambient"] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
    members: {
      value: e.members,
      union: unions.EnumMember,
      isList: true,
    },
  }),
  build: ({ name, members }, modifiers) =>
    ts.createEnumDeclaration(undefined, modifiers, name, members),
};

const EnumMember: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.PropertyName>;
    initializer: OptionalStructSingleChild<ts.Expression>;
  },
  ts.EnumMember
> = {
  match: plainTypes.EnumMember.match,
  children: ["name", "initializer"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.PropertyName,
    },
    initializer: {
      value: e.initializer,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ name, initializer }) => ts.createEnumMember(name, initializer),
};

const ModuleDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.ModuleName>;
    body: OptionalStructSingleChild<
      ts.ModuleBody | ts.JSDocNamespaceDeclaration
    >;
  },
  ts.ModuleDeclaration
> = {
  match: plainTypes.ModuleDeclaration.match,
  children: ["name", "body"],
  flags: ["export", "ambient"] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.ModuleName,
    },
    body: {
      value: e.body,
      union: unions.ModuleBody,
      optional: true,
    },
  }),
  build: ({ name, body }, modifiers) =>
    ts.createModuleDeclaration(undefined, modifiers, name, body, undefined),
};

const ImportEqualsDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
    moduleReference: RequiredStructSingleChild<ts.ModuleReference>;
  },
  ts.ImportEqualsDeclaration
> = {
  match: plainTypes.ImportEqualsDeclaration.match,
  children: ["name", "moduleReference"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
    moduleReference: {
      value: e.moduleReference,
      union: unions.ModuleReference,
    },
  }),
  build: ({ name, moduleReference }) =>
    ts.createImportEqualsDeclaration(
      undefined,
      undefined,
      false,
      name,
      moduleReference,
    ),
};

const ExternalModuleReference: StructTemplate<
  {
    expression: OptionalStructSingleChild<ts.Expression>;
  },
  ts.ExternalModuleReference
> = {
  match: plainTypes.ExternalModuleReference.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ expression }) => {
    if (!expression) {
      throw new Error("expression required");
    }
    return ts.createExternalModuleReference(expression);
  },
};

const NamespaceExportDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
  },
  ts.NamespaceExportDeclaration
> = {
  match: plainTypes.NamespaceExportDeclaration.match,
  children: ["name"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
  }),
  build: ({ name }) => ts.createNamespaceExportDeclaration(name),
};

const NamespaceDeclaration: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
    body: RequiredStructSingleChild<ts.NamespaceBody>;
  },
  ts.NamespaceDeclaration
> = {
  match: plainTypes.NamespaceDeclaration.match,
  children: ["name", "body"],
  flags: ["export", "ambient"] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
    body: {
      value: e.body,
      union: unions.NamespaceBody,
    },
  }),
  build: ({ name, body }, modifiers) =>
    ts.createModuleDeclaration(
      undefined,
      modifiers,
      name,
      body,
      undefined,
    ) as ts.NamespaceDeclaration,
};

const ExportDeclaration: StructTemplate<
  {
    exportClause: OptionalStructSingleChild<ts.NamedExportBindings>;
    moduleSpecifier: OptionalStructSingleChild<ts.Expression>;
  },
  ts.ExportDeclaration
> = {
  match: plainTypes.ExportDeclaration.match,
  children: ["exportClause", "moduleSpecifier"],
  flags: ["default"] as FlagKind[],

  load: (e) => ({
    exportClause: {
      value: e.exportClause,
      union: unions.NamedExportBindings,
      optional: true,
    },
    moduleSpecifier: {
      value: e.moduleSpecifier,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ exportClause, moduleSpecifier }, modifiers) =>
    ts.createExportDeclaration(
      undefined,
      modifiers,
      exportClause,
      moduleSpecifier,
    ),
};

const ExportAssignment: StructTemplate<
  {
    isExportEquals: OptionalStructSingleChild<ts.EqualsToken>;
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.ExportAssignment
> = {
  match: plainTypes.ExportAssignment.match,
  children: ["isExportEquals", "expression"],
  flags: ["default"] as FlagKind[],

  load: (e) => ({
    isExportEquals: {
      value: e.isExportEquals
        ? ts.createToken(ts.SyntaxKind.EqualsToken)
        : undefined,
      union: unions.EqualsToken,
      optional: true,
    },
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ isExportEquals, expression }, modifiers) =>
    ts.createExportAssignment(
      undefined,
      modifiers,
      !!isExportEquals,
      expression,
    ),
};

const ShorthandPropertyAssignment: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
    objectAssignmentInitializer: OptionalStructSingleChild<ts.Expression>;
  },
  ts.ShorthandPropertyAssignment
> = {
  match: plainTypes.ShorthandPropertyAssignment.match,
  children: ["name", "objectAssignmentInitializer"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
    objectAssignmentInitializer: {
      value: e.objectAssignmentInitializer,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ name, objectAssignmentInitializer }) =>
    ts.createShorthandPropertyAssignment(name, objectAssignmentInitializer),
};

const SpreadAssignment: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.SpreadAssignment
> = {
  match: plainTypes.SpreadAssignment.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ expression }) => ts.createSpreadAssignment(expression),
};

const JsxElement: StructTemplate<
  {
    openingElement: RequiredStructSingleChild<ts.JsxOpeningElement>;
    children: RequiredStructListChild<ts.JsxChild>;
    closingElement: RequiredStructSingleChild<ts.JsxClosingElement>;
  },
  ts.JsxElement
> = {
  match: plainTypes.JsxElement.match,
  children: ["openingElement", "children", "closingElement"],
  flags: [] as FlagKind[],

  load: (e) => ({
    openingElement: {
      value: e.openingElement,
      union: unions.JsxOpeningElement,
    },
    children: {
      value: e.children,
      union: unions.JsxChild,
      isList: true,
    },
    closingElement: {
      value: e.closingElement,
      union: unions.JsxClosingElement,
    },
  }),
  build: ({ openingElement, children, closingElement }) =>
    ts.createJsxElement(openingElement, children, closingElement),
};

const JsxFragment: StructTemplate<
  {
    children: RequiredStructListChild<ts.JsxChild>;
  },
  ts.JsxFragment
> = {
  match: plainTypes.JsxFragment.match,
  children: ["children"],
  flags: [] as FlagKind[],

  load: (e) => ({
    children: {
      value: e.children,
      union: unions.JsxChild,
      isList: true,
    },
  }),
  build: ({ children }) =>
    ts.createJsxFragment(
      ts.createJsxOpeningFragment(),
      children,
      ts.createJsxJsxClosingFragment(),
    ),
};

const JsxSelfClosingElement: StructTemplate<
  {
    tagName: RequiredStructSingleChild<ts.JsxTagNameExpression>;
    typeArguments: OptionalStructListChild<ts.TypeNode>;
    attributes: RequiredStructSingleChild<ts.JsxAttributes>;
  },
  ts.JsxSelfClosingElement
> = {
  match: plainTypes.JsxSelfClosingElement.match,
  children: ["tagName", "typeArguments", "attributes"],
  flags: [] as FlagKind[],

  load: (e) => ({
    tagName: {
      value: e.tagName,
      union: unions.JsxTagNameExpression,
    },
    typeArguments: {
      value: e.typeArguments,
      union: unions.TypeNode,
      optional: true,
      isList: true,
    },
    attributes: {
      value: e.attributes,
      union: unions.JsxAttributes,
    },
  }),
  build: ({ tagName, typeArguments, attributes }) =>
    ts.createJsxSelfClosingElement(tagName, typeArguments, attributes),
};

const JsxOpeningElement: StructTemplate<
  {
    tagName: RequiredStructSingleChild<ts.JsxTagNameExpression>;
    typeArguments: OptionalStructListChild<ts.TypeNode>;
    attributes: RequiredStructSingleChild<ts.JsxAttributes>;
  },
  ts.JsxOpeningElement
> = {
  match: plainTypes.JsxOpeningElement.match,
  children: ["tagName", "typeArguments", "attributes"],
  flags: [] as FlagKind[],

  load: (e) => ({
    tagName: {
      value: e.tagName,
      union: unions.JsxTagNameExpression,
    },
    typeArguments: {
      value: e.typeArguments,
      union: unions.TypeNode,
      optional: true,
      isList: true,
    },
    attributes: {
      value: e.attributes,
      union: unions.JsxAttributes,
    },
  }),
  build: ({ tagName, typeArguments, attributes }) =>
    ts.createJsxOpeningElement(tagName, typeArguments, attributes),
};

const JsxClosingElement: StructTemplate<
  {
    tagName: RequiredStructSingleChild<ts.JsxTagNameExpression>;
  },
  ts.JsxClosingElement
> = {
  match: plainTypes.JsxClosingElement.match,
  children: ["tagName"],
  flags: [] as FlagKind[],

  load: (e) => ({
    tagName: {
      value: e.tagName,
      union: unions.JsxTagNameExpression,
    },
  }),
  build: ({ tagName }) => ts.createJsxClosingElement(tagName),
};

const JsxAttribute: StructTemplate<
  {
    name: RequiredStructSingleChild<ts.Identifier>;
    initializer: OptionalStructSingleChild<ts.StringLiteral | ts.JsxExpression>;
  },
  ts.JsxAttribute
> = {
  match: plainTypes.JsxAttribute.match,
  children: ["name", "initializer"],
  flags: [] as FlagKind[],

  load: (e) => ({
    name: {
      value: e.name,
      union: unions.Identifier,
    },
    initializer: {
      value: e.initializer,
      union: unions.JsxAttributeInitializer,
      optional: true,
    },
  }),
  build: ({ name, initializer }) => {
    if (!initializer) {
      throw new Error("initializer is required");
    }
    return ts.createJsxAttribute(name, initializer);
  },
};

const JsxSpreadAttribute: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.JsxSpreadAttribute
> = {
  match: plainTypes.JsxSpreadAttribute.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ expression }) => ts.createJsxSpreadAttribute(expression),
};

const JsxExpression: StructTemplate<
  {
    dotDotDotToken: OptionalStructSingleChild<ts.DotDotDotToken>;
    expression: OptionalStructSingleChild<ts.Expression>;
  },
  ts.JsxExpression
> = {
  match: plainTypes.JsxExpression.match,
  children: ["dotDotDotToken", "expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    dotDotDotToken: {
      value: e.dotDotDotToken,
      union: unions.DotDotDotToken,
      optional: true,
    },
    expression: {
      value: e.expression,
      union: unions.Expression,
      optional: true,
    },
  }),
  build: ({ dotDotDotToken, expression }) =>
    ts.createJsxExpression(dotDotDotToken, expression),
};

const JsxTagNamePropertyAccess: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.JsxTagNameExpression>;
    name: RequiredStructSingleChild<ts.MemberName>;
  },
  ts.JsxTagNamePropertyAccess
> = {
  match: plainTypes.JsxTagNamePropertyAccess.match,
  children: ["expression", "name"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.JsxTagNameExpression,
    },
    name: {
      value: e.name,
      union: unions.MemberName,
    },
  }),
  build: ({ expression, name }) =>
    ts.createPropertyAccess(expression, name) as ts.JsxTagNamePropertyAccess,
};

const TypeOfExpression: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.TypeOfExpression
> = {
  match: plainTypes.TypeOfExpression.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ expression }) => ts.createTypeOf(expression),
};

const AwaitExpression: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.AwaitExpression
> = {
  match: plainTypes.AwaitExpression.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ expression }) => ts.createAwait(expression),
};

const NonNullExpression: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.NonNullExpression
> = {
  match: plainTypes.NonNullExpression.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ expression }) => ts.createNonNullExpression(expression),
};

const DoStatement: StructTemplate<
  {
    statement: RequiredStructSingleChild<ts.Statement>;
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.DoStatement
> = {
  match: plainTypes.DoStatement.match,
  children: ["statement", "expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    statement: {
      value: e.statement,
      union: unions.Statement,
    },
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ statement, expression }) => ts.createDo(statement, expression),
};

const WhileStatement: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
    statement: RequiredStructSingleChild<ts.Statement>;
  },
  ts.WhileStatement
> = {
  match: plainTypes.WhileStatement.match,
  children: ["expression", "statement"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
    statement: {
      value: e.statement,
      union: unions.Statement,
    },
  }),
  build: ({ expression, statement }) => ts.createWhile(expression, statement),
};

const ForStatement: StructTemplate<
  {
    initializer: OptionalStructSingleChild<ts.ForInitializer>;
    condition: OptionalStructSingleChild<ts.Expression>;
    incrementor: OptionalStructSingleChild<ts.Expression>;
    statement: RequiredStructSingleChild<ts.Statement>;
  },
  ts.ForStatement
> = {
  match: plainTypes.ForStatement.match,
  children: ["initializer", "condition", "incrementor", "statement"],
  flags: [] as FlagKind[],

  load: (e) => ({
    initializer: {
      value: e.initializer,
      union: unions.ForInitializer,
      optional: true,
    },
    condition: {
      value: e.condition,
      union: unions.Expression,
      optional: true,
    },
    incrementor: {
      value: e.incrementor,
      union: unions.Expression,
      optional: true,
    },
    statement: {
      value: e.statement,
      union: unions.Statement,
    },
  }),
  build: ({ initializer, condition, incrementor, statement }) =>
    ts.createFor(initializer, condition, incrementor, statement),
};

const ForInStatement: StructTemplate<
  {
    initializer: RequiredStructSingleChild<ts.ForInitializer>;
    expression: RequiredStructSingleChild<ts.Expression>;
    statement: RequiredStructSingleChild<ts.Statement>;
  },
  ts.ForInStatement
> = {
  match: plainTypes.ForInStatement.match,
  children: ["initializer", "expression", "statement"],
  flags: [] as FlagKind[],

  load: (e) => ({
    initializer: {
      value: e.initializer,
      union: unions.ForInitializer,
    },
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
    statement: {
      value: e.statement,
      union: unions.Statement,
    },
  }),
  build: ({ initializer, expression, statement }) =>
    ts.createForIn(initializer, expression, statement),
};

const ForOfStatement: StructTemplate<
  {
    awaitModifier: OptionalStructSingleChild<ts.AwaitKeywordToken>;
    initializer: RequiredStructSingleChild<ts.ForInitializer>;
    expression: RequiredStructSingleChild<ts.Expression>;
    statement: RequiredStructSingleChild<ts.Statement>;
  },
  ts.ForOfStatement
> = {
  match: plainTypes.ForOfStatement.match,
  children: ["awaitModifier", "initializer", "expression", "statement"],
  flags: [] as FlagKind[],
  keyword: ts.SyntaxKind.ForKeyword,
  load: (e) => ({
    awaitModifier: {
      value: e.awaitModifier,
      union: unions.AwaitKeywordToken,
      optional: true,
    },
    initializer: {
      value: e.initializer,
      union: unions.ForInitializer,
    },
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
    statement: {
      value: e.statement,
      union: unions.Statement,
    },
  }),
  build: ({ awaitModifier, initializer, expression, statement }) =>
    ts.createForOf(awaitModifier!, initializer, expression, statement),
};

const SpreadElement: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
  },
  ts.SpreadElement
> = {
  match: plainTypes.SpreadElement.match,
  children: ["expression"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
  }),
  build: ({ expression }) => ts.createSpread(expression),
};

const TryStatement: StructTemplate<
  {
    tryBlock: RequiredStructSingleChild<ts.Block>;
    catchClause: OptionalStructSingleChild<ts.CatchClause>;
    finallyBlock: OptionalStructSingleChild<ts.Block>;
  },
  ts.TryStatement
> = {
  match: plainTypes.TryStatement.match,
  children: ["tryBlock", "catchClause", "finallyBlock"],
  flags: [] as FlagKind[],

  load: (e) => ({
    tryBlock: {
      value: e.tryBlock,
      union: unions.Block,
    },
    catchClause: {
      value: e.catchClause,
      union: unions.CatchClause,
      optional: true,
    },
    finallyBlock: {
      value: e.finallyBlock,
      union: unions.Block,
      optional: true,
    },
  }),
  build: ({ tryBlock, catchClause, finallyBlock }) =>
    ts.createTry(tryBlock, catchClause, finallyBlock),
};

const CatchClause: StructTemplate<
  {
    variableDeclaration: OptionalStructSingleChild<ts.VariableDeclaration>;
    block: RequiredStructSingleChild<ts.Block>;
  },
  ts.CatchClause
> = {
  match: plainTypes.CatchClause.match,
  children: ["variableDeclaration", "block"],
  flags: [] as FlagKind[],

  load: (e) => ({
    variableDeclaration: {
      value: e.variableDeclaration,
      union: unions.VariableDeclaration,
      optional: true,
    },
    block: {
      value: e.block,
      union: unions.Block,
    },
  }),
  build: ({ variableDeclaration, block }) =>
    ts.createCatchClause(variableDeclaration, block),
};

const SwitchStatement: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
    caseBlock: RequiredStructSingleChild<ts.CaseBlock>;
  },
  ts.SwitchStatement
> = {
  match: plainTypes.SwitchStatement.match,
  children: ["expression", "caseBlock"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
    caseBlock: {
      value: e.caseBlock,
      union: unions.CaseBlock,
    },
  }),
  build: ({ expression, caseBlock }) => ts.createSwitch(expression, caseBlock),
};

const CaseBlock: StructTemplate<
  {
    clauses: RequiredStructListChild<ts.CaseOrDefaultClause>;
  },
  ts.CaseBlock
> = {
  match: plainTypes.CaseBlock.match,
  children: ["clauses"],
  flags: [] as FlagKind[],

  load: (e) => ({
    clauses: {
      value: e.clauses,
      union: unions.CaseOrDefaultClause,
      isList: true,
    },
  }),
  build: ({ clauses }) => ts.createCaseBlock(clauses),
};

const CaseClause: StructTemplate<
  {
    expression: RequiredStructSingleChild<ts.Expression>;
    statements: RequiredStructListChild<ts.Statement>;
  },
  ts.CaseClause
> = {
  match: plainTypes.CaseClause.match,
  children: ["expression", "statements"],
  flags: [] as FlagKind[],

  load: (e) => ({
    expression: {
      value: e.expression,
      union: unions.Expression,
    },
    statements: {
      value: e.statements,
      union: unions.Statement,
      isList: true,
    },
  }),
  build: ({ expression, statements }) =>
    ts.createCaseClause(expression, statements),
};

const DefaultClause: StructTemplate<
  {
    statements: RequiredStructListChild<ts.Statement>;
  },
  ts.DefaultClause
> = {
  match: plainTypes.DefaultClause.match,
  children: ["statements"],
  flags: [] as FlagKind[],

  load: (e) => ({
    statements: {
      value: e.statements,
      union: unions.Statement,
      isList: true,
    },
  }),
  build: ({ statements }) => ts.createDefaultClause(statements),
};

const BreakStatement: StructTemplate<
  {
    label: OptionalStructSingleChild<ts.Identifier>;
  },
  ts.BreakStatement
> = {
  match: plainTypes.BreakStatement.match,
  children: ["label"],
  flags: [] as FlagKind[],

  load: (e) => ({
    label: {
      value: e.label,
      union: unions.Identifier,
      optional: true,
    },
  }),
  build: ({ label }) => ts.createBreak(label),
};

const ContinueStatement: StructTemplate<
  {
    label: OptionalStructSingleChild<ts.Identifier>;
  },
  ts.ContinueStatement
> = {
  match: plainTypes.ContinueStatement.match,
  children: ["label"],
  flags: [] as FlagKind[],

  load: (e) => ({
    label: {
      value: e.label,
      union: unions.Identifier,
      optional: true,
    },
  }),
  build: ({ label }) => ts.createContinue(label),
};

const NamedTupleMember: StructTemplate<
  {
    dotDotDotToken: OptionalStructSingleChild<ts.DotDotDotToken>;
    name: RequiredStructSingleChild<ts.Identifier>;
    questionToken: OptionalStructSingleChild<ts.QuestionToken>;
    type: RequiredStructSingleChild<ts.TypeNode>;
  },
  ts.NamedTupleMember
> = {
  match: plainTypes.NamedTupleMember.match,
  children: ["dotDotDotToken", "name", "questionToken", "type"],
  flags: [] as FlagKind[],

  load: (e) => ({
    dotDotDotToken: {
      value: e.dotDotDotToken,
      union: unions.DotDotDotToken,
      optional: true,
    },
    name: {
      value: e.name,
      union: unions.Identifier,
    },
    questionToken: {
      value: e.questionToken,
      union: unions.QuestionToken,
      optional: true,
    },
    type: {
      value: e.type,
      union: unions.TypeNode,
    },
  }),
  build: ({ dotDotDotToken, name, questionToken, type }) =>
    ts.factory.createNamedTupleMember(
      dotDotDotToken,
      name,
      questionToken,
      type,
    ),
};

const OpenBraceToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.OpenBraceToken>
> = {
  match: plainTypes.OpenBraceToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.OpenBraceToken),
};

const CloseBraceToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.CloseBraceToken>
> = {
  match: plainTypes.CloseBraceToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.CloseBraceToken),
};

const OpenParenToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.OpenParenToken>
> = {
  match: plainTypes.OpenParenToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.OpenParenToken),
};

const CloseParenToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.CloseParenToken>
> = {
  match: plainTypes.CloseParenToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.CloseParenToken),
};

const OpenBracketToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.OpenBracketToken>
> = {
  match: plainTypes.OpenBracketToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.OpenBracketToken),
};

const CloseBracketToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.CloseBracketToken>
> = {
  match: plainTypes.CloseBracketToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.CloseBracketToken),
};

const DotToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.DotToken>> = {
  match: plainTypes.DotToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.DotToken),
};

const SemicolonToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.SemicolonToken>
> = {
  match: plainTypes.SemicolonToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.SemicolonToken),
};

const CommaToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.CommaToken>> = {
  match: plainTypes.CommaToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.CommaToken),
};

const LessThanToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.LessThanToken>
> = {
  match: plainTypes.LessThanToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.LessThanToken),
};

const LessThanSlashToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.LessThanSlashToken>
> = {
  match: plainTypes.LessThanSlashToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.LessThanSlashToken),
};

const GreaterThanToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.GreaterThanToken>
> = {
  match: plainTypes.GreaterThanToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.GreaterThanToken),
};

const LessThanEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.LessThanEqualsToken>
> = {
  match: plainTypes.LessThanEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.LessThanEqualsToken),
};

const GreaterThanEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.GreaterThanEqualsToken>
> = {
  match: plainTypes.GreaterThanEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.GreaterThanEqualsToken),
};

const EqualsEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.EqualsEqualsToken>
> = {
  match: plainTypes.EqualsEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.EqualsEqualsToken),
};

const ExclamationEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.ExclamationEqualsToken>
> = {
  match: plainTypes.ExclamationEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.ExclamationEqualsToken),
};

const EqualsEqualsEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.EqualsEqualsEqualsToken>
> = {
  match: plainTypes.EqualsEqualsEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.EqualsEqualsEqualsToken),
};

const ExclamationEqualsEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.ExclamationEqualsEqualsToken>
> = {
  match: plainTypes.ExclamationEqualsEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.ExclamationEqualsEqualsToken),
};

const EqualsGreaterThanToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.EqualsGreaterThanToken>
> = {
  match: plainTypes.EqualsGreaterThanToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.EqualsGreaterThanToken),
};

const PlusToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.PlusToken>> = {
  match: plainTypes.PlusToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.PlusToken),
};

const MinusToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.MinusToken>> = {
  match: plainTypes.MinusToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.MinusToken),
};

const AsteriskAsteriskToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.AsteriskAsteriskToken>
> = {
  match: plainTypes.AsteriskAsteriskToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.AsteriskAsteriskToken),
};

const SlashToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.SlashToken>> = {
  match: plainTypes.SlashToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.SlashToken),
};

const PercentToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.PercentToken>> = {
  match: plainTypes.PercentToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.PercentToken),
};

const PlusPlusToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.PlusPlusToken>
> = {
  match: plainTypes.PlusPlusToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.PlusPlusToken),
};

const MinusMinusToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.MinusMinusToken>
> = {
  match: plainTypes.MinusMinusToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.MinusMinusToken),
};

const LessThanLessThanToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.LessThanLessThanToken>
> = {
  match: plainTypes.LessThanLessThanToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.LessThanLessThanToken),
};

const GreaterThanGreaterThanToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.GreaterThanGreaterThanToken>
> = {
  match: plainTypes.GreaterThanGreaterThanToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.GreaterThanGreaterThanToken),
};

const GreaterThanGreaterThanGreaterThanToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.GreaterThanGreaterThanGreaterThanToken>
> = {
  match: plainTypes.GreaterThanGreaterThanGreaterThanToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () =>
    ts.createToken(ts.SyntaxKind.GreaterThanGreaterThanGreaterThanToken),
};

const AmpersandToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.AmpersandToken>
> = {
  match: plainTypes.AmpersandToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.AmpersandToken),
};

const BarToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.BarToken>> = {
  match: plainTypes.BarToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.BarToken),
};

const CaretToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.CaretToken>> = {
  match: plainTypes.CaretToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.CaretToken),
};

const ExclamationToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.ExclamationToken>
> = {
  match: plainTypes.ExclamationToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.ExclamationToken),
};

const TildeToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.TildeToken>> = {
  match: plainTypes.TildeToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.TildeToken),
};

const AmpersandAmpersandToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.AmpersandAmpersandToken>
> = {
  match: plainTypes.AmpersandAmpersandToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.AmpersandAmpersandToken),
};

const QuestionQuestionToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.QuestionQuestionToken>
> = {
  match: plainTypes.QuestionQuestionToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.QuestionQuestionToken),
};

const BarBarToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.BarBarToken>> = {
  match: plainTypes.BarBarToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.BarBarToken),
};

const ColonToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.ColonToken>> = {
  match: plainTypes.ColonToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.ColonToken),
};

const AtToken: StructTemplate<{}, ts.Token<ts.SyntaxKind.AtToken>> = {
  match: plainTypes.AtToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.AtToken),
};

const PlusEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.PlusEqualsToken>
> = {
  match: plainTypes.PlusEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.PlusEqualsToken),
};

const MinusEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.MinusEqualsToken>
> = {
  match: plainTypes.MinusEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.MinusEqualsToken),
};

const AsteriskEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.AsteriskEqualsToken>
> = {
  match: plainTypes.AsteriskEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.AsteriskEqualsToken),
};

const AsteriskAsteriskEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.AsteriskAsteriskEqualsToken>
> = {
  match: plainTypes.AsteriskAsteriskEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.AsteriskAsteriskEqualsToken),
};

const SlashEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.SlashEqualsToken>
> = {
  match: plainTypes.SlashEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.SlashEqualsToken),
};

const PercentEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.PercentEqualsToken>
> = {
  match: plainTypes.PercentEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.PercentEqualsToken),
};

const LessThanLessThanEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.LessThanLessThanEqualsToken>
> = {
  match: plainTypes.LessThanLessThanEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.LessThanLessThanEqualsToken),
};

const GreaterThanGreaterThanEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.GreaterThanGreaterThanEqualsToken>
> = {
  match: plainTypes.GreaterThanGreaterThanEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.GreaterThanGreaterThanEqualsToken),
};

const GreaterThanGreaterThanGreaterThanEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.GreaterThanGreaterThanGreaterThanEqualsToken>
> = {
  match: plainTypes.GreaterThanGreaterThanGreaterThanEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () =>
    ts.createToken(ts.SyntaxKind.GreaterThanGreaterThanGreaterThanEqualsToken),
};

const AmpersandEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.AmpersandEqualsToken>
> = {
  match: plainTypes.AmpersandEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.AmpersandEqualsToken),
};

const BarEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.BarEqualsToken>
> = {
  match: plainTypes.BarEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.BarEqualsToken),
};

const CaretEqualsToken: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.CaretEqualsToken>
> = {
  match: plainTypes.CaretEqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.CaretEqualsToken),
};

const InKeyword: StructTemplate<{}, ts.Token<ts.SyntaxKind.InKeyword>> = {
  match: plainTypes.InKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.InKeyword),
};

const InstanceOfKeyword: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.InstanceOfKeyword>
> = {
  match: plainTypes.InstanceOfKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.InstanceOfKeyword),
};

const AnyKeyword: StructTemplate<{}, ts.Token<ts.SyntaxKind.AnyKeyword>> = {
  match: plainTypes.AnyKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.AnyKeyword),
};

const UnknownKeyword: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.UnknownKeyword>
> = {
  match: plainTypes.UnknownKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.UnknownKeyword),
};

const NumberKeyword: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.NumberKeyword>
> = {
  match: plainTypes.NumberKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.NumberKeyword),
};

const ObjectKeyword: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.ObjectKeyword>
> = {
  match: plainTypes.ObjectKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.ObjectKeyword),
};

const BooleanKeyword: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.BooleanKeyword>
> = {
  match: plainTypes.BooleanKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.BooleanKeyword),
};

const StringKeyword: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.StringKeyword>
> = {
  match: plainTypes.StringKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.StringKeyword),
};

const SymbolKeyword: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.SymbolKeyword>
> = {
  match: plainTypes.SymbolKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.SymbolKeyword),
};

const VoidKeyword: StructTemplate<{}, ts.Token<ts.SyntaxKind.VoidKeyword>> = {
  match: plainTypes.VoidKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.VoidKeyword),
};

const UndefinedKeyword: StructTemplate<
  {},
  ts.Token<ts.SyntaxKind.UndefinedKeyword>
> = {
  match: plainTypes.UndefinedKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.UndefinedKeyword),
};

const NeverKeyword: StructTemplate<{}, ts.Token<ts.SyntaxKind.NeverKeyword>> = {
  match: plainTypes.NeverKeyword.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => ts.createToken(ts.SyntaxKind.NeverKeyword),
};

const NullLiteral: StructTemplate<{}, ts.NullLiteral> = {
  match: plainTypes.NullLiteral.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.NullLiteral.default,
};

const TrueLiteral: StructTemplate<{}, ts.TrueLiteral> = {
  match: plainTypes.TrueLiteral.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.TrueLiteral.default,
};

const FalseLiteral: StructTemplate<{}, ts.FalseLiteral> = {
  match: plainTypes.FalseLiteral.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.FalseLiteral.default,
};

const ThisExpression: StructTemplate<{}, ts.ThisExpression> = {
  match: plainTypes.ThisExpression.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.ThisExpression.default,
};

const SuperExpression: StructTemplate<{}, ts.SuperExpression> = {
  match: plainTypes.SuperExpression.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.SuperExpression.default,
};

const ImportExpression: StructTemplate<{}, ts.ImportExpression> = {
  match: plainTypes.ImportExpression.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.ImportExpression.default,
};

const DotDotDotToken: StructTemplate<{}, ts.DotDotDotToken> = {
  match: plainTypes.DotDotDotToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.DotDotDotToken.default,
};

const QuestionToken: StructTemplate<{}, ts.QuestionToken> = {
  match: plainTypes.QuestionToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.QuestionToken.default,
};

const QuestionDotToken: StructTemplate<{}, ts.QuestionDotToken> = {
  match: plainTypes.QuestionDotToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.QuestionDotToken.default,
};

const AsteriskToken: StructTemplate<{}, ts.AsteriskToken> = {
  match: plainTypes.AsteriskToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.AsteriskToken.default,
};

const ThisTypeNode: StructTemplate<{}, ts.ThisTypeNode> = {
  match: plainTypes.ThisTypeNode.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.ThisTypeNode.default,
};

const ReadonlyToken: StructTemplate<{}, ts.ReadonlyToken> = {
  match: plainTypes.ReadonlyToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.ReadonlyToken.default,
};

const EqualsToken: StructTemplate<{}, ts.EqualsToken> = {
  match: plainTypes.EqualsToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.EqualsToken.default,
};

const AwaitKeywordToken: StructTemplate<{}, ts.AwaitKeywordToken> = {
  match: plainTypes.AwaitKeywordToken.match,
  children: [],
  flags: [],
  load: () => ({}),
  build: () => plainTypes.AwaitKeywordToken.default,
};

export const structTemplates = [
  VariableDeclaration,
  EmptyStatement,
  ThrowStatement,
  VariableStatement,
  FunctionDeclaration,
  FunctionExpression,
  ExpressionStatement,
  ParameterDeclaration,
  BindingElement,
  ComputedPropertyName,
  CallExpression,
  PropertyAccessExpression,
  ElementAccessExpression,
  ImportDeclaration,
  ImportClause,
  NamespaceImport,
  NamespaceExport,
  ImportSpecifier,
  ExportSpecifier,
  ClassDeclaration,
  HeritageClause,
  ExpressionWithTypeArguments,
  PropertyDeclaration,
  MethodDeclaration,
  ConstructorDeclaration,
  GetAccessorDeclaration,
  SetAccessorDeclaration,
  ArrowFunction,
  PropertyAssignment,
  ReturnStatement,
  NewExpression,
  IfStatement,
  ConditionalExpression,
  BinaryExpression,
  PrefixUnaryExpression,
  PostfixUnaryExpression,
  TemplateExpression,
  TemplateSpan,
  ParenthesizedExpression,
  AsExpression,
  TypeParameterDeclaration,
  FunctionTypeNode,
  ConstructorTypeNode,
  QualifiedName,
  TypeReferenceNode,
  TypePredicateNode,
  TypeQueryNode,
  CallSignatureDeclaration,
  ConstructSignatureDeclaration,
  PropertySignature,
  MethodSignature,
  IndexSignatureDeclaration,
  ArrayTypeNode,
  ParenthesizedTypeNode,
  TypeOperatorNode,
  IndexedAccessTypeNode,
  MappedTypeNode,
  LiteralTypeNode,
  InterfaceDeclaration,
  TypeAliasDeclaration,
  EnumDeclaration,
  EnumMember,
  ModuleDeclaration,
  ImportEqualsDeclaration,
  ExternalModuleReference,
  NamespaceExportDeclaration,
  NamespaceDeclaration,
  ExportDeclaration,
  ExportAssignment,
  ShorthandPropertyAssignment,
  SpreadAssignment,
  JsxElement,
  JsxFragment,
  JsxSelfClosingElement,
  JsxOpeningElement,
  JsxClosingElement,
  JsxAttribute,
  JsxSpreadAttribute,
  JsxExpression,
  JsxTagNamePropertyAccess,
  TypeOfExpression,
  AwaitExpression,
  NonNullExpression,
  DoStatement,
  WhileStatement,
  ForStatement,
  ForInStatement,
  ForOfStatement,
  SpreadElement,
  TryStatement,
  CatchClause,
  SwitchStatement,
  CaseBlock,
  CaseClause,
  DefaultClause,
  BreakStatement,
  ContinueStatement,
  NamedTupleMember,
  OpenBraceToken,
  CloseBraceToken,
  OpenParenToken,
  CloseParenToken,
  OpenBracketToken,
  CloseBracketToken,
  DotToken,
  SemicolonToken,
  CommaToken,
  LessThanToken,
  LessThanSlashToken,
  GreaterThanToken,
  LessThanEqualsToken,
  GreaterThanEqualsToken,
  EqualsEqualsToken,
  ExclamationEqualsToken,
  EqualsEqualsEqualsToken,
  ExclamationEqualsEqualsToken,
  EqualsGreaterThanToken,
  PlusToken,
  MinusToken,
  AsteriskAsteriskToken,
  SlashToken,
  PercentToken,
  PlusPlusToken,
  MinusMinusToken,
  LessThanLessThanToken,
  GreaterThanGreaterThanToken,
  GreaterThanGreaterThanGreaterThanToken,
  AmpersandToken,
  BarToken,
  CaretToken,
  ExclamationToken,
  TildeToken,
  AmpersandAmpersandToken,
  QuestionQuestionToken,
  BarBarToken,
  ColonToken,
  AtToken,
  PlusEqualsToken,
  MinusEqualsToken,
  AsteriskEqualsToken,
  AsteriskAsteriskEqualsToken,
  SlashEqualsToken,
  PercentEqualsToken,
  LessThanLessThanEqualsToken,
  GreaterThanGreaterThanEqualsToken,
  GreaterThanGreaterThanGreaterThanEqualsToken,
  AmpersandEqualsToken,
  BarEqualsToken,
  CaretEqualsToken,
  InKeyword,
  InstanceOfKeyword,
  AnyKeyword,
  UnknownKeyword,
  NumberKeyword,
  ObjectKeyword,
  BooleanKeyword,
  StringKeyword,
  SymbolKeyword,
  VoidKeyword,
  UndefinedKeyword,
  NeverKeyword,
  NullLiteral,
  TrueLiteral,
  FalseLiteral,
  ThisExpression,
  SuperExpression,
  ImportExpression,
  DotDotDotToken,
  QuestionToken,
  QuestionDotToken,
  AsteriskToken,
  ThisTypeNode,
  ReadonlyToken,
  EqualsToken,
  AwaitKeywordToken,
];

export const typesByShortcut = new Map<string, string>([
  ["=", "EqualsToken"],
  ["<", "LessThanToken"],
  [">", "GreaterThanToken"],
  ["<=", "LessThanEqualsToken"],
  [">=", "GreaterThanEqualsToken"],
  ["==", "EqualsEqualsToken"],
  ["!=", "ExclamationEqualsToken"],
  ["===", "EqualsEqualsEqualsToken"],
  ["!==", "ExclamationEqualsEqualsToken"],
  ["+", "PlusToken"],
  ["-", "MinusToken"],
  ["*", "AsteriskToken"],
  ["**", "AsteriskAsteriskToken"],
  ["/", "SlashToken"],
  ["%", "PercentToken"],
  ["++", "PlusPlusToken"],
  ["--", "MinusMinusToken"],
  ["<<", "LessThanLessThanToken"],
  [">>", "GreaterThanGreaterThanToken"],
  [">>>", "GreaterThanGreaterThanGreaterThanToken"],
  ["&", "AmpersandToken"],
  ["|", "BarToken"],
  ["^", "CaretToken"],
  ["!", "ExclamationToken"],
  ["~", "TildeToken"],
  ["&&", "AmpersandAmpersandToken"],
  ["||", "BarBarToken"],
  ["??", "QuestionQuestionToken"],
  ["+=", "PlusEqualsToken"],
  ["-=", "MinusEqualsToken"],
  ["*=", "AsteriskEqualsToken"],
  ["**=", "AsteriskAsteriskEqualsToken"],
  ["/=", "SlashEqualsToken"],
  ["%=", "PercentEqualsToken"],
  ["<<=", "LessThanLessThanEqualsToken"],
  [">>=", "GreaterThanGreaterThanEqualsToken"],
  [">>>=", "GreaterThanGreaterThanGreaterThanEqualsToken"],
  ["&=", "AmpersandEqualsToken"],
  ["|=", "BarEqualsToken"],
  ["^=", "CaretEqualsToken"],
  ["?.", "QuestionDotToken"],
  ["cd", "ClassDeclaration"],
  ["if", "IfStatement"],
  ["re", "ReturnStatement"],
  ["fd", "FunctionDeclaration"],
  ["af", "ArrowFunction"],
  ["bi", "BinaryExpression"],
  ["ca", "CallExpression"],
  ["es", "ExpressionStatement"],
  ["bl", "Block"],
  ["pr", "PropertyAccessExpression"],
  ["bo", "BooleanLiteral"],
  ["va", "VariableStatement"],
  ["va", "VariableDeclarationList"],
  ["mod", "ModuleDeclaration"],
  ["int", "InterfaceDeclaration"],
  ["tya", "TypeAliasDeclaration"],
  ["enu", "EnumDeclaration"],
  ["imd", "ImportDeclaration"],
  ["imq", "ImportEqualsDeclaration"],
  ["nas", "NamespaceDeclaration"],
  ["exd", "ExportDeclaration"],
  ["exa", "ExportAssignment"],
  ["do", "DoStatement"],
  ["wh", "WhileStatement"],
  ["fos", "ForStatement"],
  ["foi", "ForInStatement"],
  ["foo", "ForOfStatement"],
  ["trw", "ThrowStatement"],
  ["try", "TryStatement"],
  ["id", "Identifier"],
  ["nu", "NumericLiteral"],
  ["st", "StringLiteral"],
  ["rx", "RegularExpressionLiteral"],
  ["th", "ThisExpression"],
  ["sup", "SuperExpression"],
  ["ime", "ImportExpression"],
  ["fe", "FunctionExpression"],
  ["tpl", "TemplateExpression"],
  ["nl", "NullLiteral"],
  ["ar", "ArrayLiteralExpression"],
  ["ob", "ObjectLiteralExpression"],
  ["par", "ParenthesizedExpression"],
  ["new", "NewExpression"],
  ["jxa", "JsxAttributes"],
  ["jxe", "JsxElement"],
  ["jxc", "JsxSelfClosingElement"],
  ["jxf", "JsxFragment"],
  ["cle", "ClassExpression"],
  ["el", "ElementAccessExpression"],
  ["ttl", "TaggedTemplateExpression"],
  ["nnl", "NonNullExpression"],
  ["unb", "PrefixUnaryExpression"],
  ["una", "PostfixUnaryExpression"],
  ["tyo", "TypeOfExpression"],
  ["awa", "AwaitExpression"],
  ["cde", "ConditionalExpression"],
  ["as", "AsExpression"],
  ["spr", "SpreadElement"],
  ["tru", "TrueLiteral"],
  ["fal", "FalseLiteral"],
  ["any", "AnyKeyword"],
  ["unk", "UnknownKeyword"],
  ["nu", "NumberKeyword"],
  ["kob", "ObjectKeyword"],
  ["bo", "BooleanKeyword"],
  ["st", "StringKeyword"],
  ["sym", "SymbolKeyword"],
  ["th", "ThisKeyword"],
  ["voi", "VoidKeyword"],
  ["und", "UndefinedKeyword"],
  ["nl", "NullKeyword"],
  ["nev", "NeverKeyword"],
  ["ob", "TypeLiteralNode"],
  ["af", "FunctionTypeNode"],
  ["con", "ConstructorTypeNode"],
  ["id", "TypeReferenceNode"],
  ["tyo", "TypeQueryNode"],
  ["ar", "ArrayTypeNode"],
  ["tup", "TupleTypeNode"],
  ["par", "ParenthesizedTypeNode"],
  ["uni", "UnionTypeNode"],
  ["its", "IntersectionTypeNode"],
  ["kyo", "TypeOperatorNode"],
  ["el", "IndexedAccessTypeNode"],
  ["mat", "MappedTypeNode"],
  ["lit", "LiteralTypeNode"],
  ["ext", "ExpressionWithTypeArguments"],
  ["is", "TypePredicateNode"],
  ["pr", "PropertyAssignment"],
  ["sh", "ShorthandPropertyAssignment"],
  ["spr", "SpreadAssignment"],
  ["me", "MethodDeclaration"],
  ["gea", "GetAccessorDeclaration"],
  ["sea", "SetAccessorDeclaration"],
  ["pr", "PropertyDeclaration"],
  ["con", "ConstructorDeclaration"],
  ["ixs", "IndexSignatureDeclaration"],
  ["me", "CallSignatureDeclaration"],
  ["con", "ConstructSignatureDeclaration"],
  ["pr", "PropertySignature"],
  ["me", "MethodSignature"],
  ["ixs", "IndexSignatureDeclaration"],
]);
export const shortcutsByType = new Map<string, string>([
  ["EqualsToken", "="],
  ["LessThanToken", "<"],
  ["GreaterThanToken", ">"],
  ["LessThanEqualsToken", "<="],
  ["GreaterThanEqualsToken", ">="],
  ["EqualsEqualsToken", "=="],
  ["ExclamationEqualsToken", "!="],
  ["EqualsEqualsEqualsToken", "==="],
  ["ExclamationEqualsEqualsToken", "!=="],
  ["PlusToken", "+"],
  ["MinusToken", "-"],
  ["AsteriskToken", "*"],
  ["AsteriskAsteriskToken", "**"],
  ["SlashToken", "/"],
  ["PercentToken", "%"],
  ["PlusPlusToken", "++"],
  ["MinusMinusToken", "--"],
  ["LessThanLessThanToken", "<<"],
  ["GreaterThanGreaterThanToken", ">>"],
  ["GreaterThanGreaterThanGreaterThanToken", ">>>"],
  ["AmpersandToken", "&"],
  ["BarToken", "|"],
  ["CaretToken", "^"],
  ["ExclamationToken", "!"],
  ["TildeToken", "~"],
  ["AmpersandAmpersandToken", "&&"],
  ["BarBarToken", "||"],
  ["QuestionQuestionToken", "??"],
  ["PlusEqualsToken", "+="],
  ["MinusEqualsToken", "-="],
  ["AsteriskEqualsToken", "*="],
  ["AsteriskAsteriskEqualsToken", "**="],
  ["SlashEqualsToken", "/="],
  ["PercentEqualsToken", "%="],
  ["LessThanLessThanEqualsToken", "<<="],
  ["GreaterThanGreaterThanEqualsToken", ">>="],
  ["GreaterThanGreaterThanGreaterThanEqualsToken", ">>>="],
  ["AmpersandEqualsToken", "&="],
  ["BarEqualsToken", "|="],
  ["CaretEqualsToken", "^="],
  ["QuestionDotToken", "?."],
  ["ClassDeclaration", "cd"],
  ["IfStatement", "if"],
  ["ReturnStatement", "re"],
  ["FunctionDeclaration", "fd"],
  ["ArrowFunction", "af"],
  ["BinaryExpression", "bi"],
  ["CallExpression", "ca"],
  ["ExpressionStatement", "es"],
  ["Block", "bl"],
  ["PropertyAccessExpression", "pr"],
  ["BooleanLiteral", "bo"],
  ["VariableStatement", "va"],
  ["VariableDeclarationList", "va"],
  ["ModuleDeclaration", "mod"],
  ["InterfaceDeclaration", "int"],
  ["TypeAliasDeclaration", "tya"],
  ["EnumDeclaration", "enu"],
  ["ImportDeclaration", "imd"],
  ["ImportEqualsDeclaration", "imq"],
  ["NamespaceDeclaration", "nas"],
  ["ExportDeclaration", "exd"],
  ["ExportAssignment", "exa"],
  ["DoStatement", "do"],
  ["WhileStatement", "wh"],
  ["ForStatement", "fos"],
  ["ForInStatement", "foi"],
  ["ForOfStatement", "foo"],
  ["ThrowStatement", "trw"],
  ["TryStatement", "try"],
  ["Identifier", "id"],
  ["NumericLiteral", "nu"],
  ["StringLiteral", "st"],
  ["RegularExpressionLiteral", "rx"],
  ["ThisExpression", "th"],
  ["SuperExpression", "sup"],
  ["ImportExpression", "ime"],
  ["FunctionExpression", "fe"],
  ["TemplateExpression", "tpl"],
  ["NullLiteral", "nl"],
  ["ArrayLiteralExpression", "ar"],
  ["ObjectLiteralExpression", "ob"],
  ["ParenthesizedExpression", "par"],
  ["NewExpression", "new"],
  ["JsxAttributes", "jxa"],
  ["JsxElement", "jxe"],
  ["JsxSelfClosingElement", "jxc"],
  ["JsxFragment", "jxf"],
  ["ClassExpression", "cle"],
  ["ElementAccessExpression", "el"],
  ["TaggedTemplateExpression", "ttl"],
  ["NonNullExpression", "nnl"],
  ["PrefixUnaryExpression", "unb"],
  ["PostfixUnaryExpression", "una"],
  ["TypeOfExpression", "tyo"],
  ["AwaitExpression", "awa"],
  ["ConditionalExpression", "cde"],
  ["AsExpression", "as"],
  ["SpreadElement", "spr"],
  ["TrueLiteral", "tru"],
  ["FalseLiteral", "fal"],
  ["AnyKeyword", "any"],
  ["UnknownKeyword", "unk"],
  ["NumberKeyword", "nu"],
  ["ObjectKeyword", "kob"],
  ["BooleanKeyword", "bo"],
  ["StringKeyword", "st"],
  ["SymbolKeyword", "sym"],
  ["ThisKeyword", "th"],
  ["VoidKeyword", "voi"],
  ["UndefinedKeyword", "und"],
  ["NullKeyword", "nl"],
  ["NeverKeyword", "nev"],
  ["TypeLiteralNode", "ob"],
  ["FunctionTypeNode", "af"],
  ["ConstructorTypeNode", "con"],
  ["TypeReferenceNode", "id"],
  ["TypeQueryNode", "tyo"],
  ["ArrayTypeNode", "ar"],
  ["TupleTypeNode", "tup"],
  ["ParenthesizedTypeNode", "par"],
  ["UnionTypeNode", "uni"],
  ["IntersectionTypeNode", "its"],
  ["TypeOperatorNode", "kyo"],
  ["IndexedAccessTypeNode", "el"],
  ["MappedTypeNode", "mat"],
  ["LiteralTypeNode", "lit"],
  ["ExpressionWithTypeArguments", "ext"],
  ["TypePredicateNode", "is"],
  ["PropertyAssignment", "pr"],
  ["ShorthandPropertyAssignment", "sh"],
  ["SpreadAssignment", "spr"],
  ["MethodDeclaration", "me"],
  ["GetAccessorDeclaration", "gea"],
  ["SetAccessorDeclaration", "sea"],
  ["PropertyDeclaration", "pr"],
  ["ConstructorDeclaration", "con"],
  ["IndexSignatureDeclaration", "ixs"],
  ["CallSignatureDeclaration", "me"],
  ["ConstructSignatureDeclaration", "con"],
  ["PropertySignature", "pr"],
  ["MethodSignature", "me"],
  ["IndexSignatureDeclaration", "ixs"],
]);
